import { createSlice } from "@reduxjs/toolkit";


export const questionSlice = createSlice({
    name: 'question',
    initialState: {
        faq: { lists: null, category_lists: null, titleLists: null },
        question_pages: { question_lists: null }
    },
    reducers: {
        get_faq_lists: (state, action) => {
            state.faq.lists = action.payload
        },
        get_faq_category_lists: (state, action) => {
            state.faq.category_lists = action.payload
        },
        get_question_pages_lists: (state, action) => {
            state.question_pages.question_lists = action.payload
        },
        setTitleList: (state, action) => {
            state.faq.titleLists = action.payload
        },
    }
})

export const { get_faq_lists, get_faq_category_lists, get_question_pages_lists, setTitleList } = questionSlice.actions;

export default questionSlice.reducer;
