import "../../styles/views/Pages/belun.scss";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Box,
  Divider,
  Alert,
  Button,
  IconButton,
  TextField,
  OutlinedInput,
  FormControl,
  Card,
  getDividerUtilityClass,
  Icon,
} from "@mui/material";

import { Ratio } from "react-bootstrap";
import img1 from "../../images/sleep/adultsleep.webp";
import img2 from "../../images/sleep/how/belun_how.webp";

import video1 from "../../images/sleep/how/3.mp4";
import video2 from "../../images/sleep/how/8.mp4";
import video3 from "../../images/sleep/how/5.mp4";
import video4 from "../../images/sleep/how/6.mp4";

import { useTranslation } from "react-i18next";

export const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="belun_terms_section">
      <Box className="banner_div">
        <CardMedia className="banner_img" image={img1} />
        <Box className="context_div">
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontSize: "70px", fontWeight: "bold" }}
          >
            {t("BELUN_cn_title_1.1")}
          </Typography>
          <Grid container justifyContent={"center"}>
            <Typography variant="h4" component="h4">
              {t("BELUN_TERMS_p1_title_left.1")}
            </Typography>
            <Typography variant="h5">&reg;</Typography>
            <Typography variant="h4" component="h4" sx={{ pl: "8px" }}>
              {t("BELUN_TERMS_p1_title_right.1")}
            </Typography>
          </Grid>

          <div className="text_list">
            <Typography variant="body1" component="h6">
              {t("BELUN_TERMS_p1_sub_title_1.1")}
            </Typography>
            <Typography variant="body1" component="h6">
              {t("BELUN_TERMS_p1_sub_title_2.1")}
            </Typography>
            <Typography variant="body1" component="h6">
              {t("BELUN_TERMS_p1_sub_title_3.1")}
            </Typography>
          </div>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("BELUN_cn_title_2.1")}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" className="terms_context">
        <Typography variant="h5" component="h5">
          {t("BELUN_TERMS_p2_title.1")}
        </Typography>
        <Typography variant="h6" component="h6">
          {t("BELUN_TERMS_p2_sub_title.1")}
        </Typography>
        <Box className="terms_item">
          <Typography variant="body1" className="title">
            {t("BELUN_TERMS_p2_p1_title.1")}
          </Typography>
          <Typography variant="body1" className="sub_title">
            {t("BELUN_TERMS_p2_p1_sub_title.1")}
          </Typography>
          <Box className="list_div">
            <ul className="list_ul">
              <li>
                {" "}
                <Typography variant="body2">
                  {t("BELUN_TERMS_p2_p1_list_1.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body2">
                  {t("BELUN_TERMS_p2_p1_list_2.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body2">
                  {t("BELUN_TERMS_p2_p1_list_3.1")}
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box className="terms_amount_div">
          {/* <Typography variant="body1" className="title">
            {t("BELUN_TERMS_p2_p2_title.1")}
          </Typography>
          <Box className="list_box">
            <Grid container >
              <Grid item xs={6}>
                <Typography variant="body1" className="sub_title">
                  {t("BELUN_TERMS_p2_p2_list_1_left.1")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className="sub_title">
                  {t("BELUN_TERMS_p2_p2_list_1_right.1")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={6}>
                <Typography variant="body1" className="sub_title">
                  {t("BELUN_TERMS_p2_p2_list_2_left.1")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className="sub_title">
                  {t("BELUN_TERMS_p2_p2_list_2_right.1")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={6}>
                <Typography variant="body1" className="sub_title">
                  {t("BELUN_TERMS_p2_p2_list_3_left.1")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className="sub_title">
                  {t("BELUN_TERMS_p2_p2_list_3_right.1")}
                </Typography>
              </Grid>
            </Grid>
          </Box> */}

          <Box className="list_div">
            <ul className="list_ul">
              <li>
                {" "}
                <Typography variant="body2">
                  {t("BELUN_TERMS_p2_p3_list_1.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body2">
                  {t("BELUN_TERMS_p2_p3_list_2.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body2">
                  {t("BELUN_TERMS_p2_p3_list_3.1")}
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box className="moreInfo_div">
          <Typography variant="body1" className="title">
            {t("BELUN_TERMS_p3_title.1")}
          </Typography>
          <Box className="info_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p3_p1_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p1_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p1_list_2.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p1_list_3.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p1_list_4.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p1_list_5.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>

          <Box className="info_div">
            <Typography
              variant="body1"
              className="sub_title"
              sx={{ pb: "16px" }}
            >
              {t("BELUN_TERMS_p3_p2_title.1")}
            </Typography>
            <Typography variant="body2" className="sub_title_contaxt">
              {t("BELUN_TERMS_p3_p2_sub_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p2_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p2_list_2.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p2_list_3.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p2_list_4.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p2_list_5.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>

          <Box className="info_text_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p3_p3_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p3_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p3_list_2.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>

          <Box className="info_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p3_p4_title.1")}
            </Typography>
            <Typography variant="body2" className="sub_title_contaxt">
              {t("BELUN_TERMS_p3_p4_dos_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_dos_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_dos_list_2.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_dos_list_3.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
            <Typography variant="body2" className="sub_title_contaxt">
              {t("BELUN_TERMS_p3_p4_donts_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_donts_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_donts_list_2.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_donts_list_3.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p4_donts_list_4.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>

          <Box className="info_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p3_p5_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p5_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p3_p5_list_2.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
          <Box className="info_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p4_title.1")} 
            </Typography>
            <br />
            <Box className="info_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p4_sub_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_2.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_3.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_4.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
          </Box>
        </Box>
        {/* <Box className="report_div">
          <Typography variant="body1" className="title">
            {t("BELUN_TERMS_p4_title.1")}
          </Typography>
          <Box className="info_div">
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p4_sub_title.1")}
            </Typography>
            <Box className="list_div">
              <ul className="list_ul">
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_1.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_2.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_3.1")}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography variant="body2">
                    {t("BELUN_TERMS_p4_list_4.1")}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </Box> */}
        <Box className="know_div">
          <Typography variant="body1" className="title" sx={{ pt: "40px" }}>
            {t("BELUN_TERMS_p5_title.1")}
          </Typography>
          <Box className="list_item">
            <Typography variant="body1" className="title">
              {t("BELUN_TERMS_p5_p1_title.1")}
            </Typography>
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p5_p1_text.1")}
            </Typography>
          </Box>
          <Box className="list_item">
            <Typography variant="body1" className="title">
              {t("BELUN_TERMS_p5_p2_title.1")}
            </Typography>
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p5_p2_text.1")}
            </Typography>
          </Box>
          <Box className="list_item">
            <Typography variant="body1" className="title">
              {t("BELUN_TERMS_p5_p3_title.1")}
            </Typography>
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p5_p3_text.1")}
            </Typography>
          </Box>
          <Box className="list_item">
            <Typography variant="body1" className="title">
              {t("BELUN_TERMS_p5_p4_title.1")}
            </Typography>
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p5_p4_text.1")}
            </Typography>
          </Box>

          <Box className="list_item">
            <Typography variant="body1" className="title">
              {t("BELUN_TERMS_p5_p5_title.1")}
            </Typography>
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p5_p5_text.1")}
            </Typography>
          </Box>
          <Box className="list_item">
            <Typography variant="body1" className="title">
              {t("BELUN_TERMS_p5_p6_title.1")}
            </Typography>
            <Typography variant="body1" className="sub_title">
              {t("BELUN_TERMS_p5_p6_text.1")}
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
