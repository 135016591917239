import '../../styles/components/contactBtn.scss';

import React, { useState } from 'react'
import {
    Slide,
    Box,
    Fab,
    Backdrop,

} from '@mui/material';
import { useSelector } from 'react-redux';



import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const ContactBtn = () => {

    const { info } = useSelector(state => state.home.Home);


    const [open, setOpen] = useState(false);




    return (

        info == null ? null : <>
            <Box container className="contactBtn_box"
                flexDirection='column'
                spacing={2}
                sx={{
                    position: 'fixed',
                    right: '32px',
                    bottom: '56px',
                    width: 'auto',
                    zIndex: 500,
                    display: { xs: 'none', md: 'flex' }
                }}
            >
                <a href={`mailto:${info.info.email[0].value}`} >
                    <Fab className="ChatFab" sx={{ backgroundColor: 'orange', color: 'white', mb: '16px' }} >
                        <ChatBubbleOutlineIcon sx={{ fontSize: '35px' }} />
                    </Fab>
                </a>

                <a href={`https://wa.me/${info.info.whatsapp_number[0].value}?text=${info.info.whatsapp_message[0].value}`} target="_blank">
                    <Fab className="WhatappsFab" sx={{ backgroundColor: 'rgb(37, 211, 102)', color: 'white' }}>
                        <WhatsAppIcon sx={{ fontSize: '35px' }} />
                    </Fab>
                </a>
            </Box>
            < Box container className="contactBtn_box"
                flexDirection='column'
                spacing={2}
                sx={{

                    position: 'fixed',
                    right: '32px',
                    bottom: '56px',
                    width: 'auto',
                    zIndex: 500,
                    display: { xs: 'flex', md: 'none' }
                }}
            >

                <Fab className="mobileBtn" sx={{ backgroundColor: 'rgb(37, 211, 102)', color: 'white' }} onClick={() => setOpen(!open)}>
                    <LocalPhoneOutlinedIcon sx={{ fontSize: '35px' }} />
                </Fab>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={() => setOpen(!open)}
            >

                <Box container className="contactBtn_box"
                    flexDirection='column'
                    spacing={2}
                    sx={{
                        position: 'fixed',
                        right: '32px',
                        bottom: '56px',
                        width: 'auto',
                        zIndex: 500,
                        display: { xs: 'flex', md: 'none' }
                    }}
                >
                    <Slide
                        in={open}
                        direction="up"
                        mountOnEnter unmountOnExit
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Fab className="closeBtn" sx={{ backgroundColor: '#e0e0e0', color: '#666', mb: '8px' }} onClick={() => setOpen(!open)}>
                                <CloseOutlinedIcon sx={{ fontSize: '35px' }} />
                            </Fab>
                            <a href={`mailto:${info.info.email[0].value}`} >
                                <Fab className="ChatFab" sx={{ backgroundColor: 'darkorange', color: 'white', mb: '8px' }} >
                                    <ChatBubbleOutlineIcon sx={{ fontSize: '35px' }} />
                                </Fab>
                            </a>
                            <a href={`https://wa.me/${info.info.whatsapp_number[0].value}?text=${info.info.whatsapp_message[0].value}`} target="_blank">

                                <Fab className="WhatappsFab" sx={{ backgroundColor: 'rgb(37, 211, 102)', color: 'white', mb: '8px' }} >
                                    <WhatsAppIcon sx={{ fontSize: '35px' }} />
                                </Fab>
                            </a>
                        </Box>
                    </Slide >
                    <a href={`tel:${info.info.tel[0].value.split(" ")[0] + info.info.tel[0].value.split(" ")[1]}`}>
                        <Fab className="phoneBtn" sx={{ backgroundColor: 'rgba(0, 38, 255, 0.67)', color: 'white' }} >
                            <LocalPhoneOutlinedIcon sx={{ fontSize: '35px' }} />
                        </Fab>
                    </a>
                </Box>

            </Backdrop>
        </>
    )
}

export default ContactBtn;


