import "../../styles/components/header.scss";
import React, { useState, useRef, useEffect, useMemo, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Menu from "@mui/icons-material/Menu";
import { MenuItem } from "@mui/material";
import { Modal, Button } from "@mui/material";
import { CardMedia } from "@mui/material";
import { Alert, Snackbar, FormControlLabel, Checkbox } from "@mui/material";






import Select from "react-select";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import { ListGroup, Accordion, Col, useAccordionButton } from "react-bootstrap";




import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Autoplay, Navigation, FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faMagnifyingGlass,
  faPhone,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";

import { useLocation } from "react-router";
import { useNavigate, createSearchParams } from "react-router-dom";
// Logo
import logo from "../../images/header/logo.webp";
import family from "../../images/header/family.webp";

import { useForm, Controller } from "react-hook-form";

import { useSelector, useDispatch } from "react-redux";
import { select_categroy_header } from "../../redux/reducers/newsSlice";

import { user_login_auth } from "../../controller/api/api";
import { user_login, user_logout, toggle_isRegister, toggle_isLogin } from "../../redux/reducers/userSlice";

import { change_language } from "../../redux/reducers/langSlice";

import { checkNotNull } from "../../utils";

import { SuccessToast, ErrorToast } from "../ToastSweetAlert2/Toast";

import { LocalizationProvider, CalendarPicker } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { user_register_auth, forget_pwd, reset_pwd, userLogout } from "../../controller/api/api";

import { get_search_filter_lists, set_category_item } from "../../redux/reducers/homeSlice";
import Datepicker from "../datepicker/Datepicker";

// Search bar component
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#efefef", 1),
  width: "100%",
  display: "flex",
  [theme.breakpoints.up("md")]: {
    width: "28%",
  },
  [theme.breakpoints.up("xs")]: {
    display: "none",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `1rem`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },
}));

export const Header = () => {
  const { info, showMenu } = useSelector((state) => state.home.Home);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const stcikyRef = useRef();
  const headerRef = useRef();
  const searchRef = useRef(null);
  const searchRef2 = useRef(null);
  const blogMenuRef = useRef(null);
  const mainMenuRef = useRef(null);
  const belunMenuRef = useRef(null);

  const [showLeft, setShowLeft] = useState(false);
  const [filterCategoriesClick, setFilterCategoriesClick] = useState(null);
  const [filterClick, setFilterClick] = useState(null);
  const [filterList, setFilterList] = useState(null);
  const [searchText, setSearchText] = useState('');


  const [openLogin, setOpenLogin] = useState(false);
  const [isLoginUser, setIsLoginUser] = useState(false);

  const [openRegister, setOpenRegister] = useState(false);
  const [isRegisterUser, setIsRegister] = useState(false);

  const { news_category_list } = useSelector((state) => state.news);
  const [openDate, setOpenDate] = useState(false);
  const { user, isRegister, isLogin } = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);

  const [forgetPwd, setForgetPwd] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);

  const [showLang, setShowLang] = useState(false);

  const {
    register: loginRegister,
    unregister: loginUnregister,
    handleSubmit: loginHandleSubmit,
    reset: resetLogin,
    setError: loginSetError,
    resetField: resetFieldLogin,
    formState: { errors: loginErrors },
  } = useForm();

  const {
    register: createAccountRegister,
    handleSubmit: createAccountHandleSubmit,
    reset: resetCreateAccount,
    control: createAccountControl,
    setError: createAccountSetError,
    formState: { errors: createAccountErrors },
  } = useForm();



  const handleUserState = () => {
    setOpenLogin(isLogin);
    setOpenRegister(isRegister);
  }





  useEffect(() => { handleUserState() }, [isLogin, isRegister])

  const handleLogin = (e) => {
    // e.preventDefault();
    if (isLoginUser == false) {
      setOpenLogin(true);
      dispatch(toggle_isLogin(true))
    } else {
      setOpenLogin(false);
      dispatch(toggle_isLogin(false))
    }
  };
  const handleLoginClose = () => {
    setOpenLogin(false);
    setForgetPwd(false);
    setResetPwd(false);
    dispatch(toggle_isLogin(false))
    resetLogin();
  };

  const handleSignIn = async (data) => {
    // console.log(data);
    if (!forgetPwd && !resetPwd) {
      loginUnregister('c_password');
      loginUnregister('verification_code');
      await user_login_auth(data)
        .then((res) => {
          // console.log("handleSignIn->res", res);
          dispatch(user_login(res));
          setIsLoginUser(true);
          handleLoginClose();
          SuccessToast("Login Success.");
        })
        .catch((err) => {
          // console.log("handleSignIn->err2", err);
          if (typeof err.response.data.message == 'object') {
            Object.keys(err.response.data.message).forEach((item, index) => {
              loginSetError(item, { type: 'error', message: Object.values(err.response.data.message)[index] });
            })
          } else {
            ErrorToast(err.response.data.message);
            resetFieldLogin('password')

          }
        });
    } else {
      if (forgetPwd && !resetPwd) {
        await forget_pwd(data)
          .then((res) => {
            // console.log("forget_pwd->res", res);
            setResetPwd(true);
            setForgetPwd(false);
            SuccessToast(res.message);
          })
          .catch((err) => {
            // console.log("forget_pwd->err", err);
            ErrorToast(err.response.data.message);
          });
      } else {
        // console.log('reset_pwd')
        await reset_pwd(data)
          .then((res) => {
            // console.log("reset_pwd->res", res);
            SuccessToast(res.message);
            setResetPwd(false);
            setForgetPwd(false);
          })
          .catch((err) => {
            // console.log("reset_pwd->err", err);
            if (typeof err.response.data.message == 'object') {
              // // console.log(' == object')

              Object.keys(err.response.data.message).forEach((item, index) => {
                // // console.log(item, { type: 'error', message: Object.values(index) })
                loginSetError(item, { type: 'error', message: Object.values(err.response.data.message)[index] });
              })
            }

          });

      }
    }
  };


  const handleRegister = (e) => {
    // e.preventDefault();
    if (isRegisterUser == false) {
      setOpenRegister(true);
      dispatch(toggle_isRegister(true))
    } else {
      setOpenRegister(false);
      dispatch(toggle_isRegister(false))
    }
  };
  const handleRegisterClose = () => {
    setOpenRegister(false);
    dispatch(toggle_isRegister(false))
    resetCreateAccount();
  };



  const toggleDateOpen = () => {
    setOpenDate(!openDate);
  };

  const handleDateChange = (dateVal, onChange) => {
    let temp = new Date(dateVal);

    let new_date = temp.toLocaleDateString('en-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });

    onChange(new_date);
    toggleDateOpen();
  };

  const handleSignUp = async (data) => {
    // console.log(data);
    await user_register_auth(data)
      .then((res) => {
        // console.log("handleSignUp->res", res);
        SuccessToast(res.message);
        handleRegisterClose()
        setTimeout(() => {
          handleLogin();
        }, 1000);

      })
      .catch((err) => {
        // console.log("handleSignUp->err", err);
        if (typeof err.response.data.message == 'object') {
          // // console.log(' == object')

          Object.keys(err.response.data.message).forEach((item, index) => {
            // // console.log(item, { type: 'error', message: Object.values(index) })
            createAccountSetError(item, { type: 'error', message: Object.values(err.response.data.message)[index] });
          })
        }
        ErrorToast("Register Failed");
      });
  };

  const checkUserLogin = () => {
    let user_info = JSON.parse(localStorage.getItem("user"));
    if (user_info !== null) {
      if (new Date() < new Date(user_info.expires_at)) {
        dispatch(user_login(user_info));
        setIsLoginUser(true);
      } else {
        handleLogout();
      }
    }
  };

  const handleLogout = async () => {
    // API
    setIsLoginUser(false);
    if (checkNotNull(user)) {
      let token = user.token_type + " " + user.access_token;
      // // console.log(token);
      await userLogout(token)
        .then((res) => {
          // console.log("userLogout->res", res);
          SuccessToast(res.message);
          dispatch(user_logout());
        })
        .catch((err) => {
          // console.log("userLogout->err", err);
          // ErrorToast("Register Failed");
        });
    }


  };


  const handleForgotPwd = (bool) => {
    if (typeof bool == 'boolean') {
      setForgetPwd(bool);
    } else {
      setForgetPwd(!forgetPwd);
      if (!forgetPwd == true) {
        loginUnregister("password");
      } else {
        loginRegister("password");
      }
    }
    if (resetPwd) {
      loginRegister('email');
      loginRegister("password");
    } else if (forgetPwd) {
      loginUnregister("c_password");
      loginUnregister("password");
      loginUnregister("verification_code");
    }
    resetLogin()
  }

  useEffect(() => {
    setFilterList(null);
  }, [language])

  useEffect(() => {
    if (user == null) {
      checkUserLogin();
    }
  }, []);

  const checkLangCookies = () => {
    let lang = localStorage.getItem("i18nextLng");
    changeLang(lang);
  };
  const changeLang = (lang) => {
    //// console.log(lang);
    // 1 : en-us
    // 2 : zh-hk
    // 3 : zh-cn
    switch (lang.toLowerCase()) {
      case "en-us": {
        dispatch(change_language(1));
        break;
      }case "en": {
        dispatch(change_language(1));
        break;
      }
      case "zh-cn": {
        dispatch(change_language(3));
        break;
      }
      case "cn": {
        dispatch(change_language(3));
        break;
      }
      case "zh-hk": {
        dispatch(change_language(2));
        break;
      }
      case "hk": {
        dispatch(change_language(2));
        break;
      }
    }
    i18n.changeLanguage(lang);
    toggleShowLang(false)
  };

  const toggleShowLang = (bool) => {

    setShowLang(bool);
  }


  const toggleDrawer = (open) => {
    setShowLeft(open);
  };

  const searchFilterCategoriesClass = (num) => {
    return filterCategoriesClick == num ? "context_item item-click" : "context_item";
  };
  const searchFilterClass = (num) => {
    return filterClick == num ? "context_item item-click" : "context_item";
  };

  const scrollUp = () => {
    if (
      window.pageYOffset >
      headerRef.current.offsetHeight - stcikyRef.current.offsetHeight
    ) {
      stcikyRef.current.classList.add("sticky");
    } else {
      stcikyRef.current.classList.remove("sticky");
    }
  };

  const checkPathForScroll = () => {
    if (location.pathname == "/blog") {
      headerRef.current.style.display = "block ";
      stcikyRef.current.style.height = "0";
      blogMenuRef.current.style.display = "flex";
      mainMenuRef.current.style.height = "auto";
      belunMenuRef.current.style.display = "none";
    } else if (location.pathname.startsWith("/belun")) {
      belunMenuRef.current.style.display = "block";
      headerRef.current.style.display = "none ";
    } else {
      belunMenuRef.current.style.display = "none";
      stcikyRef.current.style.height = "unset";
      headerRef.current.style.display = "block ";
      stcikyRef.current.style.display = "flex";
      blogMenuRef.current.style.display = "none";
    }
  };

  useEffect(() => {
    //// console.log('header render()');
    checkLangCookies();
    checkPathForScroll();
    document.addEventListener("scroll", scrollUp);
    return () => {
      document.removeEventListener("scroll", scrollUp);
    };
  }, [location]);

  const handleFilterCategoriesClick = (id) => {
    // console.log(id)
    setFilterCategoriesClick(id);
    if (checkNotNull(info)) {
      let new_lists = info.filter_categories.filter((item, index) => {
        return item.id == id;
      })
      setFilterList(new_lists[0].filters);
      dispatch(get_search_filter_lists(new_lists[0].filters));
      setFilterClick(null);
    }
  }
  const handleFilterClick = (id) => {
    if (filterClick == id) {
      setFilterClick(null);
    } else {
      setFilterClick(id);
    }
  }
  const handleFilterSearch = () => {
    // console.log('filterClick: ', filterClick);
    // console.log('filterCategoriesClick: ', filterCategoriesClick);
    // console.log('searchText: ', searchText);
    let searchStr = {};
    if (filterClick != null) {
      searchStr.filter_id = filterClick;
    }
    if (filterCategoriesClick != null) {
      searchStr.filter_category_id = filterCategoriesClick;
    }
    if (searchText != '') {
      searchStr.q = searchText;
    }
    // console.log('searchStr->', searchStr);
    navigate({ pathname: "/health", search: `?${createSearchParams(searchStr)}`, state: filterCategoriesClick != null ? { "filter_category_id": filterCategoriesClick } : null });
    setFilterClick(null);
    setFilterCategoriesClick(null);
    searchRef.current.classList.remove("focus-search")
    setSearchText('')
  }

  useEffect(() => {
    if (checkNotNull(info)) {
      setFilterList(info.filter);

    }
  }, [])



  const [leftNavPath, setLeftNavPath] = useState('/');
  const [leftNavPathname, setLeftNavPathname] = useState('');


  const decoratedOnClick = useAccordionButton(leftNavPathname, (num) => {
    //// console.log('decoratedOnClick->leftNavPathname,num', leftNavPathname, num);
    if (leftNavPathname != num) {
      setLeftNavPathname('' + num)
    } else {

      setLeftNavPathname('')

    }

  },
  );

  useEffect(() => {
    if (showLeft == true) {
      //// console.log('showLeft->location', location);

      if (location.pathname.includes('/health') == true) {
        //// console.log("location.pathname.indexOf('/health')");
        setLeftNavPathname('1');
      } else if (location.pathname.includes('/belun') == true) {
        //// console.log("location.pathname.indexOf('/belun')");
        setLeftNavPathname('2');
      } else {
        setLeftNavPathname('');
      }
      //// console.log(leftNavPathname)
      let temp = location.pathname + location.search;
      setLeftNavPath(temp);
    }
  }, [showLeft])




  return (
    <>
      <Modal
        open={openLogin}
        onClose={handleLoginClose}
        className="login_modal"
        sx={{ zIndex: '900' }}
      >
        <Grid
          container
          className="modal_box"
          sx={{ width: { md: "800px", sm: "80%", xs: "100%" } }}
        >
          <Grid item xs={12} md={5} className="left_box">
            <form onSubmit={loginHandleSubmit(handleSignIn)}>
              <Grid container className="top_bar">
                <IconButton onClick={() => {
                  if (forgetPwd == true && !resetPwd) {
                    handleForgotPwd();
                    setForgetPwd(false);
                  } else if (forgetPwd == false && resetPwd) {
                    handleForgotPwd();
                    setResetPwd(false);
                  }
                  else {
                    handleLoginClose();
                    setResetPwd(false);
                    setForgetPwd(false);
                  }
                }}>
                  <ArrowBackIcon />
                </IconButton>
                <Link
                  to="#"
                  className="reg_btn"
                  onClick={(e) => {
                    handleLoginClose();
                    // setOpenRegister(true);
                    handleRegister();
                  }}
                >
                  {t("register_header_title.1")}
                </Link>
              </Grid>
              <Grid className="bottom_bar">
                <Typography variant="h6">
                  {t("Login_header_title.1")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", mb: 2, color: "#666" }}
                >
                  {t("Login_header_sub_title.1")}
                </Typography>

                <Search
                  className="search_div"
                  sx={{
                    width: { xs: "100%" },
                    mb: 1,
                    display: { xs: "block" },
                  }}
                >
                  <StyledInputBase
                    className="email_input"
                    placeholder={t("Login_header_email_placeholder.1")}
                    sx={{ width: "100%", fontSize: "13px" }}
                    {...loginRegister("email", { required: true })}
                  />
                </Search>
                {loginErrors.email && loginErrors.email.type === "required" ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {t("header_required.1")}
                  </Typography>
                ) : loginErrors.email && <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", mb: 1, color: "red" }}
                >
                  {loginErrors.email.message}
                </Typography>}

                {!forgetPwd ?
                  <Search
                    className="search_div"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="password_input"
                      placeholder={t("Login_header_pwd_placeholder.1")}
                      type="password"
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...loginRegister("password", { required: true })}
                    />
                  </Search> : null}
                {!forgetPwd && loginErrors.password &&
                  loginErrors.password.type === "required" ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {t("header_required.1")}
                  </Typography>
                ) : loginErrors.password && <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", mb: 1, color: "red" }}
                >
                  {loginErrors.password.message}
                </Typography>
                }
                {!forgetPwd && resetPwd ?
                  <Search
                    className="search_div"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="password_input"
                      placeholder={t("Login_header_reset_c_pwd_placeholder.1")}
                      type="password"
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...loginRegister("c_password", { required: true })}
                    />
                  </Search> : null}
                {!forgetPwd && resetPwd && loginErrors.c_password &&
                  loginErrors.c_password.type === "required" ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {t("header_required.1")}
                  </Typography>
                ) : loginErrors.c_password && <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", mb: 1, color: "red" }}
                >
                  {loginErrors.c_password.message}
                </Typography>}
                {!forgetPwd && resetPwd ?
                  <Search
                    className="search_div"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="password_input"
                      placeholder={t("Login_header_reset_v_code_placeholder.1")}
                      type="text"
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...loginRegister("verification_code", { required: true })}
                    />
                  </Search> : null}
                {!forgetPwd && resetPwd && loginErrors.verification_code &&
                  loginErrors.verification_code.type === "required" ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {t("header_required.1")}
                  </Typography>
                ) : loginErrors.verification_code && <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", mb: 1, color: "red" }}
                >
                  {loginErrors.verification_code.message}
                </Typography>}

                {!forgetPwd && !resetPwd && <Grid item sx={{ textAlign: "right", margin: "8px 0" }}>
                  <Link to="#" className="forget_btn" onClick={() => handleForgotPwd()}>
                    {t("Login_header_forget_pw.1")}
                  </Link>
                </Grid>}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "teal",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    "&:hover": {
                      backgroundColor: "green",
                    },
                  }}
                  type="submit"
                >
                  {forgetPwd ? t("Login_header_forget_send.1") : resetPwd ? t("Login_header_forget_reset.1") : t("Login_header_sign_in.1")}
                </Button>
              </Grid>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: { xs: "none", md: "block" } }}
            className="right_box"
          >
            <CardMedia
              image={family}
              component="img"
              className="bg_img"
            ></CardMedia>
            <Typography variant="h6" className="img_text">
              {t("Login_header_img_text.1")}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openRegister}
        onClose={handleRegisterClose}
        className="register_modal"
        sx={{ zIndex: '900' }}
      >
        <Grid
          container
          className="modal_box"
          sx={{ width: { lg: "1000px", md: "900px", sm: "80%", xs: "100%" } }}
        >

          <Grid item xs={12} md={5} className="left_box" sx={{ padding: { xs: '16px 16px 16px 16px', md: '16px 16px 64px 16px' } }}>
            <form onSubmit={createAccountHandleSubmit(handleSignUp)}>
              <Grid container className="top_bar">
                <IconButton onClick={handleRegisterClose}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid className="bottom_bar">
                <Typography variant="h6">
                  {t("register_header_title.1")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "13px", mb: 2, color: "#f0506e" }}
                >
                  {t("booking_warn_text.1")}
                </Typography>

                <Grid container spacing={2}>

                  <Grid item xs>
                    <Search
                      className="search_div"
                      sx={{
                        width: { xs: "100%" },
                        mb: 1,
                        display: { xs: "block" },
                      }}
                    >
                      <StyledInputBase
                        className="register_input"
                        placeholder={t(
                          "register_header_firstname_placeholder.1"
                        )}
                        sx={{ width: "100%", fontSize: "13px" }}
                        {...createAccountRegister("first_name", {
                          required: true,
                        })}
                      />
                    </Search>
                    {createAccountErrors.first_name &&
                      createAccountErrors.first_name.type === "required" ? (
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "12px", mb: 1, color: "red" }}
                      >
                        {t("header_required.1")}
                      </Typography>
                    ) : createAccountErrors.first_name && <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {createAccountErrors.first_name.message}
                    </Typography>}
                  </Grid>
                  <Grid item xs>
                    <Search
                      className="search_div"
                      sx={{
                        width: { xs: "100%" },
                        mb: 1,
                        display: { xs: "block" },
                      }}
                    >
                      <StyledInputBase
                        className="register_input"
                        placeholder={t(
                          "register_header_lastname_placeholder.1"
                        )}
                        sx={{ width: "100%", fontSize: "13px" }}
                        {...createAccountRegister("last_name", {
                          required: true,
                        })}
                      />
                    </Search>
                    {createAccountErrors.last_name &&
                      createAccountErrors.last_name.type === "required" ? (
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "12px", mb: 1, color: "red" }}
                      >
                        {t("header_required.1")}
                      </Typography>
                    ) : createAccountErrors.last_name && <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {createAccountErrors.last_name.message}
                    </Typography>}
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={7}>
                    <Search
                      className="search_div"
                      sx={{
                        width: { xs: "100%" },
                        mb: 1,
                        display: { xs: "block" },
                      }}
                    >
                      <StyledInputBase
                        className="register_input"
                        placeholder={t("register_header_email_placeholder.1")}
                        sx={{ width: "100%", fontSize: "13px" }}
                        {...createAccountRegister("email", {
                          required: true,
                        })}
                      />
                    </Search>
                    {createAccountErrors.email &&
                      createAccountErrors.email.type === "required" ? (
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "12px", mb: 1, color: "red" }}
                      >
                        {t("header_required.1")}
                      </Typography>
                    ) : createAccountErrors.email && <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {createAccountErrors.email.message}
                    </Typography>}
                  </Grid>
                  <Grid item xs={5} className="gender_box">
                    <Controller
                      name="title"
                      control={createAccountControl}
                      rules={{ required: true }}

                      render={({ field: { value, onChange } }) => (
                        <Select
                          className="title_select"
                          placeholder={t("register_header_title_placeholder.1")}
                          options={[
                            { value: "mr", label: t("register_header_title_mr_placeholder.1") },
                            { value: "ms", label: t("register_header_title_ms_placeholder.1") },
                            { value: "mrs", label: t("register_header_title_mrs_placeholder.1") },
                          ]}

                          onChange={(e) => onChange(e.value)}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "unset",
                              border: 0,
                              backgroundColor: "#efefef",
                              fontSize: "13px",
                            }),
                            menu: (provided, state) => ({
                              ...provided,
                              margin: 0,
                              fontSize: "13px",
                              backgroundColor: "#efefef",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#efefef",
                              color: "#666",
                            }),
                            ":active": (styles) => ({
                              ...styles[":active"],
                              backgroundColor: "red",
                            }),
                          }}
                        />
                      )}
                    />
                    {createAccountErrors.title &&
                      createAccountErrors.title.type === "required" ? (
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "12px", mb: 1, color: "red" }}
                      >
                        {t("header_required.1")}
                      </Typography>
                    ) : createAccountErrors.title && <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {createAccountErrors.title.message}
                    </Typography>}
                  </Grid>
                </Grid>
                <Grid item xs={12} className="date_box">

                  <ClickAwayListener
                    onClickAway={() => setOpenDate(false)}
                  >
                    <Box>
                      {/* <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                      > */}
                      <Controller
                        name="date"
                        control={createAccountControl}
                        rules={{}}
                        defaultValue={''}
                        render={({ field: { value, onChange } }) => (
                          <Box>
                            <Search
                              className="search_div"
                              sx={{
                                width: { xs: "100%" },
                                height: "100%",
                                display: { xs: "block" },
                              }}
                            >
                              <StyledInputBase
                                className="booking_input"
                                placeholder={t("register_header_date_placeholder.1")}
                                sx={{
                                  width: "100%",
                                  fontSize: "13px",
                                }}
                                onClick={toggleDateOpen}
                                value={value}
                                readOnly
                              />
                            </Search>
                            <Box
                              sx={{
                                display: openDate ? "block" : "none",
                                position: 'relative'
                              }}
                            >
                              {/* <CalendarPicker

                                  onChange={(val) =>
                                    handleDateChange(val, onChange)
                                  }
                                /> */}
                              <Datepicker changeDate={(val) =>
                                handleDateChange(val, onChange)
                              } dateWidth={'100%'} />
                            </Box>
                          </Box>
                        )}
                      />
                      {/* </LocalizationProvider> */}
                      {createAccountErrors.date &&
                        createAccountErrors.date.type === "required" ? (
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "12px", color: "red" }}
                        >
                          {t("header_required.1")}
                        </Typography>
                      ) : createAccountErrors.date && <Typography
                        variant="body1"
                        sx={{ fontSize: "12px", mb: 1, color: "red" }}
                      >
                        {createAccountErrors.date.message}
                      </Typography>}
                    </Box>
                  </ClickAwayListener>
                </Grid>
                <Grid item xs>
                  <Search
                    className="search_div"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="register_input"
                      placeholder={t("register_header_telephone_placeholder.1")}
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...createAccountRegister("telephone", {
                        required: true,
                      })}
                    />
                  </Search>
                  {createAccountErrors.telephone &&
                    createAccountErrors.telephone.type === "required" ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {t("header_required.1")}
                    </Typography>
                  ) : createAccountErrors.telephone && <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {createAccountErrors.telephone.message}
                  </Typography>}
                </Grid>
                <Grid item xs>
                  <Search
                    className="search_div"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="register_input"
                      placeholder={t("register_header_mobile_placeholder.1")}
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...createAccountRegister("mobile", {
                        required: true,
                      })}
                    />
                  </Search>
                  {createAccountErrors.mobile &&
                    createAccountErrors.mobile.type === "required" ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {t("header_required.1")}
                    </Typography>
                  ) : createAccountErrors.mobile && <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {createAccountErrors.mobile.message}
                  </Typography>}
                </Grid>
                <Grid item xs={12}>
                  <Search
                    className="search_div_pwd"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="password_input"
                      placeholder={t("register_header_pwd_placeholder.1")}
                      type="password"
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...createAccountRegister("password", { required: true })}
                    />
                  </Search>

                  {createAccountErrors.password &&
                    createAccountErrors.password.type === "required" ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {t("header_required.1")}
                    </Typography>
                  ) : createAccountErrors.password && <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {createAccountErrors.password.message}
                  </Typography>}
                </Grid>
                <Grid item xs={12}>
                  <Search
                    className="search_div_pwd"
                    sx={{
                      width: { xs: "100%" },
                      mb: 1,
                      display: { xs: "block" },
                    }}
                  >
                    <StyledInputBase
                      className="password_input"
                      placeholder={t("register_header_c_password_placeholder.1")}
                      type="password"
                      sx={{ width: "100%", fontSize: "13px" }}
                      {...createAccountRegister("c_password", { required: true })}
                    />
                  </Search>
                  {createAccountErrors.c_password &&
                    createAccountErrors.c_password.type === "required" ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "12px", mb: 1, color: "red" }}
                    >
                      {t("header_required.1")}
                    </Typography>
                  ) : createAccountErrors.c_password && <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {createAccountErrors.c_password.message}
                  </Typography>}
                </Grid>


                <Button
                  className="sign_up_btn"
                  variant="contained"
                  sx={{
                    backgroundColor: "teal",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    "&:hover": {
                      backgroundColor: "green",
                    },
                  }}
                  type="submit"
                >
                  {t("register_header_sign_up.1")}
                </Button>
                <FormControlLabel
                  className="label_ck"
                  control={
                    <Checkbox
                      className="checkbox_btn"
                      {...createAccountRegister("checked", { required: true })}
                      sx={{
                        color: createAccountErrors.checked &&
                          createAccountErrors.checked.type === "required" ? 'red' : 'inital'
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" className="checkout_text"

                    >
                      {t("register_header_checkbox_text_1.1")}
                      &nbsp;
                      <Link
                        to="/question/terms_and_conditions"
                        onClick={handleRegisterClose}
                      >
                        {t("register_header_checkbox_text_2.1")}
                      </Link>
                      {t("register_header_checkbox_text_3.1")}
                      &nbsp;
                      <Link
                        to="/question/privacy_policy"
                        onClick={handleRegisterClose}
                      >
                        {t("register_header_checkbox_text_4.1")}
                      </Link>
                      &nbsp;
                      {t("register_header_checkbox_text_5.1")}
                      &nbsp;
                      <Link
                        to="/question/personal_info"
                        onClick={handleRegisterClose}
                      >
                        {t("register_header_checkbox_text_6.1")}
                      </Link>
                    </Typography>
                  }
                />
                {createAccountErrors.checked &&
                  createAccountErrors.checked.type === "required" ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                  >
                    {t("header_required.1")}
                  </Typography>
                ) : createAccountErrors.checked && <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", mb: 1, color: "red" }}
                >
                  {createAccountErrors.checked.message}
                </Typography>}
              </Grid>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: { xs: "none", md: "block" } }}
            className="right_box"
          >
            <CardMedia
              image={family}
              component="img"
              className="bg_img"
            ></CardMedia>
            <Typography variant="h6" className="img_text">
              {t("Login_header_img_text.1")}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
      <Box
        className="belun_header_section"
        ref={belunMenuRef}
        sx={{ display: "none" }}
      >
        <AppBar className="header_div" position="static">
          <Container maxWidth="lg">
            <Toolbar sx={{ height: "80px" }}>
              <Box
                className="header_center"
                sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              >
                <Link className="a_links" to="/belun">
                  {t("sleep_header_overview.1")}
                </Link>
                <Link className="a_links" to="/belun/how">
                  {t("sleep_header_how_to_use.1")}
                </Link>
                <Link className="a_links" to="/">
                  <img src={logo} style={{ maxWidth: "160px" }} />
                </Link>
                <Link className="a_links" to="/belun/terms">
                  {t("sleep_header_terms.1")}
                </Link>
                <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                  <div className="show_sub_menu" style={{ height: '100%' }}>
                    <div className={`lang_link desktop row`} >
                      <LanguageIcon fontSize="medium" />

                      <Typography
                        sx={{
                          display: { xs: "none", md: "inline-block" },
                          width: "auto",
                          pr: 0,
                          color: "#333",
                        }}
                        component={"span"}
                      >
                        {t("Current_lang.1")}
                      </Typography>
                    </div>
                    <div className={`langs `} >
                      <div className="lang_div">
                        <div className="langs_context">
                          <Link
                            className="langs"
                            to="#"
                            onClick={() => changeLang("en")}
                          >
                            <h6>ENGLISH </h6>
                            <div className="icons">
                              <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg" />
                              <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg" />
                            </div>
                          </Link>
                          <Link
                            to="#"
                            className="langs"
                            onClick={() => changeLang("hk")}
                          >
                            <h6>繁體中文 </h6>
                            <div className="icons">
                              <img src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg" />
                            </div>
                          </Link>
                          <Link
                            className="langs"
                            to="#"
                            onClick={() => changeLang("cn")}
                          >
                            <h6>简体中文 </h6>
                            <div className="icons">
                              <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg" />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* <Box sx={{ display: { md: 'none', xs: 'block' } }}>

                  <div className="show_sub_menu" >
                    <div className={`lang_link mobile`} >
                      <LanguageIcon
                        fontSize="medium"
                        sx={{ color: "#666", marginRight: "8px" }}
                      />

                      <Typography
                        sx={{
                          display: { xs: "none", md: "inline-block" },
                          width: "auto",
                          pr: 0,
                          color: "#333",
                        }}
                        component={"span"}
                      >
                        {t("Current_lang.1")}
                      </Typography>
                    </div>
                    <Box sx={{ display: showLang == true ? 'block' : 'none' }}>
                      <div className={`langs `} >
                        <div className="lang_div">
                          <div className="langs_context">
                            <Link
                              className="langs"
                              to="#"
                              onClick={() => changeLang("en-us")}
                            >
                              <h6>ENGLISH </h6>
                              <div className="icons">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg" />
                                <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg" />
                              </div>
                            </Link>
                            <Link
                              to="#"
                              className="langs"
                              onClick={() => changeLang("zh-hk")}
                            >
                              <h6>繁體中文 </h6>
                              <div className="icons">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg" />
                              </div>
                            </Link>
                            <Link
                              className="langs"
                              to="#"
                              onClick={() => changeLang("zh-cn")}
                            >
                              <h6>简体中文 </h6>
                              <div className="icons">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg" />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                </Box> */}

              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  onClick={() => toggleDrawer(true)}
                  sx={{ color: "#666" }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  mr: 2,
                }}
              >
                <Link className="a_links" to="/">
                  <img src={logo} style={{ maxWidth: "160px" }} />
                </Link>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <Box
        className="header_section"
        ref={headerRef}
        sx={{
          marginBottom: {
            xs: "117px"//, sm: "180px"
            , md: "0"
          }
        }}
      >
        <div className="top_menu row">
          <Container maxWidth="lg">
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <div className="left_div col">
                <Link to="/about">{t("header_about.1")}</Link>
                {/* <Link to="/question/contact_us">{t("header_contact.1")}</Link> */}
                <Link to="/question/FAQ">{t("header_faqs.1")}</Link>
              </div>

              <div className="right_div col">
                <Link
                  to={isLoginUser == true ? "/account" : "#"}
                  onClick={(e) => handleLogin(e)}
                >
                  {user == null ? t("header_login.1") : user.userData.email}
                </Link>
                {user == null ? (
                  <Link to="#" onClick={() => handleRegister()}>
                    {t("header_register.1")}
                  </Link>
                ) : (
                  <Link to="#" onClick={handleLogout}>
                    {t("header_logout.1")}
                  </Link>
                )}
              </div>
            </Box>
          </Container>
        </div>

        <div className="header_div">
          <Box
            className="main_menu row"
            ref={mainMenuRef}
            sx={{
              height: {
                //xs: "117px", //sm: "205px" 
              },
              position: { md: "initial", xs: "fixed" },
              width: "100%",
              top: { xs: "0", md: "unset" },
              zIndex: { xs: "800" },
              backgroundColor: "white",
              boxShadow: { xs: "0 5px 15px rgb(0 0 0 / 8%)", md: "none" },
            }}
          >
            <Container maxWidth="lg">
              <AppBar
                position="static"
                sx={{
                  boxShadow: "none",
                }}
                style={{ backgroundColor: "white" }}
              >
                <Toolbar
                  sx={{
                    pl: {
                      xs: 0,
                    },
                    pr: {
                      xs: 0,
                    },
                    pt: 4,
                    pb: 3,
                  }}
                >
                  <Link to="/" style={{ height: "100%" }}>
                    <img src={logo} style={{ maxWidth: "160px" }} />
                  </Link>

                  <ClickAwayListener
                    onClickAway={() => {
                      searchRef.current.classList.remove("focus-search");
                      setFilterClick(null);
                      setFilterCategoriesClick(null);
                    }}
                  >
                    <Search
                      className="search_div"
                      sx={{
                        ml: { md: 5, xs: 1 },
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      <StyledInputBase
                        className="input_bar"
                        placeholder={t("main_header_search_placeholder.1")}
                        inputProps={{
                          "aria-label": "search",
                        }}
                        sx={{ width: "100%" }}
                        onFocus={() => {
                          searchRef.current.classList.add("focus-search");
                          // // console.log(searchRef.current.classList)
                        }}
                        value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}

                      />

                      <Button onClick={handleFilterSearch} className="search_btn">
                        {/* <Link to="#" className="search_btn"> */}
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                        {/* </Link> */}
                      </Button>

                      <div
                        id="search_submenu"
                        className="search_submenu"
                        ref={searchRef}
                        style={{ minWidth: "700px" }}
                      >
                        <h6>{t("main_header_filter_categorys_title.1")}</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={2} className="type_name">
                            <h6>{t("main_header_filter_categorys_service.1")}</h6>
                          </Grid>
                          <Grid item xs={10} className="type_context">
                            {info && info.filter_categories.map((item, index) => {
                              return (
                                <Link
                                  to="#"
                                  className={searchFilterCategoriesClass(item.id)}
                                  onClick={() => handleFilterCategoriesClick(item.id)}
                                  key={item.id}
                                >
                                  {item.category_name}
                                </Link>)
                            })}
                          </Grid>
                          {filterList != null && filterList.length >= 1 ? <>
                            <Grid item xs={2} className="type_name">
                              <h6>{t("main_header_filter_categorys_categorys.1")}</h6>
                            </Grid>
                            <Grid item xs={10} className="type_context">
                              {filterList != null && filterList.map((item, index) => {
                                return (
                                  <Link
                                    to="#"
                                    className={searchFilterClass(item.id)}
                                    onClick={() => handleFilterClick(item.id)}
                                    key={item.id}
                                  >
                                    {item.filter_name}
                                  </Link>)
                              })}
                            </Grid> </> : null}
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "teal",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                width: "100%",
                                borderRadius: 0,
                                "&:hover": {
                                  backgroundColor: "green",
                                },
                              }}
                              type="submit"
                              onClick={handleFilterSearch}
                            >
                              {t("main_header_filter_search_btn.1")}
                            </Button>
                          </Grid>

                        </Grid>
                      </div>
                    </Search>
                  </ClickAwayListener>
                  <Box sx={{ flexGrow: 1 }} />

                  <IconButton
                    size="large"
                    sx={{
                      mr: {
                        xs: 0,
                        md: 2,
                      },
                    }}
                    onClick={() => toggleDrawer(true)}
                    style={{ color: "black" }}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Typography
                    sx={{
                      display: { xs: "none", md: "flex" },
                      mr: { md: 2, xs: 0 },
                    }}
                    component={"span"}
                  >{info == null ? null :
                    <a href={"tel:+852" + info.info.tel[0].value.split(" ")[0] + info.info.tel[0].value.split(" ")[1]} className="phone_link row">
                      <FontAwesomeIcon size="lg" icon={faPhone} />
                      <h6>{info.info.tel[0].value}</h6>
                    </a>
                    }
                  </Typography>

                  <IconButton
                    size="large"
                    sx={{
                      mr: { md: 2, xs: 0 },
                      ml: { md: 2, xs: 0 },
                    }}
                    style={{ color: "#9e9e9e" }}
                    onClick={() => navigate('/cart')}
                  >
                    <FontAwesomeIcon size="xs" icon={faCartShopping} />
                  </IconButton>
                  <Box sx={{ display: { md: 'block', xs: 'none' } }}>


                    <div className={`show_sub_menu`} >
                      <div className={`lang_link desktop row`} >
                        <LanguageIcon fontSize="medium" />

                        <Typography
                          sx={{
                            display: { xs: "none", md: "inline-block" },
                            width: "auto",
                            pr: 0,
                            color: "#333",
                          }}
                          component={"span"}
                        >
                          {t("Current_lang.1")}
                        </Typography>
                      </div>
                      <div className={`langs `}>
                        <div className="lang_div">
                          <div className="langs_context">
                            <Link
                              className="langs"
                              to="#"
                              onClick={() => changeLang("en")}
                            >
                              <h6>ENGLISH </h6>
                              <div className="icons">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg" />
                                <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg" />
                              </div>
                            </Link>
                            <Link
                              to="#"
                              className="langs"
                              onClick={() => changeLang("hk")}
                            >
                              <h6>繁體中文 </h6>
                              <div className="icons">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg" />
                              </div>
                            </Link>
                            <Link
                              className="langs"
                              to="#"
                              onClick={() => changeLang("cn")}
                            >
                              <h6>简体中文 </h6>
                              <div className="icons">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg" />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                  <ClickAwayListener
                    onClickAway={() => {
                      toggleShowLang(false)
                    }}
                  >
                    <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                      <div className="show_sub_menu" >
                        <div className={`lang_link mobile row`} >
                          <IconButton onClick={() => toggleShowLang(!showLang)}>
                            <LanguageIcon
                              fontSize="medium"
                              sx={{ color: "#666", marginRight: "8px" }}
                            />

                            <Typography
                              sx={{
                                display: { xs: "none", md: "inline-block" },
                                width: "auto",
                                pr: 0,
                                color: "#333",
                              }}
                              component={"span"}
                            >
                              {t("Current_lang.1")}
                            </Typography>
                          </IconButton>
                        </div>
                        <Box sx={{ display: showLang == true ? 'block' : 'none' }}>
                          <div className={`langs `} style={{ display: 'block' }}>
                            <div className="lang_div">
                              <div className="langs_context">
                                <Link
                                  className="langs"
                                  to="#"
                                  onClick={() => changeLang("en")}
                                >
                                  <h6>ENGLISH </h6>
                                  <div className="icons">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg" />
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg" />
                                  </div>
                                </Link>
                                <Link
                                  to="#"
                                  className="langs"
                                  onClick={() => changeLang("hk")}
                                >
                                  <h6>繁體中文 </h6>
                                  <div className="icons">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg" />
                                  </div>
                                </Link>
                                <Link
                                  className="langs"
                                  to="#"
                                  onClick={() => changeLang("cn")}
                                >
                                  <h6>简体中文 </h6>
                                  <div className="icons">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg" />
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </ClickAwayListener>
                </Toolbar>
                {/* <ClickAwayListener
                  onClickAway={() =>
                    searchRef2.current.classList.remove("focus-search")
                  }
                >
                  <Search
                    className="search_div"
                    sx={{
                      ml: 0,
                      mr: 0,
                      width: "100%",
                      mb: 3,
                      display: { xs: "none", sm: "flex", md: "none" },
                    }}
                  >
                    <StyledInputBase
                      className="input_bar"
                      sx={{ width: "100%" }}
                      placeholder="Keyword, Center, Type, Filter or Screening"
                      inputProps={{ "aria-label": "search" }}
                      onFocus={() => {
                        searchRef2.current.classList.add("focus-search");
                        // // console.log(searchRef.current.classList)
                      }}
                    />
                    <div className="search_btn">
                      <Link to="#">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Link>
                    </div>
                    <div className="search_submenu" ref={searchRef2}>
                      <h6>請選擇相關項目/類型:</h6>
                      <Grid container>
                        <Grid item xs={3} className="type_name">
                          <h6>服務</h6>
                        </Grid>
                        <Grid item xs={9} className="type_context">
                          <Link
                            to="#"
                            className={searchItemClass(0)}
                            onClick={() => {
                              setSearchClick(0);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                          <Link
                            to="#"
                            className={searchItemClass(1)}
                            onClick={() => {
                              setSearchClick(1);
                            }}
                          >
                            年度健康檢查
                          </Link>
                        </Grid>
                      </Grid>
                    </div>
                  </Search>
                </ClickAwayListener> */}
                <Box
                  id="header_scroll_menu"
                  ref={stcikyRef}
                  className="scroll_menu"
                  sx={{
                    display: { xs: "none !important", md: "flex !important" },
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    className="scroll_div"
                    sx={{
                      justifyContent: { lg: "center" },
                    }}
                  >
                    <div className="show_sub_menu">
                      <Link to={{ pathname: "/health" }} state={{ reset: true }} className="menu_link">
                        {t("main_header_health_services.1")}
                      </Link>
                      <Box
                        className="sub_menu_container"
                        sx={{ padding: "25px" }}
                      >
                        <Container
                          maxWidth="lg"
                          sx={{ padding: { xs: "0", lg: "0 24px" } }}
                        >
                          <div className="sub_menu">
                            <Container maxWidth="lg">
                              <Grid container className="menu">
                                {" "}
                                {checkNotNull(info)
                                  ? info.filter_categories.map(
                                    (item, index) => {
                                      return (
                                        <Grid
                                          item
                                          xs={3}
                                          className="menu_item"
                                          key={item.id}
                                        >
                                          <Box
                                            className="item"
                                            sx={{ textAlign: "left" }}
                                          >
                                            <Box className="context">
                                              <Link
                                                to={{
                                                  pathname: "/health",
                                                  search: `?filter_category_id=${item.id}`
                                                }}
                                                className="sub_menu_link"

                                              >
                                                {item.category_name}
                                              </Link>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      );
                                    }
                                  )
                                  : null}
                              </Grid>
                            </Container>
                          </div>
                        </Container >
                      </Box >
                    </div >

                    {
                      showMenu != null && showMenu.length >= 1 ?
                        showMenu.map((item, index) => {
                          return (
                            <Link
                              to={{
                                pathname: "/health",
                                search: `?filter_category_id=${item.id}`
                              }}
                              className="menu_link"
                              key={item.id}
                            >

                              {item.category_name}
                            </Link>
                          )
                        })
                        : null
                    }





                    <Link to="/belun" className="menu_link">
                      {t("main_header_sleep.1")}
                    </Link>
                    {/* <Link to="/blog" className="menu_link">
                      {t("main_header_news.1")}
                    </Link> */}
                    <Link to="/centre" className="menu_link">
                      {t("main_header_medical_centre.1")}
                    </Link>
                  </Box >
                </Box >

                <Box
                  id="blog_menu"
                  ref={blogMenuRef}
                  className="scroll_menu"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    className="scroll_div"
                    sx={{
                      justifyContent: { lg: "center" },
                    }}
                  >
                    {news_category_list.lists == null ? null : (
                      <Link
                        to={{ pathname: "/blog" }}
                        className="menu_link"
                        onClick={() => dispatch(select_categroy_header(0))}
                      >
                        {t("main_header_blog_All.1")}
                      </Link>
                    )}
                    {news_category_list.lists == null
                      ? null
                      : news_category_list.lists.map(item => {
                        return item.news_category_contents.length >= 1 && item.news_category_contents.filter(item => item.language_id == language).map(
                          (item, index) => {
                            return (
                              <Link
                                to={{ pathname: "/blog" }}
                                className="menu_link"
                                key={item.id}
                                onClick={() =>
                                  dispatch(select_categroy_header(item.id))
                                }
                              >
                                {item.name}
                              </Link>
                            );
                          }
                        )
                      })}
                  </Box>
                </Box>
              </AppBar >
            </Container >
          </Box >
        </div >

        <SwipeableDrawer
          className="drawer_left"
          open={showLeft}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <Box className="left_nav" sx={{ width: { md: 370, xs: 270 } }}>
            <ListGroup>
              <ListGroup.Item className="nav_list_item">
                {user == null ? (
                  <Link
                    to="#"
                    onClick={(e) => {
                      toggleDrawer(false);
                      handleRegister(e);
                    }}
                  >
                    {t("header_register.1")}
                  </Link>
                ) : (
                  <Link
                    to="#"
                    onClick={() => {
                      toggleDrawer(false);
                      handleLogout();
                    }}
                  >
                    {t("header_logout.1")}
                  </Link>
                )}
              </ListGroup.Item>
              <ListGroup.Item className="nav_list_item">
                <Link
                  to={isLoginUser == true ? "/account" : "#"}
                  onClick={(e) => {
                    toggleDrawer(false);
                    handleLogin(e);
                  }}
                >
                  {user == null ? t("header_login.1") : user.userData.email}
                </Link>
              </ListGroup.Item>
              <ListGroup.Item className="nav_list_item">
                <Link to="/" className={`${leftNavPath == '/' ? 'currNav' : ''}`} onClick={() => toggleDrawer(false)}>
                  {t("header_left_nav_home_page.1")}
                </Link>
              </ListGroup.Item>

              <Accordion className="nav_list_item" defaultValue={"10"} activeKey={leftNavPathname}>
                <Accordion.Item eventKey="1" onClick={() => decoratedOnClick(1)}>
                  <Accordion.Header  >

                    <span style={{ color: `${location.pathname.includes('/health') == true ? 'teal' : '#333'}` }} >{t("main_header_health_services.1")}</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Col role={12}>
                      <Link
                        to={`/health`}
                        state={{ reset: true }}
                        onClick={() => toggleDrawer(false)}
                        className={` ${leftNavPathname == `/health` ? 'currNav' : ''}`}
                      >
                        {t("main_header_health_services.1")}
                      </Link>
                    </Col>
                    {checkNotNull(info)
                      ? info.filter_categories &&
                      info.filter_categories.map((item, index) => {
                        return (
                          <Col role={12} key={item.id}>
                            <Link
                              to={{
                                pathname: "/health",
                                search: `?filter_category_id=${item.id}`,
                              }}
                              className={` ${leftNavPath == `/health?filter_category_id=${item.id}` ? 'currNav' : ''}`}
                              onClick={() => {
                                toggleDrawer(false);
                              }}
                            >
                              {item.category_name}
                            </Link>
                          </Col>
                        );
                      })
                      : null}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <ListGroup.Item className="nav_list_item">
                <Link to="/centre" className={`${leftNavPath == '/centre' ? 'currNav' : ''}`} onClick={() => toggleDrawer(false)}>
                  {t("main_header_medical_centre.1")}
                </Link>
              </ListGroup.Item>
              {showMenu != null && showMenu.length >= 1 ?
                showMenu.map((item, index) => {
                  return (
                    <ListGroup.Item key={item.id} className="nav_list_item">
                      <Link
                        to={{
                          pathname: "/health",
                          search: `?filter_category_id=${item.id}`
                        }}
                        className={`menu_link ${leftNavPath == `/health?filter_category_id=${item.id}` ? 'currNav' : ''}`}
                        onClick={() => toggleDrawer(false)}>
                        {item.category_name}
                      </Link>
                    </ListGroup.Item>
                  )
                })
                : null}

              <Accordion className="nav_list_item" defaultValue={leftNavPathname} activeKey={leftNavPathname}>
                <Accordion.Item eventKey="2" onClick={() => decoratedOnClick(2)}>
                  <Accordion.Header  >
                    <span style={{ color: `${location.pathname.includes('/belun') == true ? 'teal' : '#333'}` }} >{t("main_header_sleep.1")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Col role={12}>
                      <Link to="/belun" className={` ${leftNavPath == `/belun` ? 'currNav' : ''}`}
                        onClick={() => toggleDrawer(false)}>
                        {t("sleep_header_overview.1")}
                      </Link>
                    </Col>
                    <Col role={12}>
                      <Link to="/belun/how" className={` ${leftNavPath == `/belun/how` ? 'currNav' : ''}`}
                        onClick={() => toggleDrawer(false)}>
                        {t("sleep_header_how_to_use.1")}
                      </Link>
                    </Col>
                    <Col role={12}>
                      <Link
                        to="/belun/terms" className={` ${leftNavPath == `/belun/terms` ? 'currNav' : ''}`}
                        onClick={() => toggleDrawer(false)}
                      >
                        {t("sleep_header_terms.1")}
                      </Link>
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <ListGroup.Item className="nav_list_item">
                <Link to="/blog" className={` ${leftNavPath == `/blog` ? 'currNav' : ''}`}
                  onClick={() => toggleDrawer(false)}>
                  {t("main_header_news.1")}
                </Link>
              </ListGroup.Item> */}
              <ListGroup.Item className="nav_list_item">
                <Link to="/question/General" className={` ${leftNavPath == `/question/General` ? 'currNav' : ''}`}
                  onClick={() => toggleDrawer(false)}>
                  {t("header_faqs.1")}
                </Link>
              </ListGroup.Item>
              {/* <ListGroup.Item className="nav_list_item">
                <Link to="#" onClick={() => toggleDrawer(false)}>
                  COVID
                </Link>
              </ListGroup.Item> */}
            </ListGroup>
          </Box>
        </SwipeableDrawer>
      </Box >
    </>
  );
};
