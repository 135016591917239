import "../../styles/views/Pages/about.scss";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Box,
  Divider,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import about3 from "../../images/about/3-about.webp";
import about4 from "../../images/about/4-about.webp";
import about5 from "../../images/about/5-about.webp";
import about6 from "../../images/about/6-about.webp";
import about7 from "../../images/about/7-about.webp";

import { ContactUs } from "../../components/contact_us";
import ContactBtn from "../../components/ContactBtn/ContactBtn";



export const About = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    // console.log(location);
  }, []);

  return (
    <div className="about_section">
      <Container maxWidth="md">
        <Box sx={{ textAlign: "center", p: "140px 0" }}>
          <Typography variant="h3" component="div">
            {t("About_US.1")}
          </Typography>
          <Typography variant="h6" component="div" sx={{ mt: "20px" }}>
            {t("About_US_sub_title.1")}
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Grid container sx={{ p: "70px 0" }} className="tow_col_div">
          <Grid
            item
            md={6}
            xs={12}
            className="left_div"
            sx={{ pb: { xs: "20px", md: "0" } }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "rgb(0, 150, 136)" }}
            >
              {t("About_US_FOR_INDIVIDUALS.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("About_US_FOR_INDIVIDUALS_desc.1")}
            </Typography>
            <ul>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_INDIVIDUALS_li1.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_INDIVIDUALS_li2.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_INDIVIDUALS_li3.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_INDIVIDUALS_li4.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_INDIVIDUALS_li5.1")}
              </li>
            </ul>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            className="right_div"
            sx={{
              borderLeft: {
                xs: "0",
                md: "1px solid #e3e3e3",
              },
              pl: { xs: "0", md: "40px" },
              borderTop: {
                xs: "1px solid #e3e3e3",
                md: "0",
              },
              pt: { xs: "20px", md: "0" },
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "rgb(0, 150, 136)" }}
            >
              {t("About_US_FOR_CORPORATES.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("About_US_FOR_CORPORATES_desc.1")}
            </Typography>
            <ul>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li1.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li2.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li3.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li4.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li5.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li6.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li7.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li8.1")}
              </li>
              <li style={{ color: "#666" }}>
                {t("About_US_FOR_CORPORATES_li9.1")}
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            flexDirection: {
              md: "row",
              xs: "column-reverse",
            },
            p: "70px 0",
          }}
        >
          <Grid item md={7} xs={12}>
            <Typography variant="h6" component="div" className="title">
              {t("ABOUT_US_p3_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p3_p1.1")}
              <br /> <br />
              {t("ABOUT_US_p3_p2.1")}
            </Typography>
          </Grid>

          <Paper
            elevation={0}
            sx={{
              width: {
                md: "300px",
                sm: "100%",
              },
            }}
          >
            <img src={about3} width={"100%"} />
          </Paper>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "space-between",
            flexDirection: {
              // md: 'row', xs: 'column-reverse'
            },
            p: "70px 0",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              width: {
                md: "300px",
                sm: "100%",
              },
            }}
          >
            <img src={about4} width={"100%"} />
          </Paper>
          <Grid item md={7} xs={12}>
            <Typography variant="h6" component="div" className="title">
              {t("ABOUT_US_p4_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p4_p1.1")}
              <br /> <br />
              {t("ABOUT_US_p4_p2.1")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            flexDirection: {
              md: "row",
              xs: "column-reverse",
            },
            p: "70px 0",
          }}
        >
          <Grid item md={7} xs={12}>
            <Typography variant="h6" component="div">
              {t("ABOUT_US_p5_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p5_p1.1")}
            </Typography>
          </Grid>

          <Paper
            elevation={0}
            sx={{
              width: {
                md: "300px",
                sm: "100%",
              },
            }}
          >
            <img src={about5} width={"100%"} />
          </Paper>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "space-between",
            flexDirection: {
              // md: 'row', xs: 'column-reverse'
            },
            p: "70px 0",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              width: {
                md: "300px",
                sm: "100%",
              },
            }}
          >
            <img src={about6} width={"100%"} />
          </Paper>
          <Grid item md={7} xs={12}>
            <Typography variant="h6" component="div">
              {t("ABOUT_US_p6_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p6_p1.1")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            flexDirection: {
              md: "row",
              xs: "column-reverse",
            },
            p: "70px 0",
          }}
        >
          <Grid item md={7} xs={12}>
            <Typography variant="h6" component="div">
              {t("ABOUT_US_p7_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p7_p1.1")}
            </Typography>
          </Grid>

          <Paper
            elevation={0}
            sx={{
              width: {
                md: "300px",
                sm: "100%",
              },
            }}
          >
            <img src={about7} width={"100%"} />
          </Paper>
        </Grid>
        <Grid container sx={{ p: "70px 0" }} className="tow_col_div">
          <Grid
            item
            md={6}
            xs={12}
            className="left_div"
            sx={{ pb: { xs: "20px", md: "0" } }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "rgb(0, 150, 136)" }}
            >
              {t("ABOUT_US_p8_left_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p8_left_p1.1")}
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            className="right_div"
            sx={{
              borderLeft: {
                xs: "0",
                md: "1px solid #e3e3e3",
              },
              pl: { xs: "0", md: "40px" },
              borderTop: {
                xs: "1px solid #e3e3e3",
                md: "0",
              },
              pt: { xs: "20px", md: "0" },
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "rgb(0, 150, 136)" }}
            >
              {t("ABOUT_US_p8_right_title.1")}
            </Typography>
            <Typography variant="body" component="div" sx={{ color: "#666" }}>
              {t("ABOUT_US_p8_right_p1.1")}
            </Typography>
          </Grid>
        </Grid>

        <ContactUs />
        <ContactBtn />
      </Container>
    </div>
  );
};
