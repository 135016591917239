import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

// Components
import { Header } from "./components/header";
import { Footer } from "./components/footer";


import { get_home_info } from "./controller/api/api";
import { get_home_details, set_category_item } from "./redux/reducers/homeSlice";
import { useSelector, useDispatch } from "react-redux";
import { checkNotNull } from "./utils";


import { SEO_META } from "./components/seo/SEO";
import RoutesSwitch from "./RoutesSwitch";




function App() {
  const { language } = useSelector((state) => state.language);
  const { info } = useSelector((state) => state.home.Home);
  const dispatch = useDispatch();



  const getInfo = async () => {
    if (checkNotNull(language)) {
      await get_home_info(language)
        .then((res) => {
          dispatch(get_home_details(res));
          //setTitle(res.info.meta_title[0].value)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (language !== null) {
      getInfo();
    }
  }, [language]);




  return (
    <div className="App">
      <SEO_META />
      <Header />
      <RoutesSwitch />
      <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
      <Footer />

    </div>
  );
}

export default App;
