import "../../styles/views/Pages/question.scss";
import React, { useCallback, useEffect, useState } from "react";

import { useLocation, useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Breadcrumbs,
  Button,
  useMediaQuery,
  List,
} from "@mui/material";

import { ListGroup, Accordion, Form } from "react-bootstrap";

import bg from "../../images/question/partners.webp";

import {
  get_question_pages,
  get_faq_category_list,
  send_contact_form
} from "../../controller/api/api";

import { useDispatch, useSelector } from "react-redux";
import {
  get_faq_lists,
  get_faq_category_lists,
  get_question_pages_lists,
  setTitleList
} from "../../redux/reducers/questionSlice";

import { ContactUs } from "../../components/contact_us";

import { useTranslation } from "react-i18next";
import { checkNotNull } from "../../utils";

import { useForm } from "react-hook-form";

import { SuccessToast, ErrorToast } from "../../components/ToastSweetAlert2/Toast";

import ContactBtn from "../../components/ContactBtn/ContactBtn";



export const Question = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [titleName, setTitleName] = useState();
  const [selectMenuNum, setSelectMenuNum] = useState(0);
  const [menu, setMenu] = useState([
    "personal_info",
    "terms_and_conditions",
    "privacy_policy",
    "cookies",
    "contact_us",
  ]);
  const [linkTitle, setLinkTitle] = useState([
    t("Question_left_nav_p_i.1"),
    t("Question_left_nav_t_c.1"),
    t("Question_left_nav_p_p.1"),
    t("Question_left_nav_c.1"),
    // t("Question_left_nav_t_i.1"),
  ]);



  const [faqList, setFaqList] = useState();
  const [faqCateCount, setFaqCateCount] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [updateTitle, setUpdateTitle] = useState(false);


  const mediumViewport = useMediaQuery("(min-width:900px)");

  const { lists, category_lists } = useSelector((state) => state.question.faq);
  const { question_lists } = useSelector(
    (state) => state.question.question_pages
  );
  const { language } = useSelector((state) => state.language);


  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleContactForm = async (data) => {
    //// console.log(data);
    await send_contact_form(data)
      .then((res) => {
        SuccessToast(res.message);
      })
      .catch((err) => {
        ErrorToast(err.response.data.message)
      });
  }


  const handleSelect = (e, value) => {
    //console.log(value)
    let new_link = "/question" + e.target.getAttribute("link");
    let new_name = e.target.getAttribute("t_name");
    //// console.log(new_name);
    setSelectMenuNum(value);
    navigate(new_link, { state: new_name });
  };

  const checkTitleAndMenu = () => {

    if (menu !== null) {

      let index = menu.indexOf(params.page) == -1 ? 0 : menu.indexOf(params.page);
      //// console.log(index);
      setSelectMenuNum(index);
      setTabIndex(index);
      if (location.state !== null && location.state !== "") {
        setTitleName(location.state);
        setTabIndex(index);

      } else {
        setTabIndex(index);

        //// console.log('linkTitle', linkTitle)
        // // console.log('menu', menu)
        //// console.log('checkTitleAndMenu->linkTitle[index]', linkTitle[index])

        if (category_lists != null) {
          if (updateTitle == true) {
            if (index >= category_lists.length) {
              //// console.log(index - category_lists.length)
              switch (index - category_lists.length) {
                case 0:
                  setTitleName(t("Question_left_nav_p_i.1"));
                  break;
                case 1:
                  setTitleName(t("Question_left_nav_t_c.1"));
                  break;
                case 2:
                  setTitleName(t("Question_left_nav_p_p.1"));
                  break;
                case 3:
                  setTitleName(t("Question_left_nav_c.1"));
                  break;
                // case 4:
                //   setTitleName(t("Question_left_nav_t_i.1"));
                //   break;
              }

            } else {
              setTitleName(linkTitle[index]);
            }
          }
        } else {
          setTitleName(linkTitle[index]);
        }
        setUpdateTitle(false);
      }

    }
  };

  const getFaqCategoryList = async (lang) => {

    await get_faq_category_list(lang)
      .then((res) => {
        // // console.log('get_faq_category_list', res);
        //  // console.log('get_faq_category_list->menu', menu);

        let new_menu = Object.values(res.faqcategories).map(
          (item) => item.category_name
        );
        let temp_menu = [...menu];
        let temp_title = [...linkTitle];
        if (faqCateCount !== 0) {
          temp_menu = temp_menu.slice(new_menu.length);
          temp_title = temp_title.slice(new_menu.length);
        }
        temp_menu = [...new_menu, ...temp_menu];
        temp_title = [...new_menu, ...temp_title];
        //console.log('temp_menu', temp_menu)
        //// console.log('temp_title', temp_title)


        setFaqCateCount(new_menu.length);
        setFaqList(new_menu);

        //console.log('linkTitle', linkTitle)
        setMenu(temp_menu);
        setLinkTitle(temp_title);
        dispatch(get_faq_lists(temp_menu));

        dispatch(get_faq_category_lists(res.faqcategories));

      })
      .catch((err) => {
        // console.log(err);
      })
  };

  const getQuestions = async (language) => {
    if (checkNotNull(language)) {
      //// console.log("getQuestions", language);
      await get_question_pages(language)
        .then((res) => {
          //// console.log(res);
          dispatch(get_question_pages_lists(res.pages));
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };







  useEffect(() => {

    if (language !== null) {
      getQuestions(language);
      getFaqCategoryList(language);


    }

  }, [language]);

  useEffect(() => {
    if (language !== null) {
      checkTitleAndMenu();
      if (updateTitle == false) {
        setUpdateTitle(true);
      }
    }
  }, [params, language, menu, tabIndex, selectMenuNum]);



  return (
    <div className="question_section">
      <Grid
        item
        xs={12}
        className="top_img_div"
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ display: "flex", height: "300px", alignItems: "center" }}
        >
          <Typography variant="title" component="h1" sx={{ color: "white" }}>
            {t("Question_banner_text.1")}
          </Typography>
        </Container>
      </Grid>
      <Container maxWidth="lg" className="question_div">
        <Typography
          variant="title"
          component="h1"
          id="question_title2"
          className="question_title"
        >
          {titleName == null ? "General" : titleName}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "auto",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={3}>
              {faqList == null ? null : (
                <Tabs
                  orientation={mediumViewport ? "vertical" : "horizontal"}
                  variant="scrollable"
                  value={selectMenuNum}
                  onChange={handleSelect}
                  sx={{
                    borderRight: { xs: 0, md: "1px solid rgba(0, 0, 0, 0.12)" },
                    paddingLeft: { xs: "20px", md: 0 },
                    paddingBottom: { xs: "20px", md: 0 },
                  }}
                >
                  {faqList == null
                    ? null
                    : faqList.map((item, index) => {
                      return (
                        <Tab
                          key={item + item.id}
                          label={item}
                          link={`/${item}`}
                        />
                      );
                    })}
                  <Tab
                    label={t("Question_left_nav_p_i.1")}
                    t_name={t("Question_left_nav_p_i.1")}
                    link="/personal_info"
                  />
                  <Tab
                    label={t("Question_left_nav_t_c.1")}
                    t_name={t("Question_left_nav_t_c.1")}
                    link="/terms_and_conditions"
                  />
                  <Tab
                    label={t("Question_left_nav_p_p.1")}
                    t_name={t("Question_left_nav_p_p.1")}
                    link="/privacy_policy"
                  />
                  <Tab
                    label={t("Question_left_nav_c.1")}
                    t_name={t("Question_left_nav_c.1")}
                    link="/cookies"
                  />
                  {/* <Tab
                    label={t("Question_left_nav_t_i.1")}
                    t_name={t("Question_left_nav_t_i.1")}
                    link="/contact_us"
                  /> */}
                </Tabs>
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <Box
                className="question_context"
                sx={{ width: "100%", pl: { xs: "20px", md: "80px" } }}
              >
                {location.state == null ? (
                  <Breadcrumbs className="breadcrumbs">
                    <Typography color="text.primary"> {t("Question_context_brumb_home.1")}</Typography>
                  </Breadcrumbs>
                ) : (
                  <Breadcrumbs className="breadcrumbs">
                    <Link to="/">
                      <Typography underline="hover" color="inherit">
                        {t("Question_context_brumb_home.1")}
                      </Typography>
                    </Link>
                    <Typography color="text.primary">
                      {location.state}
                    </Typography>
                  </Breadcrumbs>
                )}

                {category_lists == null
                  ? null
                  : category_lists.map((item, index) => {
                    return (
                      <div
                        key={item + item.id}
                        className="show_context_div"
                        hidden={selectMenuNum !== index}
                      >
                        <ListGroup className="question_list_gp">
                          {item.faqs == null
                            ? null
                            : item.faqs.map((item, index) => {
                              return (
                                <Accordion
                                  key={item + item.id}
                                  className="nav_list_item"
                                >
                                  <Accordion.Item eventKey={item.id}>
                                    <Accordion.Header>
                                      {item.question}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div
                                        className="context_div"
                                        dangerouslySetInnerHTML={{
                                          __html: item.answer,
                                        }}
                                      ></div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              );
                            })}
                        </ListGroup>
                      </div>
                    );
                  })}

                <div
                  className="show_context_div"
                  hidden={faqList ? selectMenuNum !== faqList.length : null}
                >
                  {question_lists == null
                    ? null
                    : question_lists
                      .filter((item, index) => {
                        return item.title == t("Question_left_nav_p_i.1");
                      })
                      .map((item, index) => {
                        return (
                          <div key={item + item.id} className="desc_div">
                            <div
                              className="context_div"
                              dangerouslySetInnerHTML={{
                                __html: item.content,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                </div>
                <div
                  className="show_context_div"
                  hidden={faqList ? selectMenuNum !== faqList.length + 1 : null}
                >
                  {question_lists == null
                    ? null
                    : question_lists
                      .filter((item, index) => {
                        return item.title == t("Question_left_nav_t_c.1");
                      })
                      .map((item, index) => {
                        return (
                          <div key={item + item.id} className="desc_div">
                            <div
                              className="context_div"
                              dangerouslySetInnerHTML={{
                                __html: item.content,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                </div>
                <div
                  className="show_context_div"
                  hidden={faqList ? selectMenuNum !== faqList.length + 2 : null}
                >
                  <div className="desc_div">
                    {question_lists == null
                      ? null
                      : question_lists
                        .filter((item, index) => {
                          return item.title == t("Question_left_nav_p_p.1");
                        })
                        .map((item, index) => {
                          return (
                            <div key={item + item.id} className="desc_div">
                              <div
                                className="context_div"
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div
                  className="show_context_div"
                  hidden={faqList ? selectMenuNum !== faqList.length + 3 : null}
                >
                  <div className="desc_div">
                    {question_lists == null
                      ? null
                      : question_lists
                        .filter((item, index) => {
                          return item.title == t("Question_left_nav_c.1");
                        })
                        .map((item, index) => {
                          return (
                            <div key={item + item.id} className="desc_div">
                              <div
                                className="context_div"
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div
                  className="show_context_div"
                  hidden={faqList ? selectMenuNum !== faqList.length + 4 : null}
                >
                  <form onSubmit={handleSubmit(handleContactForm)}>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        md={8}
                        justifyContent="space-between"
                        sx={{ mb: "12px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            margin: { xs: "0" },
                            mb: { xs: "8px", md: "0" },
                            pr: { md: "8px" },
                          }}
                          className="inputs_t"
                        >
                          <Grid item xs={12} sm={12}>
                            <Form.Control type="text" placeholder={t("Question_context_form_fname.1")}
                              {...register('first_name', { required: true })}
                            />
                            {errors.first_name?.type === 'required' && <Typography
                              variant="body1"
                              sx={{ fontSize: "12px", color: "red" }}
                            >
                              {t("header_required.1")}
                            </Typography>}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ margin: { xs: "0" }, pl: { md: "8px" } }}
                          className="inputs_t"
                        >
                          <Grid item xs={12} sm={12}>
                            <Form.Control type="text" placeholder={t("Question_context_form_lname.1")}
                              {...register('last_name', { required: true })}
                            />
                            {errors.last_name?.type === 'required' && <Typography
                              variant="body1"
                              sx={{ fontSize: "12px", color: "red" }}
                            >
                              {t("header_required.1")}
                            </Typography>}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{ margin: { xs: "0 0 12px 0" } }}
                        className="inputs_t"
                      >
                        <Grid item xs={12} sm={12}>
                          <Form.Control type="text" placeholder={t("Question_context_form_email.1")}
                            {...register('email', { required: true })}
                          />
                          {errors.email?.type === 'required' && <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "red" }}
                          >
                            {t("header_required.1")}
                          </Typography>}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{ margin: { xs: "0 0 12px 0" } }}
                        className="inputs_t"
                      >
                        <Grid item xs={12} sm={12}>
                          <Form.Control type="text" placeholder={t("Question_context_form_telephone.1")}
                            {...register('telephone', { required: true })}
                          />
                          {errors.telephone?.type === 'required' && <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "red" }}
                          >
                            {t("header_required.1")}
                          </Typography>}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{ margin: { xs: "0 0 12px 0" } }}
                        className="inputs_t"
                      >
                        <Grid item xs={12} sm={12}>
                          <Form.Control
                            as="textarea"
                            rows={9}
                            placeholder={t("Question_context_form_msg.1")}
                            {...register('message', { required: true })}
                          />
                          {errors.message?.type === 'required' && <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "red" }}
                          >
                            {t("header_required.1")}
                          </Typography>}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{ margin: { xs: "0" } }}
                        className="inputs_t"
                      >
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "teal",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            width: "100%",
                            borderRadius: 0,
                            "&:hover": {
                              backgroundColor: "green",
                            },
                          }}
                          type="submit"
                        >
                          {t("Question_context_form_send.1")}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ContactUs />
      <ContactBtn />
    </div>
  );
};
