import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        Home: { info: null, searchFilterList: null, showMenu: null, cateItem: null ,cateId:null}
    },
    reducers: {
        get_home_details: (state, action) => {
            state.Home.info = action.payload;
            let temp = { ...action.payload };
            let femaleCate_temp = temp.filter_categories.filter((item, index) => item.show_in_menu == 1);
            if (femaleCate_temp.length >= 1) {
                state.Home.showMenu = femaleCate_temp;
            }
        },
        get_search_filter_lists: (state, action) => {
            state.Home.searchFilterList = action.payload.category_name
        },
        set_category_item: (state, action) => {
            state.Home.cateItem = action.payload;
        },
        set_category_id: (state, action) => {
            state.Home.cateId = action.payload;
        },

    }
})

export const { get_home_details, get_search_filter_lists, set_category_item,set_category_id } = homeSlice.actions;

export default homeSlice.reducer;
