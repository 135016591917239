import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null, isRegister: false, isLogin: false, customer: null, booking: null
    },
    reducers: {
        user_login: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        user_logout: (state, action) => {
            state.user = null;
            localStorage.removeItem('user');
        },
        toggle_isRegister: (state, action) => {
            state.isRegister = action.payload;
        },
        toggle_isLogin: (state, action) => {
            state.isLogin = action.payload;
        },
        get_customer: (state, action) => {
            state.customer = action.payload;
        },
        get_customer_booking: (state, action) => {
            state.booking = action.payload;
        },
    }
})

export const { user_login, user_logout, toggle_isRegister, toggle_isLogin, get_customer, get_customer_booking } = userSlice.actions;

export default userSlice.reducer;


