import "../../styles/views/Pages/belun.scss";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Box,
  Divider,
  Alert,
  Button,
  IconButton,
  TextField,
  OutlinedInput,
  FormControl,
  Card,
  getDividerUtilityClass,
  Icon,
} from "@mui/material";

import { Ratio } from "react-bootstrap";
import img1 from "../../images/sleep/adultsleep.webp";
import img2 from "../../images/sleep/how/belun_how.webp";

import video1 from "../../images/sleep/how/3.mp4";
import video2 from "../../images/sleep/how/8.mp4";
import video3 from "../../images/sleep/how/5.mp4";
import video4 from "../../images/sleep/how/6.mp4";

import { useTranslation } from "react-i18next";

export const How = () => {
  const { t } = useTranslation();

  return (
    <div className="belun_how_section">
      <Box className="banner_div">
        <CardMedia className="banner_img" image={img1} />
        <Box className="context_div">
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontSize: "70px", fontWeight: "bold" }}
          >
            {t("BELUN_cn_title_1.1")}
          </Typography>
          <Grid container justifyContent={"center"}>
            <Typography variant="h3" component="h3">
              {t("BELUN_HOW_p1_title_left.1")}
            </Typography>
            <Typography variant="h5">&reg;</Typography>
            <Typography variant="h3" component="h3" sx={{ pl: "8px" }}>
              {t("BELUN_HOW_p1_title_right.1")}
            </Typography>
          </Grid>
          <div className="text_list">
            <Typography variant="body1" component="h6">
              {t("BELUN_HOW_p1_sub_title_1.1")}
            </Typography>
            <Typography variant="body1" component="h6">
              {t("BELUN_HOW_p1_sub_title_2.1")}
            </Typography>
            <Typography variant="body1" component="h6">
              {t("BELUN_HOW_p1_sub_title_3.1")}
            </Typography>
          </div>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("BELUN_cn_title_2.1")}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" className="belun_how_context">
        <Grid container className="context_coin left_media_div">
          <Grid item xs={12} md={5} className="left_media">
            <Typography
              variant="h5"
              component="h5"
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {t("BELUN_HOW_p2_title.1")}
            </Typography>
            <Ratio aspectRatio="16x9">
              <video src={video1} controls="controls" playsInline autoPlay />
            </Ratio>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ pl: { xs: "0", md: "73px" } }}
            className="right_div"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {t("BELUN_HOW_p2_title.1")}
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{ mt: { xs: "16px", md: "0" } }}
            >
              {t("BELUN_HOW_p2_sub_title.1")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#666", pb: { xs: "8px", md: "16px" } }}
            >
              {t("BELUN_HOW_p2_p1_title.1")}
            </Typography>
            <ul className="list_ul">
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p2_p1_list1.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p2_p1_list2.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p2_p1_list3.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p2_p1_list4.1")}
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container className="context_coin points_div">
          <Grid item xs={12} sx={{ mb: { xs: "16px", md: "0" } }}>
            <Typography variant="h5" component="h5">
              {t("BELUN_HOW_p3_title.1")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#666", pb: { xs: "8px", md: "16px" } }}
            >
              {t("BELUN_HOW_p3_sub_title.1")}
            </Typography>
          </Grid>
          <ul className="list_ul">
            <li>
              {" "}
              <Typography variant="body1">
                {t("BELUN_HOW_p3_list_1.1")}
              </Typography>
            </li>
            <li>
              {" "}
              <Typography variant="body1">
                {t("BELUN_HOW_p3_list_2.1")}
              </Typography>
            </li>
            <li>
              {" "}
              <Typography variant="body1">
                {t("BELUN_HOW_p3_list_3.1")}
              </Typography>
            </li>
            <li>
              {" "}
              <Typography variant="body1">
                {t("BELUN_HOW_p3_list_4.1")}
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid container className="context_coin right_media_div">
          <Grid
            item
            xs={12}
            md={8}
            sx={{ pr: { xs: "0", md: "73px" } }}
            className="left_div"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{
                display: { xs: "none", md: "block" },
                m: { xs: " 0 0 16px 0 ", md: "0" },
              }}
            >
              {t("BELUN_HOW_p4_title.1")}
            </Typography>

            <ul className="list_ul">
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_1.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_2.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_3.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_4.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_5.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_6.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_7.1")}v
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p4_list_8.1")}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className="right_media"
            sx={{ mb: { xs: "16px", md: "0" } }}
          >
            <Ratio aspectRatio="16x9">
              <video src={video2} controls="controls" playsInline autoPlay />
            </Ratio>
          </Grid>
          <Typography
            variant="h5"
            component="h5"
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {t("BELUN_HOW_p4_title.1")}
          </Typography>
        </Grid>
        <Grid container className="context_coin left_media_div">
          <Typography
            variant="h5"
            component="h5"
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {t("BELUN_HOW_p5_title.1")}
          </Typography>
          <Grid
            item
            xs={12}
            md={5}
            className="left_media"
            sx={{ mb: { xs: "16px", md: "0" } }}
          >
            <Ratio aspectRatio="16x9">
              <video src={video3} controls="controls" playsInline autoPlay />
            </Ratio>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ pl: { xs: "0", md: "73px" } }}
            className="right_div"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {t("BELUN_HOW_p5_title.1")}
            </Typography>
            <ul className="list_ul">
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p5_list_1.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p5_list_2.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p5_list_3.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p5_list_4.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p5_list_5.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p5_list_6.1")}
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container className="context_coin right_media_div">
          <Grid
            item
            xs={12}
            md={8}
            sx={{ pr: { xs: "0", md: "73px" } }}
            className="left_div"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {t("BELUN_HOW_p6_title.1")}
            </Typography>
            <ul className="list_ul">
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p6_list_1.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p6_list_2.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p6_list_3.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p6_list_4.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p6_list_5.1")}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1">
                  {t("BELUN_HOW_p6_list_6.1")}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className="right_media"
            sx={{ mb: { xs: "16px", md: "0" } }}
          >
            <Ratio aspectRatio="16x9">
              <video src={video4} controls="controls" playsInline autoPlay />
            </Ratio>
          </Grid>
          <Typography
            variant="h5"
            component="h5"
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {t("BELUN_HOW_p6_title.1")}
          </Typography>
        </Grid>
        <Grid
          container
          className="context_coin right_img_div"
          sx={{ flexFlow: { xs: "column-reverse", md: "row" } }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{ pr: { xs: "0", md: "73px" } }}
            className="left_div"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {t("BELUN_HOW_p7_title.1")}
            </Typography>
            <Typography variant="body1" sx={{ color: "#666", pt: "16px" }}>
              {t("BELUN_HOW_p7_p1.1")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className="right_media">
            <Ratio aspectRatio="16x9">
              <CardMedia image={img2} height="100%" width="100%" />
            </Ratio>
          </Grid>
          <Typography
            variant="h5"
            component="h5"
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {t("BELUN_HOW_p7_title.1")}
          </Typography>
        </Grid>
      </Container>
    </div>
  );
};
