import '../../styles/views/Pages/account.scss'
import React, { useEffect, useState, useRef, useCallback } from 'react'

import { useLocation, useParams, useNavigate } from 'react-router'
import { Link } from 'react-router-dom';

import {
    Grid,
    Container,
    Typography,
    Box,
    Button,
    InputBase,
    ClickAwayListener,
    Tabs,
    Tab,
    Divider,
    Modal

} from '@mui/material';


import { styled, alpha } from "@mui/material/styles";
import LogoutIcon from '@mui/icons-material/Logout';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import "swiper/css";
import "swiper/css/navigation";

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { get_cust_info, get_cust_booking, update_user_info } from '../../controller/api/api';
import { get_customer, get_customer_booking } from '../../redux/reducers/userSlice';

import { SuccessToast, ErrorToast } from '../../components/ToastSweetAlert2/Toast';
import { checkNotNull } from '../../utils';

import { AccountCard } from '../../components/card/AccountCard';

import CardMap from '../../images/account/card-map.webp';

import Datepicker from '../../components/datepicker/Datepicker';

//Search bar component
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#efefef", 1),
    width: "100%",
    display: "flex",
    [theme.breakpoints.up("md")]: {
        width: "28%",
    },
    [theme.breakpoints.up("xs")]: {
        display: "none",
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `1rem`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
    },
}));




export const Account = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const NAV_BOOKING = 'NAV_BOOKING';
    const NAV_INFORMATION = 'NAV_INFORMATION';

    const { user, customer, booking } = useSelector(state => state.user);
    const { language } = useSelector(state => state.language);

    const [openNavStr, setOpenNavStr] = useState();
    const [title, setTitle] = useState('');
    const [openDate, setOpenDate] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const [cardId, setCardId] = useState();
    const [tabSelect, setTabSelect] = useState(0);
    const [firstTab, setFirstTab] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [bookModal, setBookModal] = useState(false);


    const { register, handleSubmit, unregister, watch, setValue, getValues, control, formState: { errors } } = useForm({
        shouldUnregister: true
    });

    const handleChangeInfo = async ({ customer }) => {
        // console.log(customer);
        if (user != null) {
            await update_user_info(user.access_token, customer)
                .then((res) => {
                    // console.log("update_user_info->res", res);
                    SuccessToast(res.message)
                })
                .catch((err) => {
                    //console.log("update_user_info->err", err);
                    ErrorToast(err.response.data.message)
                });
        }
    }

    const setValues = () => {
        if (customer && customer.customer != null) {
            // console.log('register->setValues', customer.customer);
            setValue("customer", customer.customer);

        }
    }

    const toggleDateOpen = () => {
        setOpenDate(!openDate);
    };

    const handleDateChange = (dateVal, onChange) => {
        let temp = new Date(dateVal);

        let new_date = temp.toLocaleDateString('en-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });


        onChange(new_date);
        toggleDateOpen();
    };
    const getInfo = async () => {
        if (user != null) {
            await get_cust_info(user.access_token, language != null && language)
                .then((res) => {
                    // console.log("get_cust_info->res12", res);
                    dispatch(get_customer(res));
                    if (cardId == null) {
                        checkCardNum(res.customer.id)
                    }
                    if (firstTab) {
                        clickUserNav(NAV_BOOKING);
                        setFirstTab(false);
                    }
                })
                .catch((err) => {
                    // console.log("get_cust_info->err", err);
                });

        }
    }
    const handleClickBooking = async () => {
        if (user != null) {
            await get_cust_booking(user.access_token, language != null && language)
                .then((res) => {
                    // console.log("get_cust_booking->res12", res);
                    //SuccessToast(res.message);
                    dispatch(get_customer_booking(res.bookings));

                })
                .catch((err) => {
                    // console.log("get_cust_booking->err", err);
                });
        }
    }

    const clickUserNav = (navStr) => {
        // console.log('clickUserNav->', navStr);
        switch (navStr) {
            case NAV_BOOKING: {
                handleClickBooking();
                setTitle('Booking Record');
                break;
            }
            case NAV_INFORMATION: {

                getInfo();

                // memoizedCallback();
                setValues()
                setTitle('User Information');
                break;
            }
            default: {
                setTitle('');
            }


            // case NAV_CHANGE: {
            //     setTitle('Change Information');
            //     break;
            // }
        }
        setOpenNavStr(navStr);
    }


    const checkCardNum = (num) => {


        let needNum = 16;
        let starNum = 0;
        let str = '';
        starNum = needNum - num.toString().length;
        //console.log(starNum);
        for (let i = 0; i < needNum; i++) {
            str += "*";
        }
        // console.log(typeof str);
        str = str.slice(0, starNum) + num;
        str = str.split("");
        let newStr = '';
        for (let i = 0; i < needNum; i++) {
            if (i % 4 == 0) {
                newStr += ' ';
            }
            newStr += str[i];
        }
        //console.log(str);
        //console.log(newStr);


        setCardId(newStr);
    }


    const clickTabVal = (e, num) => {
        // console.log('clickTabVal', num)
        setTabValue(num);
        let navStr = null;
        switch (num) {
            case 0: {
                navStr = NAV_INFORMATION;
                break;
            }
            case 1: {
                navStr = NAV_BOOKING;
                break;
            }
        }
        clickUserNav(navStr);
    }


    const handleTagsChange = (e, val) => {
        // console.log(e, val);
        setTabSelect(val)
        switch (val) {
            case 0:
                clickUserNav();
                break;
            case 1:
                clickUserNav(NAV_INFORMATION);
                break;
            case 2:
                clickUserNav(NAV_BOOKING);
                break;


        }
        // <Link to="#" onClick={() => clickUserNav(NAV_INFORMATION)}>Information</Link>
        // <Link to="#" onClick={() => clickUserNav(NAV_BOOKING)}>Booking</Link>
    }


    const openBookingModal = (e, booking) => {
        e.preventDefault();
        //console.log(booking);
        setBookModal(booking);

        setOpenModal(true);
    }
    const closeBookingModal = () => setOpenModal(false);

    useEffect(() => {
        if (language !== null) {
            getInfo();
        }
    }, [language])



    return (
        <>
            {bookModal ? <Modal open={openModal} onClose={closeBookingModal}>
                <Box sx={{
                    position: 'absolute', top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    width: { md: '50%', xs: '80%' },
                    padding: '24px 16px 16px 16px'
                }}>
                    <Box sx={{ bgcolor: 'teal', width: '100%', mb: '8px' }}><Typography variant="h5" sx={{ pl: '8px', color: "white" }}>Booking</Typography></Box>
                    {bookModal.clinic && bookModal.clinic.contents && <Typography variant="h6"> {t("Account_booking_modal.location")}: {bookModal.clinic.contents.filter(item => item.language_id == language)[0].address}</Typography>}
                    {bookModal.clinic && bookModal.clinic.contents && <Typography variant="h6"> {t("Account_booking_modal.clinic")}: {bookModal.clinic.contents.filter(item => item.language_id == language)[0].location_clinic_name}</Typography>}
                    <Typography variant="h6">
                        {t("Account_booking_modal.date")}: {bookModal.date}
                    </Typography>
                    <Typography variant="h6">
                        {t("Account_booking_modal.time")}: {bookModal.time}
                    </Typography>
                    <Typography variant="h6">
                        {t("Account_booking_modal.phone")}: {bookModal.tel}
                    </Typography>
                </Box>
            </Modal> : null}
            <Box className="account_section" sx={{ padding: { md: '140px 0', xs: '70px 0' } }}>
                {customer == null ? null :
                    <Container maxWidth="lg" >
                        <Grid container className="account_context" sx={{ minHeight: { md: '670px', xs: 'auto' } }}>
                            <Grid item xs={12} md={5} className="left_context"
                                sx={{
                                    display: { md: 'block', xs: 'block' },
                                    backgroundColor: { xs: 'unset', md: 'white' },
                                    padding: { md: '0', xs: '16px 0' }
                                }}
                            >
                                <Grid container sx={{ height: { md: '100%', xs: 'auto' } }}>
                                    <Grid item xs={3} className="links" sx={{ display: { md: 'flex', xs: 'none' } }}>
                                        <Link to="#" className="logout_btn">
                                            <LogoutIcon fontSize="large" />
                                        </Link>

                                        <Box className="links_box">
                                            <Link to="/">{t("Account_links_home.1")}</Link>
                                            <Link to="/blog">{t("Account_links_news.1")}</Link>
                                            <Link to="#" onClick={() => clickUserNav(NAV_INFORMATION)}>{t("Account_links_information.1")}</Link>
                                            <Link to="#" onClick={() => clickUserNav(NAV_BOOKING)}>{t("Account_links_Booking.1")}</Link>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={9} className="info" sx={{ padding: { md: '25% 16px 25% 16px', xs: '0' }, borderLeft: { md: '1px solid #e3e3e3', xs: '0' } }}>
                                        <Box sx={{ visibility: { md: 'hidden', xs: 'visible' } }}>
                                            <Tabs value={tabSelect} onChange={handleTagsChange} variant="scrollable" scrollButtons={true} sx={{ mb: 1, backgroundColor: 'white' }}>
                                                <Tab value={0} label="User" />
                                                <Tab value={1} label="Information" />
                                                <Tab value={2} label="Booking" />
                                            </Tabs>
                                        </Box>
                                        <Box className="user_info_box"
                                            sx={{
                                                height: { md: '50%', sm: '240px', xs: '200px' }, width: { md: '100%', sm: '70%', xs: '100%' }, margin: { xs: '0 auto', md: 'unset' },
                                                padding: '16px', borderRadius: '8px', zIndex: '95',
                                                backgroundImage: `url(${CardMap}), linear-gradient(to right bottom, teal, #52c2c2, #52c2c2, #52c2c2, #32d296)`
                                            }}
                                        >
                                            <Typography variant="h5" className="z_title" >Z</Typography>
                                            {
                                                customer && customer.customer != null ?
                                                    <>
                                                        {cardId != null ? <Typography variant="h5" className="card_number" >{cardId}</Typography> : null}
                                                        <Grid container>
                                                            <Grid item xs>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body" >{t("Account_card_user.1")}</Typography>
                                                                </Grid>
                                                                <Typography variant="body" className="user_name" sx={{ md: '0 30px' }}>{customer.customer.first_name} {customer.customer.last_name}</Typography>
                                                            </Grid>
                                                            <Grid item xs textAlign="right">
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body" >{t("Account_card_d_o_b.1")}</Typography>
                                                                </Grid>
                                                                <Typography variant="body" className="user_date" >{customer.customer.date_of_birth}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                    : null
                                            }
                                        </Box>
                                        <Grid container className="balance"
                                            sx={{
                                                width: { xs: '100%' }, zIndex: '95',
                                                boxShadow: { xs: '0', md: '0 5px 15px rgb(0 0 0 / 8%)' },
                                                display: { xs: 'none', md: 'flex' }
                                            }}>
                                            <Grid item xs={5} className="text">
                                                <Typography variant="body2" className="title">{t("Account_card_o_s.1")}</Typography>
                                                <Typography variant="body2" className="price">$ {customer.online_spent && customer.online_spent.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid item xs={5} className="text">
                                                <Typography variant="body2" className="title">{t("Account_card_d.1")}</Typography>
                                                <Typography variant="body2" className="price">$ {customer.discounted && customer.discounted.toFixed(1)}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container sx={{ display: { xs: 'block', md: 'none' }, backgroundColor: '#fff' }}>
                                <Grid xs={12} item >
                                    <Box className="user_info_box" >
                                        <AccountCard />
                                    </Box>
                                    <Box sx={{ height: '100%' }}>
                                        <Grid container spacing={1} sx={{ textAlign: 'center', padding: '8px 0' }}>
                                            <Grid item xs >
                                                <Typography variant="body2" className="title">{t("Account_card_o_s.1")}</Typography>
                                                <Typography variant="body2" className="price" sx={{ color: 'teal' }}>$ {customer.online_spent && customer.online_spent.toFixed(1)}</Typography>
                                            </Grid>
                                            <Divider orientation="vertical" variant="middle" flexItem sx={{ height: 'auto' }} />
                                            <Grid item xs >
                                                <Typography variant="body2" className="title">{t("Account_card_d.1")}</Typography>
                                                <Typography variant="body2" className="price" sx={{ color: 'teal' }}>$ {customer.discounted && customer.discounted.toFixed(1)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item >
                                    {tabValue !== 0 &&
                                        <Tabs value={tabValue} onChange={clickTabVal}>
                                            <Tab label="Information" value={0} />
                                            <Tab label="Booking Record" value={1} />
                                        </Tabs>}
                                </Grid>

                            </Grid>

                            <Grid item xs={12} md={7} className="right_context" sx={{ display: { xs: 'block' }, maxHeight: '670px', overflow: 'auto' }}>
                                <Link to="#" className="email">
                                    <Typography variant="body1" >{customer.customer.email}</Typography>
                                </Link>
                                <Typography variant="h5" className="title">
                                    {title}
                                </Typography>
                                {openNavStr == NAV_BOOKING ?
                                    <Grid container direction="column" className="nav_booking_lists" sx={{ padding: { md: '16px', xs: '0' } }} >
                                        {checkNotNull(booking) ? booking.map((item, index) => {
                                            return (
                                                <Link to="#" onClick={(e) => openBookingModal(e, item)} key={item + index} className="nav_booking_lists_item">
                                                    <Grid container >
                                                        <Grid item xs={9}>
                                                            <Typography >{t("Account_booking_order_id.1")}: {item.id}</Typography>
                                                            {item.payment_id != "null" ? <Typography >{t("Account_booking_code.1")}: {item.payment_id}</Typography> : null}
                                                            <Typography >{t("Account_booking_status.1")}: {item.current_status == 1 ? t("Account_booking_status_paid.1") : item.current_status == 0 ? t("Account_booking_status_unpaid.1") : t("Account_booking_status_fail.1")}</Typography>
                                                            <Typography >{t("Account_booking_product.1")}: {item.product && item.product.contents.filter(item => item.language_id == language)[0].name}</Typography>
                                                            {item.clinic && <Typography >{t("Account_booking_address.1")}: {item.clinic && item.clinic.contents.filter(item => item.language_id == language)[0].address}</Typography>}
                                                            {item.clinic && <Typography >{t("Account_booking_modal.clinic")}: {item.clinic && item.clinic.contents.filter(item => item.language_id == language)[0].location_clinic_name}</Typography>}

                                                        </Grid>
                                                        <Grid item xs textAlign="left">
                                                            <Typography >{t("Account_booking_payment.1")}: {item.payment_type_id == 1 ? t("Account_booking_method_paypal.1") : item.payment_type_id == 2 ? t("Account_booking_method_offline_fps.1") : t("Account_booking_method_offline_bank.1")}</Typography>
                                                            <Typography >{t("Account_booking_date.1")}: {item.created_at.split(' ')[0]}</Typography>
                                                            <Typography >{t("Account_booking_price.1")}: {item.final_price}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Link>
                                            )
                                        }) : null}
                                    </Grid>
                                    : openNavStr == NAV_INFORMATION && customer != null ?
                                        <Grid container>
                                            <form onSubmit={handleSubmit(handleChangeInfo)} className="userInfoForm">
                                                <Grid container className="bottom_bar">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs>
                                                            <Typography variant="body1" className="field_name">
                                                                {t("account_update_info_firstname_placeholder.1")}:
                                                            </Typography>
                                                            <Search
                                                                className="search_div"
                                                                sx={{
                                                                    width: { xs: "100%" },
                                                                    mb: 1,
                                                                    display: { xs: "block" },
                                                                }}
                                                            >
                                                                <StyledInputBase
                                                                    className="update_input"
                                                                    placeholder={t("account_update_info_firstname_placeholder.1")}
                                                                    sx={{ width: "100%", fontSize: "13px" }}
                                                                    {...register("customer.first_name")}
                                                                />
                                                            </Search>

                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="body1" className="field_name">
                                                                {t("account_update_info_lastname_placeholder.1")}:
                                                            </Typography>
                                                            <Search
                                                                className="search_div"
                                                                sx={{
                                                                    width: { xs: "100%" },
                                                                    mb: 1,
                                                                    display: { xs: "block" },
                                                                }}
                                                            >
                                                                <StyledInputBase
                                                                    className="update_input"
                                                                    placeholder={t(
                                                                        "account_update_info_lastname_placeholder.1"
                                                                    )}
                                                                    sx={{ width: "100%", fontSize: "13px" }}
                                                                    {...register("customer.last_name")}
                                                                />
                                                            </Search>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className="date_box" sx={{ mb: 1 }}>
                                                        <Typography variant="body1" className="field_name">
                                                            {t("account_update_info_date_placeholder.1")}:
                                                        </Typography>
                                                        <ClickAwayListener
                                                            onClickAway={() => setOpenDate(false)}
                                                        >
                                                            <Box>
                                                                {/* <LocalizationProvider
                                                                    dateAdapter={AdapterDateFns}
                                                                > */}
                                                                <Controller
                                                                    name="customer.date_of_birth"
                                                                    control={control}

                                                                    defaultValue={''}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <Box>
                                                                            <Search
                                                                                className="search_div"
                                                                                sx={{
                                                                                    width: { xs: "100%" },
                                                                                    height: "100%",
                                                                                    display: { xs: "block" },
                                                                                }}
                                                                            >
                                                                                <StyledInputBase
                                                                                    className="update_input"
                                                                                    placeholder={t("account_update_info_date_placeholder.1")}
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        fontSize: "13px",
                                                                                    }}
                                                                                    onClick={toggleDateOpen}
                                                                                    value={value}
                                                                                    readOnly
                                                                                />
                                                                            </Search>
                                                                            <Box
                                                                                sx={{
                                                                                    display: openDate ? "block" : "none",
                                                                                    position: 'relative'
                                                                                }}

                                                                            >
                                                                                {/* <CalendarPicker

                                                                                        onChange={(val) =>
                                                                                            handleDateChange(val, onChange)
                                                                                        }
                                                                                    /> */}
                                                                                <Datepicker
                                                                                    minDate={new Date()}
                                                                                    changeDate={(val) => handleDateChange(val, onChange)}
                                                                                    dateWidth={'100%'} />
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                />
                                                                {/* </LocalizationProvider> */}

                                                            </Box>
                                                        </ClickAwayListener>
                                                    </Grid>
                                                    <Grid container spacing={2}>

                                                        <Grid item xs>
                                                            <Typography variant="body1" className="field_name">
                                                                {t("account_update_info_telephone_placeholder.1")}:
                                                            </Typography>
                                                            <Search
                                                                className="search_div"
                                                                sx={{
                                                                    width: { xs: "100%" },
                                                                    mb: 1,
                                                                    display: { xs: "block" },
                                                                }}
                                                            >
                                                                <StyledInputBase
                                                                    className="update_input"
                                                                    placeholder={t("account_update_info_telephone_placeholder.1")}
                                                                    sx={{ width: "100%", fontSize: "13px" }}
                                                                    {...register("customer.telephone")}
                                                                />
                                                            </Search>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="body1" className="field_name">
                                                                {t("account_update_info_mobile_placeholder.1")}:
                                                            </Typography>
                                                            <Search
                                                                className="search_div"
                                                                sx={{
                                                                    width: { xs: "100%" },
                                                                    mb: 1,
                                                                    display: { xs: "block" },
                                                                }}
                                                            >
                                                                <StyledInputBase
                                                                    className="update_input"
                                                                    placeholder={t("account_update_info_mobile_placeholder.1")}
                                                                    sx={{ width: "100%", fontSize: "13px" }}
                                                                    {...register("customer.mobile")}
                                                                />
                                                            </Search>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" className="field_name">
                                                            {t("account_update_info_old_pwd_placeholder.1")}:
                                                        </Typography>
                                                        <Search
                                                            className="search_div_pwd"
                                                            sx={{
                                                                width: { xs: "100%" },
                                                                mb: 1,
                                                                display: { xs: "block" },
                                                            }}
                                                        >
                                                            <StyledInputBase
                                                                className="update_old_pwd_input"
                                                                placeholder={t("account_update_info_old_pwd_placeholder.1")}
                                                                type="password"
                                                                sx={{ width: "100%", fontSize: "13px" }}
                                                                {...register("customer.old_password", { required: true })}
                                                            />
                                                        </Search>

                                                        {errors.customer && errors.customer.old_password &&
                                                            errors.customer.old_password.type === "required" && (
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                                                                >
                                                                    {t("header_required.1")}
                                                                </Typography>
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" className="field_name">
                                                            {t("account_update_info_pwd_placeholder.1")}:
                                                        </Typography>
                                                        <Search
                                                            className="search_div_pwd"
                                                            sx={{
                                                                width: { xs: "100%" },
                                                                mb: 1,
                                                                display: { xs: "block" },
                                                            }}
                                                        >
                                                            <StyledInputBase
                                                                className="update_pwd_input"
                                                                placeholder={t("account_update_info_pwd_placeholder.1")}
                                                                type="password"
                                                                sx={{ width: "100%", fontSize: "13px" }}
                                                                {...register("customer.password", { required: false })}
                                                            />
                                                        </Search>

                                                        {errors.password &&
                                                            errors.password.type === "required" && (
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                                                                >
                                                                    {t("header_required.1")}
                                                                </Typography>
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" className="field_name">
                                                            {t("account_update_info_c_password_placeholder.1")}:
                                                        </Typography>
                                                        <Search
                                                            className="search_div_pwd"
                                                            sx={{
                                                                width: { xs: "100%" },
                                                                mb: 1,
                                                                display: { xs: "block" },
                                                            }}
                                                        >
                                                            <StyledInputBase
                                                                className="update_c_pwd_input"
                                                                placeholder={t("account_update_info_c_password_placeholder.1")}
                                                                type="password"
                                                                sx={{ width: "100%", fontSize: "13px" }}
                                                                {...register("customer.c_password")}
                                                            />
                                                        </Search>
                                                        {errors.c_password &&
                                                            errors.c_password.type === "required" && (
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{ fontSize: "12px", mb: 1, color: "red" }}
                                                                >
                                                                    {t("header_required.1")}
                                                                </Typography>
                                                            )}
                                                    </Grid>
                                                    <Button
                                                        className="sign_up_btn"
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: "teal",
                                                            paddingLeft: "30px",
                                                            paddingRight: "30px",
                                                            mt: 1,
                                                            "&:hover": {
                                                                backgroundColor: "green",
                                                            },
                                                        }}
                                                        type="submit"
                                                    >
                                                        {t("account_update_info_change_btn.1")}
                                                    </Button>
                                                </Grid>

                                            </form>
                                        </Grid>
                                        : null}
                            </Grid>
                        </Grid>
                    </Container>
                }
            </Box >
        </>
    )
}
