import "../../styles/views/Pages/belun.scss";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Box,
  Divider,
  Alert,
  Button,
  IconButton,
  TextField,
  OutlinedInput,
  FormControl,
  Card,
  getDividerUtilityClass,
  Icon,
} from "@mui/material";

import { Ratio } from "react-bootstrap";

import img1 from "../../images/sleep/adultsleep.webp";
import img2 from "../../images/sleep/sleep.webp";
import img3 from "../../images/sleep/BLR-100_product.webp";

import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import CloudQueueRoundedIcon from "@mui/icons-material/CloudQueueRounded";
import WifiTetheringRoundedIcon from "@mui/icons-material/WifiTetheringRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import GamesRoundedIcon from "@mui/icons-material/GamesRounded";
import AirlineSeatIndividualSuiteRoundedIcon from "@mui/icons-material/AirlineSeatIndividualSuiteRounded";
import WeekendRoundedIcon from "@mui/icons-material/WeekendRounded";
import Filter6RoundedIcon from "@mui/icons-material/Filter6Rounded";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const Belun = () => {
  const { t } = useTranslation();

  const { info } = useSelector((state) => state.home.Home);
  const { language } = useSelector(state => state.language);

  const [videoLink, setVideoLink] = useState(null);

  const checkVideo = () => {
    setVideoLink(null);
    if (language == 1) {
      setVideoLink('https://www.youtube.com/embed/upWGglqxjh0?autoplay=1&controls=0&mute=1')
    } else {
      setVideoLink('https://www.youtube.com/embed/i_K3ZpRH5_g?autoplay=1&controls=0&mute=1')
    }
  }


  useEffect(() => {
    if (language != null) {
      checkVideo();
    }


  }, [language])

  return (
    <div className="belun_section">
      <Box className="banner_div">
        <CardMedia className="banner_img" image={img1} />
        <Box className="context_div">
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontSize: "70px", fontWeight: "bold" }}
          >
            {t("BELUN_cn_title_1.1")}
          </Typography>
          <Grid container justifyContent={"center"}>
            <Typography variant="h3" component="h3">
              {t("BELUN_p1_title_left.1")}
            </Typography>
            <Typography variant="h5">&reg;</Typography>
            <Typography variant="h3" component="h3" sx={{ pl: "8px" }}>
              {t("BELUN_p1_title_right.1")}
            </Typography>
          </Grid>
          <div className="text_list">
            <Typography variant="body1" component="h6">
              {t("BELUN_p1_sub_title_1.1")}
            </Typography>
            <Typography variant="body1" component="h6">
              {t("BELUN_p1_sub_title_2.1")}
            </Typography>
            <Typography variant="body1" component="h6">
              {t("BELUN_p1_sub_title_3.1")}
            </Typography>
          </div>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("BELUN_cn_title_2.1")}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" className="belun_context">
        <Grid container className="context_coin left_media_div">
          {videoLink == null ? null
            : <Grid item xs={12} md={5} className="left_media">
              <Ratio aspectRatio="16x9">
                <iframe
                  width="560"
                  height="315"
                  src={videoLink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Ratio>
            </Grid>}
          <Grid
            item
            xs={12}
            md={7}
            sx={{ pl: { xs: "0", md: "73px" } }}
            className="right_div"
          >
            <Typography variant="h5" component="h5">
              {t("BELUN_p2_title.1")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#666", pt: "16px", pb: "16px" }}
            >
              {t("BELUN_p2_p1.1")}
            </Typography>

            {info != null && info.info.email != null ? <Grid item xs={3}>
              <a className="emailBtn" href={`mailto: ${info.info.email[0].value}`}>
                {t("BELUN_p2_p2_btn_left.1")}
              </a>
            </Grid> : null}

            {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "teal",
                paddingLeft: "30px",
                paddingRight: "30px",
                borderRadius: 0,
                marginLeft: "8px",
                "&:hover": {
                  backgroundColor: "green",
                },
              }}
            >
              {t("BELUN_p2_p2_btn_right.1")}
            </Button> */}
          </Grid>
        </Grid>
        <Grid
          container
          className="context_coin right_media_div"
          sx={{ flexFlow: { xs: "column-reverse", md: "row" } }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{ pr: { xs: "0", md: "73px" } }}
            className="left_div"
          >
            <Typography variant="h5" component="h5">
              {t("BELUN_p3_title.1")}
            </Typography>
            <Typography variant="body1" sx={{ color: "#666", pt: "16px" }}>
              {t("BELUN_p3_p1.1")}
            </Typography>
            <Box
              sx={{
                backgroundColor: "teal",
                borderRadius: "10px",
                padding: "30px",
                margin: "20px 0",
              }}
            >
              <div className="sleep_list">
                <Typography variant="h6" component="h6">
                  {t("BELUN_p3_p2_1.1")}
                </Typography>
                <Typography variant="h6" component="h6">
                  {t("BELUN_p3_p2_2.1")}
                </Typography>
                <Typography variant="h6" component="h6">
                  {t("BELUN_p3_p2_3.1")}
                </Typography>
                <Typography variant="h6" component="h6">
                  {t("BELUN_p3_p2_4.1")}
                </Typography>
                <Typography variant="h6" component="h6">
                  {t("BELUN_p3_p2_5.1")}
                </Typography>
                <Typography variant="h6" component="h6">
                  {t("BELUN_p3_p2_6.1")}
                </Typography>
              </div>
            </Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                color: "#666",
                pt: "16px",
                textAlign: "right",
              }}
            >
              {t("BELUN_p3_p3.1")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className="right_media">
            <Ratio aspectRatio="16x9">
              <CardMedia image={img2} height="100%" width="100%" />
            </Ratio>
          </Grid>
        </Grid>
        <Grid container className="context_coin symptoms_div">
          <Typography variant="h5" component="h5" sx={{ pb: "16px" }}>
            {t("BELUN_p4_title.1")}
          </Typography>
          <Grid container className="symptoms_list">
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_1.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_2.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_3.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_4.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_5.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_6.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_7.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_8.1")}
              </Typography>
            </Box>
            <Box className="list_item" sx={{ width: { md: "18%", xs: "48%" } }}>
              <Typography variant="body1" component="h6">
                {t("BELUN_p4_p1_9.1")}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container className="context_coin belun_ring_div">
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {t("BELUN_p5_title.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <GppGoodRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_1_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_1_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <CloudQueueRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_2_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_2_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <WifiTetheringRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_3_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_3_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <ThumbUpAltRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_4_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_4_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <GamesRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_5_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_5_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <AirlineSeatIndividualSuiteRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_6_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_6_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <WeekendRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_7_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_7_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <Filter6RoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_8_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_8_text.1")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} className="icons_div">
            <TouchAppRoundedIcon />
            <Typography gutterBottom variant="h5" component="div">
              {t("BELUN_p5_p1_9_title.1")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p5_p1_9_text.1")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="context_coin ul_list_div">
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {t("BELUN_p6_title.1")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul className="list_ul">
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p6_list_1.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p6_list_2.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p6_list_3.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p6_list_4.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p6_list_5.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p6_list_6.1")}
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container className="context_coin ul_list_div">
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {t("BELUN_p7_title.1")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t("BELUN_p7_sub_title.1")}
            </Typography>
            <ul className="list_ul">
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p7_list_1.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p7_list_2.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p7_list_3.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p7_list_4.1")}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.secondary">
                  {t("BELUN_p7_list_5.1")}
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          container
          className="context_coinleft_media_div"
          sx={{ alignItems: "center" }}
        >
          <Grid item xs={12} md={5} className="left_media">
            <Ratio aspectRatio="16x9">
              <CardMedia image={img3} height="100%" width="100%" />
            </Ratio>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ pl: { xs: "0", md: "73px" } }}
            className="right_div"
          >
            <Typography variant="h5" component="h5">
              {t("BELUN_p8_title.1")}
            </Typography>
            <Typography variant="body1" sx={{ color: "#666", pt: "16px" }}>
              {t("BELUN_p8_p1.1")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
