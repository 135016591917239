import { configureStore } from "@reduxjs/toolkit";

import newsSlice from "./reducers/newsSlice";
import langSlice from "./reducers/langSlice";
import questionSlice from "./reducers/questionSlice";
import userSlice from "./reducers/userSlice";
import centreSlice from "./reducers/centreSlice";

import healthSlice from "./reducers/healthSlice";
import bookingSlice from "./reducers/bookingSlice";
import homeSlice from "./reducers/homeSlice";
import filterSlice from "./reducers/filterSlice";

import orderSlice from "./reducers/orderSlice";

import compareSlice from "./reducers/compareSlice";

export default configureStore({
    reducer: {
        news: newsSlice,
        language: langSlice,
        question: questionSlice,
        user: userSlice,
        centre: centreSlice,
        health: healthSlice,
        booking: bookingSlice,
        home: homeSlice,
        filter: filterSlice,
        order: orderSlice,
        compare: compareSlice
    }
})