import "../../styles/views/Home/home.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import {
  Box,
  Typography,
  CardContent,
  CardMedia,
  Paper,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Autoplay, Navigation, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import bg from "../../images/home/background.webp";
import bg2 from "../../images/home/bg2.webp";


import plan1 from "../../images/home/plan1.webp";
import plan2 from "../../images/home/plan2.webp";
import plan3 from "../../images/home/plan3.webp";

import { ContactUs } from "../../components/contact_us";
import { checkNotNull } from "../../utils";

import { useSelector, useDispatch } from "react-redux";

import { Ratio } from "react-bootstrap";


import ContactBtn from "../../components/ContactBtn/ContactBtn";


export const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { info } = useSelector((state) => state.home.Home);

  const clickToPage = (page) => {
    navigate(page.pathname, { state: page.state, replace: page.replace });
  };

  return (
    <div className="home_section">

      {checkNotNull(info) ? (
        <>
          <Swiper
            className="home_banner_list"
            modules={[Scrollbar, Autoplay]}
            scrollbar={{
              hide: true,
            }}
            autoplay={{ delay: 5000 }}
          >
            {info.banners && info.banners.length >= 1
              ? info.banners.map((item, index) => {
                return (
                  <SwiperSlide key={item + index}>
                    <img
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        aspectRatio: "16/4",
                        minHeight:"300px",
                      }}
                      src={
                        checkNotNull(item.banner_image_path)
                          ? process.env.REACT_APP_API_IMAGE +
                          item.banner_image_path
                          : null
                      }
                    />
                  </SwiperSlide>
                );
              })
              : null}
          </Swiper>
          
          {/* <Box
            sx={{
              backgroundColor: "rgb(70, 69, 69)",
              p: "30px",
              textAlign: "center",
            }}
          >
            <Typography variant="h5" component="div">
              <Link to="#" style={{ color: "white", textDecoration: "none" }}>
                {t("Home_p2_title.1")}
              </Link>
            </Typography>
          </Box>  */}

          <Container maxWidth="lg">
            <Swiper
              className="home_customers_list"
              slidesPerView={6}
              slidesPerGroupSkip={1}
              freeMode={true}
              spaceBetween={30}
              modules={[Autoplay, Navigation, FreeMode]}
              navigation={true}
              // autoplay={{ delay: 3000 }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  slidesPerGroup: 1
                },
                600: {
                  slidesPerView: 3,
                  slidesPerGroup: 1
                },
                900: {
                  slidesPerView: 6,
                  slidesPerGroup: 1
                },

              }}
            >
              {info.clinics && info.clinics.length >= 1
                ? info.clinics.map((item, index) => {
                  return (
                    <SwiperSlide key={item + index}>
                      <CardMedia
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          checkNotNull(item.clinic_logo_path)
                            ? process.env.REACT_APP_API_IMAGE +
                            item.clinic_logo_path
                            : null
                        }
                        component="img"
                      />
                    </SwiperSlide>
                  );
                })
                : null}
            </Swiper>
          </Container>

          <Container maxWidth="lg" sx={{ padding: { md: "70px 0", xs: '16px 0' } }}>
            <Box
              sx={{ backgroundColor: "white", p: "30px", textAlign: "center" }}
            >
              <Typography variant="h5" component="div">
                <Link to="#" style={{ color: "#333", textDecoration: "none" }}>
                  {t("Home_p4_title.1")}
                </Link>
              </Typography>
            </Box>
            <Swiper
              className="home_health_list"
              modules={[]}
              slidesPerView={1}
              spaceBetween={8}
              breakpoints={{
                600: {
                  slidesPerView: 2,
                },
                900: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            >
              {info.products && info.products.length >= 1
                ? info.products.map((item, index) => {
                  return (
                    <SwiperSlide key={item + index} style={{ width: "200px" }}>
                      <Link
                        to={`/health/${item.id}`}
                        className="list_item"
                        style={{ height: "100%" }}
                      >
                        <Box className="items">
                          {item.clinic && <Ratio aspectRatio="16x9">
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                checkNotNull(item.clinic.clinic_logo_path)
                                  ? process.env.REACT_APP_API_IMAGE +
                                  item.clinic.clinic_logo_path
                                  : null
                              }
                              alt="green iguana"
                              sx={{
                                width: "100%",
                                padding: "4px 16px",
                                objectFit: "contain",
                              }}
                            />
                          </Ratio>}

                          <CardContent className="context">
                            <h6 className="name">{item.name}</h6>
                            <h5 className="price">
                              $
                              {item.discount_price !== null
                                ? item.discount_price
                                : item.original_price}
                            </h5>
                          </CardContent>
                        </Box>
                      </Link>
                    </SwiperSlide>
                  );
                })
                : null}
            </Swiper>
          </Container>

          <Box
            sx={{
              backgroundColor: "rgba(0, 150, 136, 0.09)",
              p: "40px",
              textAlign: "center",
            }}
          >
            <Typography variant="h5" component="div">
              <Link to="/health?filter_category_id=1" style={{ color: "#333", textDecoration: "none" }}>
                {t("Home_p5_title.1")}
              </Link>
            </Typography>
          </Box>

          <Container maxWidth="lg" sx={{ padding: "70px 0" }}>
            <Box
              sx={{ backgroundColor: "white", p: "30px", textAlign: "center" }}
            >
              <Typography variant="h5" component="div">
                <Link to="#" style={{ color: "#333", textDecoration: "none" }}>
                  {t("Home_p6_title.1")}
                </Link>
              </Typography>
            </Box>

            <Swiper
              className="home_plan_list"
              modules={[]}
              slidesPerView={2}
              breakpoints={{
                0: {
                  slidesPerView: 1
                },
                600: {
                  slidesPerView: 3
                },
              }}
            >
              <SwiperSlide>
                <Paper elevation={0} className="paper_div">
                  <CardContent >
                    <CardMedia
                      component="img"
                      image={plan1}
                      alt="green iguana"
                      sx={{
                        height: {
                          xs: "112px",
                          sm: "unset",
                        },
                      }}
                    />
                    <Typography
                      gutterBottom
                      variant="h5"

                      sx={{
                        mt: "8px",
                        position: {
                          xs: "absolute",
                          sm: "initial",
                        },
                        left: {
                          xs: "50%",
                          sm: "unset",
                        },
                        top: {
                          xs: "50%",
                          sm: "unset",
                        },
                        transform: {
                          xs: "translate(-50%, -50%)",
                          sm: "unset",
                        },
                        fontSize: {
                          xs: "24px",
                          sm: "16px",
                        },
                        textShadow: {
                          xs: '3px 3px 5px black',
                          md: 'unset'
                        }
                        ,
                        color: {
                          xs: "white",
                          sm: "#333",
                        },
                      }}
                    >
                      {t("Home_p6_p1_1_title.1")}
                    </Typography>
                    <Typography
                      component={"span"}
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Home_p6_p1_1_text.1")}
                    </Typography>
                  </CardContent>
                </Paper>
              </SwiperSlide>
              <SwiperSlide>
                <Paper elevation={0} className="paper_div">
                  <CardContent>
                    <CardMedia
                      component="img"
                      image={plan2}
                      alt="green iguana"
                      sx={{
                        height: {
                          xs: "112px",
                          sm: "unset",
                        },
                      }}
                    />
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        mt: "8px",
                        position: {
                          xs: "absolute",
                          sm: "initial",
                        },
                        left: {
                          xs: "50%",
                          sm: "unset",
                        },
                        top: {
                          xs: "50%",
                          sm: "unset",
                        },
                        transform: {
                          xs: "translate(-50%, -50%)",
                          sm: "unset",
                        },
                        textShadow: {
                          xs: '3px 3px 5px black',
                          md: 'unset'
                        },
                        fontSize: {
                          xs: "24px",
                          sm: "16px",
                        },
                        color: {
                          xs: "white",
                          sm: "#333",
                        },
                      }}
                    >
                      {t("Home_p6_p1_2_title.1")}
                    </Typography>
                    <Typography
                      component={"span"}
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Home_p6_p1_2_text.1")}
                    </Typography>
                  </CardContent>
                </Paper>
              </SwiperSlide>
              <SwiperSlide>
                <Paper elevation={0} className="paper_div">
                  <CardContent>
                    <CardMedia
                      component="img"
                      image={plan3}
                      alt="green iguana"
                      sx={{
                        height: {
                          xs: "112px",
                          sm: "unset",
                        },
                      }}
                    />
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        mt: "8px",
                        position: {
                          xs: "absolute",
                          sm: "initial",
                        },
                        left: {
                          xs: "50%",
                          sm: "unset",
                        },
                        top: {
                          xs: "50%",
                          sm: "unset",
                        },
                        transform: {
                          xs: "translate(-50%, -50%)",
                          sm: "unset",
                        },
                        fontSize: {
                          xs: "24px",
                          sm: "16px",
                        },
                        textShadow: {
                          xs: '3px 3px 5px black',
                          md: 'unset'
                        }
                        ,
                        color: {
                          xs: "white",
                          sm: "#333",
                        },
                      }}
                    >
                      {t("Home_p6_p1_3_title.1")}
                    </Typography>
                    <Typography
                      component={"span"}
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Home_p6_p1_3_text.1")}
                    </Typography>
                  </CardContent>
                </Paper>
              </SwiperSlide>
            </Swiper>
          </Container>
          <div style={{ backgroundColor: "#00968817" }}>
            <Container maxWidth="lg" sx={{ padding: "70px 0" }}>
              <Box sx={{ p: "30px", textAlign: "center" }}>
                <Typography variant="h5" component="div">
                  <Link
                    to="#"
                    style={{ color: "#333", textDecoration: "none" }}
                  >
                    {t("Home_p7_title.1")}
                  </Link>
                </Typography>
              </Box>

              <Swiper
                className="home_other_list"
                modules={[]}
                slidesPerView={1}
                breakpoints={{
                  600: {
                    slidesPerView: 2,
                  },
                  900: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide style={{ height: "300px", width: "200px" }}>
                  <div>
                    <CardContent>
                      <h6 style={{ color: "#333" }}>
                        {t("Home_p7_p1_1_title.1")}
                      </h6>
                      <Typography
                        component={"span"}
                        variant="body2"
                        color="text.secondary"
                      >
                        {t("Home_p7_p1_1_text.1")}
                      </Typography>
                    </CardContent>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ height: "300px", width: "200px" }}>
                  <div>
                    <CardContent>
                      <h6 style={{ color: "#333" }}>
                        {t("Home_p7_p1_2_title.1")}
                      </h6>
                      <Typography
                        component={"span"}
                        variant="body2"
                        color="text.secondary"
                      >
                        {t("Home_p7_p1_2_text.1")}
                      </Typography>
                    </CardContent>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ height: "300px", width: "200px" }}>
                  <div>
                    <CardContent>
                      <h6 style={{ color: "#333" }}>
                        {t("Home_p7_p1_3_title.1")}
                      </h6>
                      <Typography
                        component={"span"}
                        variant="body2"
                        color="text.secondary"
                      >
                        {t("Home_p7_p1_3_text.1")}
                      </Typography>
                    </CardContent>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Container>
          </div>

          <Container maxWidth="lg" className="news_div">
            <Box
              sx={{
                backgroundColor: "white",
                xs: 12,
                p: "30px",
                textAlign: "center",
              }}
            >
              <Typography variant="h5" component="div">
                <Link to="#" style={{ color: "#333", textDecoration: "none" }}>
                  {t("Home_p8_title.1")}
                </Link>
              </Typography>
            </Box>
            <ImageList
              className="home_news_list"
              sx={{ width: { md: "80%", xs: "100%" }, margin: "0 auto" }}
              variant="quilted"
              cols={4}
              gap={0}
            >
              {info.news && info.news.length >= 1
                ? info.news.map((item, index) => {
                  return (
                    <Link key={item + index} to={`/blog/post/${item.id}`} style={{ display: 'contents' }}>
                      <ImageListItem
                        key={index + 1}
                        rows={index == 0 ? 2 : 1}
                        cols={2}
                        className="news_list_item"
                      >

                        <img
                          src={
                            checkNotNull(item.image_path)
                              ? process.env.REACT_APP_API_IMAGE +
                              item.image_path
                              : null
                          }
                          alt={item.meta_keyword}
                          loading="lazy"
                        />

                        <div className="list_item_text">
                          <Typography
                            className="text"
                            variant="h6"
                            sx={{
                              fontSize: {
                                md: '16px',
                                xs: index != 0 ? '12px' : '16px'
                              },
                              textShadow: {
                                xs: '3px 3px 5px black',
                                md: 'unset'
                              }
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            className="text"
                            variant="h6"
                            sx={{
                              fontSize: {
                                md: '16px',
                                xs: index != 0 ? '12px' : '16px'
                              },
                              textShadow: {
                                xs: '3px 3px 5px black',
                                md: 'unset'
                              }
                            }}

                          >
                            {item.date}
                          </Typography>
                        </div>

                      </ImageListItem>
                    </Link>
                  );
                })
                : null}
            </ImageList>

            {/* <div className="btn_div">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "teal",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  "&:hover": {
                    backgroundColor: "green",
                  },
                }}
                onClick={() => clickToPage({ pathname: "/blog" })}
              >
                {t("Home_p8_p1_btn.1")}
              </Button>
            </div> */}
          </Container>

          <Box sx={{}}>
            <img
              src={bg2}
              style={{ aspectRatio:"16 / 4", minHeight:"300px", objectFit: "cover", width: "100%" }}
            />
          </Box>

          <Container maxWidth="lg">
            <Box
              sx={{ backgroundColor: "white", height: "300px", p: "70px 0" }}
            >
              <Typography variant="h4" component="div">
                {t("Home_p10_title.1")}
              </Typography>
              <Typography
                component={"span"}
                variant="body2"
                color="text.secondary"
                sx={{
                  width: {
                    md: "100%",
                    lg: "50%",
                  },
                  marginTop: "20px",
                }}
              >
                {t("Home_p10_text.1")}
              </Typography>
            </Box>
          </Container>

          <ContactUs />
          <ContactBtn />
        </>
      ) : null
      }
    </div >
  );
};
