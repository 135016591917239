import "../../styles/views/Pages/successPayment.scss";

import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router";

import {
    Box,
    Grid,
    Typography,
    Container,
    Divider,
    CardMedia,
    Table,
    TableBody
} from "@mui/material";


import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";


export const SuccessPayment = () => {

    const location = useLocation();
    const { info } = useSelector((state) => state.home.Home);
    const { language } = useSelector(state => state.language);
    const { t } = useTranslation();


    const [bookingDetail, setBookingDetail] = useState(null);




    useEffect(() => {
        //console.log(location)
        if (bookingDetail == null && location.state && language) {

            let new_list = location.state;
            let new_plan = location.state.product.testing_items;

            let result_plans = new_plan.reduce((group, product) => {
                const { category_name } = product;
                group[category_name] = group[category_name] ?? [];
                group[category_name].push(product);
                return group;
            }, {});
            let newArr = [];
            Object.keys(result_plans).forEach((item, index) => {
                newArr.push({
                    name: Object.keys(result_plans)[index],
                    lists: Object.values(result_plans)[index]
                })
            })
            // console.log('newArr ', newArr)
            new_list.product.testing_items = newArr;
            // console.log('new_list ', new_list)
            setBookingDetail(new_list);
        }
    }, [])


    const clinic = language && bookingDetail && bookingDetail.clinic && bookingDetail.clinic.contents.filter(item => item.language_id == language).map(item => item)[0];
    const product = language && bookingDetail && bookingDetail.product && bookingDetail.product.contents.filter(item => item.language_id == language).map(item => { return item })[0];


    return (

        <Container maxWidth="lg" className="successPayment_section">
            {bookingDetail == null ? null :
                <>

                    <Typography variant="h4" >
                        {t("Payment_Order_b_d.1")}
                    </Typography>

                    <Divider sx={{ width: '100%' }} />
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h4" >
                            {t("Payment_Order_u_i.1")}
                        </Typography>
                        <Grid container className="group_info" >
                            <Grid item xs={12} md={6}>

                                {info && <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_payment_method.1")}:</span> {info.payment_types.filter((item, index) => item.id == bookingDetail.payment_type_id)[0].payment_name}
                                </Typography>
                                }
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_pay_date.1")}:</span> {bookingDetail.created_at}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>

                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_booking_id.1")} :</span> #{bookingDetail.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_first_name.1")}:</span> {bookingDetail.first_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_last_name.1")}:</span> {bookingDetail.last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_email.1")}:</span> {bookingDetail.email}
                                </Typography>
                            </Grid>

                        </Grid>


                        <Typography variant="h4" >
                            {t("Payment_Order_b_i.1")}
                        </Typography>

                        <Divider sx={{ width: '100%' }} />
                        <Grid container className="group_info" >
                            <Grid item xs={12} md={6} >
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_booking_date.1")}:</span> {bookingDetail.date}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_time.1")}:</span> {bookingDetail.time}
                                </Typography>
                            </Grid>
                            {bookingDetail.remark == null && bookingDetail.remark == '' &&
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" >
                                        <span className="fieldName">{t("Payment_Order_remarks.1")}:</span> {bookingDetail.remark}
                                    </Typography>
                                </Grid>
                            }
                            {clinic && clinic.address && <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_address.1")}:</span> {clinic.address}
                                </Typography>
                            </Grid>}
                            {clinic && clinic.location_clinic_name && <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_clinic.1")}:</span> {clinic.location_clinic_name}
                                </Typography>
                            </Grid>}
                            {clinic && clinic.google_map && <Grid item sx={12} md={6}>
                                {
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: clinic.google_map,
                                        }}
                                    ></div>
                                }
                            </Grid>}

                        </Grid>

                        <Typography variant="h4" >
                            {t("Payment_Order_o_d.1")}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />

                        <Grid container className="group_info" >
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_original_price.1")}:</span> $ {bookingDetail.original_price}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_discount_percentage.1")}:</span> {bookingDetail.discount_percentage}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_order_price.1")}:</span> $ {bookingDetail.final_price}
                                </Typography>
                            </Grid>
                            {bookingDetail.product && bookingDetail.product.code ? <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_health_code.1")}:</span> {bookingDetail.product && bookingDetail.product.code}
                                </Typography>
                            </Grid> : null}
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_order_name.1")}:</span> {product.name}
                                </Typography>
                            </Grid>
                            {product.description ? <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName">{t("Payment_Order_desc.1")}:</span> {product.description}
                                </Typography>
                            </Grid> : null}
                            {product.notes ? <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName ">{t("Payment_Order_notes.1")}:</span> <div dangerouslySetInnerHTML={{ __html: product.notes }}></div>
                                </Typography>
                            </Grid> : null}
                            {bookingDetail.product && bookingDetail.product.gender ? <Grid item xs={12} md={6}>
                                <Typography variant="h6" >
                                    <span className="fieldName ">{t("Payment_Order_gender.1")}:</span> {bookingDetail.product.gender == 'F' ? t("Payment_Order_gender_f.1") : bookingDetail.product.gender == 'm' ? t("Payment_Order_gender_f.1") : t("Payment_Order_gender_u.1")}
                                </Typography>
                            </Grid> : null}

                            {bookingDetail && bookingDetail.product.testing_items ? <Table className="plan_table">
                                <TableBody>
                                    {bookingDetail.product.testing_items && bookingDetail.product.testing_items.map((item, index) => {
                                        return (
                                            <tr key={item + index} className="table_rows">
                                                <th>{item.name}</th>
                                                <td>
                                                    {item.lists && item.lists.length >= 1
                                                        ? item.lists.map((item, index) => {
                                                            return (
                                                                <Box key={item + index}>{item.item_name}</Box>
                                                            );
                                                        })
                                                        : null}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </TableBody>
                            </Table> : null}
                        </Grid>
                        <Typography variant="h4" >
                            {t("Payment_Order_i.1")}
                        </Typography>

                        <Divider sx={{ width: '100%' }} />
                        <Grid container className="group_info" >
                            {bookingDetail && !bookingDetail.file ? null :
                                <Grid item xs={12} >
                                    <CardMedia
                                        image={bookingDetail && bookingDetail.file ? process.env.REACT_APP_API_IMAGE + bookingDetail.file : null}
                                        component="img"
                                        sx={{ width: { md: 'auto', xs: '100%' }, margin: '0 auto' }}
                                    />
                                </Grid>}
                        </Grid>
                    </Box>
                </>
            }
        </Container >
    );
}
