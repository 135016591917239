import React from 'react'
import { Routes, Route, Navigate } from 'react-router'

// Views
import { Home } from "./views/home/Home";
import { About } from "./views/about/About";

// Blog
import { Blog } from "./views/blog/Blog";
import { BlogPost } from "./views/blog/BlogPost";

// Payment
import { Cart } from "./views/payment/Cart";

import { SuccessPayment } from "./views/payment/SuccessPayment";


// Question
import { Question } from "./views/question/Question";

// Sleep
import { Belun } from "./views/sleep/Belun";
import { How } from "./views/sleep/How";
import { Terms } from "./views/sleep/Terms";

// Centre
import { Centre } from "./views/centre/Centre";
import { CentreStore } from "./views/centre/CentreStore";


// Health
import { Health } from "./views/health/Health";
import { HealthService } from "./views/health/HealthService";

//Female Health
import { FemaleHealth } from "./views/female_health/FemaleHealth";
import { FemaleHealthService } from "./views/female_health/FemaleHealthService";

// Account
import { AuthedRoute } from "./routing/AuthedRoute";

import { Account } from './views/Account/Account';

function RoutesSwitch() {
    return (
        <Routes >
            <Route index path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/post/:id" element={<BlogPost />} />
            <Route path="/blog/post/*" element={<BlogPost />} />

            <Route path="/about" element={<About />} />
            <Route path="/cart" element={<Cart />} />

            <Route path="/success_payment" element={<SuccessPayment />} />



            <Route path="question" element={<Question />}>
                <Route path=":page" element={<Question />} />
            </Route>
            <Route path="belun" element={<Belun />} />
            <Route path="belun/how" element={<How />} />
            <Route path="belun/terms" element={<Terms />} />

            <Route path="/Centre" element={<Centre />} />
            <Route path="/Centre/:name" element={<CentreStore />} />


            <Route path="/health" element={<Health />} />
            <Route path="/health/:uid" element={<HealthService />} />

            {/* <Route path="/femalehealth" element={<FemaleHealth />} />
        <Route path="/demalehealth/:uid" element={<FemaleHealthService />} /> */}

            <Route path="/account" element={<AuthedRoute Component={Account} />} />
            
           
            {/* <Route path="/404" element={<div style={{ textAlign: 'center', fontSize: '32px' }}>404 Page not find.</div>} /> */}
            <Route path="*" element={<Navigate replace to="/" />} />

        </Routes>
    )
}

export default RoutesSwitch
