import { createSlice } from "@reduxjs/toolkit";

export const centreSlice = createSlice({
    name: 'centre',
    initialState: {
        centre_list: { centres: null, store: null, filter: null }
    },
    reducers: {
        get_centres_list: (state, action) => {
            state.centre_list.centres = action.payload
        },
        get_centre_store_info: (state, action) => {
            state.centre_list.store = action.payload
        },
        update_centre_list: (state, action) => {
            state.centre_list.centres = action.payload
        },
        get_filters: (state, action) => {
            // state.centre_list.filter = action.payload;
            let new_filter = action.payload;
            //// console.log(new_filter)
            new_filter.map((item, index) => {
                let new_location = item.location.filter((item, index) => {
                    return item.product_count >= 1;
                })
                //// console.log(new_location)
                if (new_location.length >= 1) {
                    new_filter[index].location = new_location;
                } else {
                    new_filter[index].location = [];
                }
                new_filter[index].count = new_location.length;
            });
            //// console.log(new_filter)
            state.centre_list.filter = new_filter;
        },

    }
})

export const { get_centres_list, get_centre_store_info, update_centre_list, get_filters } = centreSlice.actions;

export default centreSlice.reducer;
