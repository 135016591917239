import React from "react";

import { Container, Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function ContactUs() {
  const { t } = useTranslation();
  const { info } = useSelector((state) => state.home.Home)


  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{ backgroundColor: "white", p: "30px 0 140px 0", textAlign: "center" }}
        >

          <Box sx={{ p: '0px 0 30px 0' }}>
            <Typography variant="h4" sx={{ color: 'teal' }}>{t("Contact_US_working_hours.1")}</Typography>
            <Typography variant="h5" >{t("Contact_US_working_hours_p1.1")}</Typography>
            <Typography variant="h6" >{t("Contact_US_working_hours_p2.1")}</Typography>
      
          </Box>
          <Typography variant="h4" sx={{ color: 'teal' }}>
            {t("Contact_US_title.1")}
          </Typography>
          {info && <Typography variant="h5" >
            (852) {info == null ? null : info.info.tel[0].value}
          </Typography>}

        </Box>
      </Container>
    </>
  );
}
