import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        filter: { filters: null, regions: null, maxPrice: null, minPrice: null }
    },
    reducers: {
        getFilterData: (state, action) => {
            state.filter = action.payload;
            let new_regs = action.payload.regions;
            new_regs.map(item => {
                let new_location = item.location.filter((item2, index) => {
                    return item2.product_count >= 1;
                })
                if (new_location.length >= 1) {
                    item.location = new_location;
                } else {
                    item.location = [];
                }
                item.count = new_location.length;
            });

        },
    }
})

export const { getFilterList, getregionList, getFilterData } = filterSlice.actions;

export default filterSlice.reducer;
