
export const checkNotNull = (value) => {
    return value !== null;
}
export const checkIsNull = (value) => {
    return value == null;
}
export const checkPage = (sum_count, count_per_pages) => {
    if (sum_count !== 0) {
        return sum_count % count_per_pages == 0 ? sum_count / count_per_pages : Math.floor(sum_count / count_per_pages) + 1;
    } else {
        return 1;
    }
};