import "../../styles/components/footer.scss";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

import { IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";


import { useSelector } from "react-redux";

export const Footer = () => {
  const { t } = useTranslation();
  const { info } = useSelector((state) => state.home.Home)

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer_div">
      <Container maxWidth="lg">
        <Box sx={{ pt: 2 }}>
          <Grid container  >
            <Grid item xs={3}>
              <Grid className="item" item xs={10} md={12}>
                <span className="title" >
                  {t("Footer_company_services.1")}
                </span>
                <Grid className="context" item xs={12}>
                  <Link
                    to="/blog"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_news.1")}
                  </Link>
                </Grid>
                {/* <Grid className="context" item xs={12}>
                  <Link
                    to="/question/contact_us"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_contact_us.1")}
                  </Link>
                </Grid> */}
                <Grid className="context" item xs={12}>
                  <Link
                    to="/about"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_about_us.1")}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid className="item" item xs={10} md={12}>
                <span className="title" >
                  {t("Footer_licences.1")}
                </span>
                <Grid className="context" item xs={12}>
                  <Link
                    to="/question/privacy_policy"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_privacy_policy.1")}
                  </Link>
                </Grid>
                <Grid className="context" item xs={12}>
                  <Link
                    to="/question/terms_and_conditions"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_t&c.1")}
                  </Link>
                </Grid>
                <Grid className="context" item xs={12}>
                  <Link
                    to="/question/personal_info"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_personal_info.1")}
                  </Link>
                </Grid>
                <Grid className="context" item xs={12}>
                  <Link
                    to="/question/cookies"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_cookies.1")}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid className="item" item xs={10} md={12}>
                <span className="title" >
                  {t("Footer_partners.1")}
                </span>
                <Grid className="context" item xs={12}>
                  <Link
                    to="/centre"
                    onClick={scrollToTop}
                    className="footer_link"
                  >
                    {t("Footer_medical_centre.1")}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid className="item" item xs={10} md={12}>
                <span className="title" >
                  {t("Footer_follow_us.1")}
                </span>
                <Grid className="context" item xs={12}>
                  {info &&
                    <a href={info == null ? null : info.info.facebook[0].value}>
                      <IconButton >
                        <FacebookIcon fontSize="large" />
                      </IconButton>
                    </a>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} sx={{ pt: 10, pb: 5, textAlign: "center" }}>
          Copyright © Zanolife Ltd 2022
        </Grid>
      </Container>
    </div>
  );
};
