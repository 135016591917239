import { createSlice } from "@reduxjs/toolkit";

export const compareSlice = createSlice({
    name: 'compare',
    initialState: {
        compare: [],
        total: 0
    },
    reducers: {
        addCompareProduct: (state, action) => {
            //// console.log(action)
            if (state.compare.length < 2) {
                let checkSame = state.compare.filter(item => item.id == action.payload.id);
                if (checkSame.length == 0) {
                    let new_list = action.payload;
                    let new_plan = action.payload.testing_items;
                    let result_plans = new_plan.reduce((group, product) => {
                        const { category_name } = product;
                        group[category_name] = group[category_name] ?? [];
                        group[category_name].push(product);
                        return group;
                    }, {});
                    let newArr = [];
                    Object.keys(result_plans).forEach((item, index) => {
                        newArr.push({
                            name: Object.keys(result_plans)[index],
                            lists: Object.values(result_plans)[index]
                        })
                    })
                    new_list.testing_items = newArr;


                    state.compare = [...state.compare, new_list];
                    state.total++;
                }
            } else {

            }
        },
        delCompareProduct: (state, action) => {
            let listTemp = [...state.compare];
            let findSame = listTemp.filter(item => item.id == action.payload.id);
            if (findSame.length >= 1) {
                listTemp = listTemp.filter(item => item.id != findSame[0].id);
                state.compare = listTemp;
            }
        },
        updateCompareProduct: (state, action) => {
            state.compare = action.payload
        },
        clearCompareItems: (state, action) => {
            state.compare = [];
            state.total = 0;
        },

    }
})

export const { addCompareProduct, delCompareProduct, updateCompareProduct, clearCompareItems } = compareSlice.actions;

export default compareSlice.reducer;
