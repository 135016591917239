import { createSlice } from "@reduxjs/toolkit";

export const healthSlice = createSlice({
    name: 'health',
    initialState: {
        healths: { lists: null, product: null, related_products: null, total: null, priceLists: null },
    },
    reducers: {
        get_health_lists: (state, action) => {
            state.healths.lists = action.payload
        },
        get_health_lists_by_price: (state, action) => {
            state.healths.priceLists = action.payload
        },
        get_related_products: (state, action) => {
            state.healths.related_products = action.payload
        },
        get_product_lists_total: (state, action) => {
            state.healths.total = action.payload
        },
        get_health_product_info: (state, action) => {
            //// console.log("get_health_product_info", action.payload.testing_items)
            // state.healths.product = action.payload;
            let new_list = action.payload;
            let new_plan = action.payload.testing_items;
            let result_plans = new_plan.reduce((group, product) => {
                const { category_name } = product;
                group[category_name] = group[category_name] ?? [];
                group[category_name].push(product);
                return group;
            }, {});
            let newArr = [];
            Object.keys(result_plans).forEach((item, index) => {
                newArr.push({
                    name: Object.keys(result_plans)[index],
                    lists: Object.values(result_plans)[index]
                })
            })
            new_list.testing_items = newArr;
            state.healths.product = new_list;

        },
        clear_product: (state, action) => {
            state.healths.product = null
        },

    }
})

export const { get_health_lists, get_health_product_info, get_related_products, get_product_lists_total, clear_product, get_health_lists_by_price } = healthSlice.actions;

export default healthSlice.reducer;
