import "../../styles/views/Pages/centre.scss";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  CardMedia,

} from "@mui/material";

import { Accordion } from "react-bootstrap";



import {
  get_centre_list,
  get_centre_filters,
  get_centre_list_by_locationId,
} from "../../controller/api/api";

import { useDispatch, useSelector } from "react-redux";
import {
  get_centres_list,
  update_centre_list,
  get_filters
} from "../../redux/reducers/centreSlice";


import { ContactUs } from "../../components/contact_us";
import ContactBtn from "../../components/ContactBtn/ContactBtn";

import { useTranslation } from "react-i18next";

export const Centre = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();


  const { centres, filter } = useSelector((state) => state.centre.centre_list);
  const { language } = useSelector((state) => state.language);

  const [selectId, setSelectId] = useState();




  const getCentreList = async () => {

    // console.log("getCentreList()");
    await get_centre_list(language)
      .then((res) => {
        dispatch(get_centres_list(res.clinic_list));
      })
      .catch((err) => {
        // console.log(err);
      });

  };
  const getFilterList = async () => {
    // console.log("getRegionList()");
    await get_centre_filters(language)
      .then((res) => {
        // console.log(res);
        dispatch(get_filters(res.regions));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getCentreListFilter = async (id) => {
    // console.log("getCentreListFilter()", id);
    await get_centre_list_by_locationId(id)
      .then((res) => {
        //// console.log(res);
        dispatch(update_centre_list(res.clinic_list));
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleLocationClick = (e, id) => {
    //// console.log(id);
    setSelectId(id);
    if (selectId == id) {
      setSelectId(null);
      getCentreList();
    } else {
      getCentreListFilter(id);
    }
  };

  useEffect(() => {
    if (language != null) {
      getCentreList();
      getFilterList();
    }



  }, [language]);

  return (
    <div className="centre_section">
      <Box className="banner_div">
        <Box className="context_div">
          <Typography variant="h4" component="h4">
            {t("centre_meet_out.1")}
          </Typography>
          <Typography variant="h3" component="h3">
            {t("centre_title.1")} {t("centre_title2.1")}
          </Typography>
          <Typography variant="h6" className="text">
            {t("healths_search_context.1")}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" className="centre_context">
        <Grid container className="show_medical_div">
          <Grid item xs={12} md={4} className="left_filter_div">
            {filter == null ? null : (
              <Accordion
                defaultActiveKey={filter.map((item, index) => "" + index)}
                alwaysOpen
              >
                {filter == null
                  ? null
                  : filter.map((item, index) => {
                    if (item.count >= 1) {
                      return (
                        <Accordion.Item
                          key={item.id}
                          eventKey={"" + index}
                        >
                          <Accordion.Header>{item.region}</Accordion.Header>
                          <Accordion.Body>
                            <Grid container className="checkbox_lists">
                              {item.location.map((item, index) => {
                                return (
                                  <Grid key={item + index} item xs={6}>
                                    <FormControlLabel
                                      className="label_ck"
                                      onChange={(e) =>
                                        handleLocationClick(e, item.id)
                                      }
                                      checked={item.id == selectId}
                                      control={
                                        <Checkbox className="checkbox_btn" />
                                      }
                                      label={
                                        <Typography
                                          variant="body2"
                                          className="checkout_text"
                                        >
                                          {item.location}
                                        </Typography>
                                      }
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    }

                  })}
              </Accordion>
            )}
          </Grid>
          <Grid container item xs={12} md={8} className="right_show_div">
            {centres == null
              ? null
              : centres.map((item, index) => {
                return (
                  <Grid
                    key={item.id}
                    item
                    xs={6}
                    md={4}
                    className="list_item"
                  >
                    <Link to={item.seo_link !== null && item.seo_link !== '' ? `/centre/${item.seo_link}` : `/centre/${item.id}`} >
                      <CardMedia
                        component="img"
                        image={
                          item.clinic_logo_path !== null
                            ? process.env.REACT_APP_API_IMAGE +
                            item.clinic_logo_path
                            : null
                        }
                        className="item_img"
                      />
                      <Typography variant="body2" className="title">
                        {item.clinic_name}
                      </Typography>
                      <Box variant="body2" className="context">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.about_text,
                          }}
                        ></div>
                      </Box>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>

        <ContactUs />
        <ContactBtn />
      </Container>
    </div>
  );
};
