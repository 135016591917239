import React from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material"

import cardMap from '../../images/account/card-map.webp';

export const AccountCard = () => {
    <Box sx={{ height: '200px', backgroundColor: 'red' }}>
        asd
    </Box>
}