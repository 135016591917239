
import React from 'react'

import { Navigate } from 'react-router'
import { useSelector } from 'react-redux';


export const AuthedRoute = ({Component}) => {
  
    const { user } = useSelector((state) => state.user);
    let user_info = JSON.parse(localStorage.getItem('user'));

    return user || user_info ? <Component /> : <Navigate replace to="/" />

}
