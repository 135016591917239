import { Box } from '@mui/material';
import React, { Component } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const Datepicker = (props) => {
    // console.log('Datepicker->props', props)
    return (
        <Box sx={{ position: 'absolute', zIndex: '999', width: props.dateWidth }}>

            <Calendar value={props.minDate} locale={'en'} onChange={props.changeDate} />
        </Box>
    )
}

export default Datepicker

