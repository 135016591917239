import "../../styles/views/Pages/health.scss";


import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  CardMedia,
  Slider,
  Pagination,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { Ratio, Accordion } from "react-bootstrap";

import img1 from "../../images/centre/HK-CDT.webp";


import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { ContactUs } from "../../components/contact_us";

import { checkNotNull, checkIsNull } from "../../utils";

import { get_healths, get_health_product_filter } from "../../controller/api/api";

import { useSelector, useDispatch } from "react-redux";
import { get_health_lists, get_product_lists_total } from "../../redux/reducers/healthSlice";

import { checkPage } from "../../utils";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#efefef", 1),
  width: "100%",
  display: "flex",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `1rem`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const FemaleHealth = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();


  const [page, setPage] = useState(1);


  const { language } = useSelector((state) => state.language);
  const { lists, total } = useSelector((state) => state.health.healths);

  const { info } = useSelector((state) => state.home.Home);

  const [budgetValue, setBudgetValue] = useState([0, 30000]);
  const handleBudgetChange = (event, newValue, activeThumb) => {
    setBudgetValue(newValue);
  };



  const handleClickPage = (e, v) => {
    // // console.log(v)
    setPage(v)
  }

  const toProductPage = (id) => {
    // console.log(id)
    if (checkIsNull(id)) {

    } else { navigate(`/health/${id}`); }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page])

  useEffect(() => {
    // console.log(location)
  }, [language, page, location])








  return (
    <div className="health_section">
      <Box className="banner_div">
        <Box className="context_div">
          <Typography variant="h4" component="h4">
            MEET OUR
          </Typography>
          <Typography variant="h3" component="h3">
            HEALTH PROVIDERS
          </Typography>
          <Typography variant="h6" className="text">
            Search, Compare & Select health checks online
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" className="centre_context">
        <Grid container className="show_medical_div">
          <Grid item xs={12} md={4} className="left_filter_div">
            {/* <Search className="search_div">
            <StyledInputBase
              className="input_bar"
              placeholder="Search"
              inputProps={{
                "aria-label": "search",
              }}
              sx={{ width: "100%" }}
            />

            <Link to="#" className="search_btn">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Link>
          </Search> */}
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Budget</Accordion.Header>
                <Accordion.Body>
                  <Grid container className="slides_list">
                    <Grid item xs={6}>
                      <Typography variant="body2" className="checkout_text">
                        HK$ {budgetValue[0]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Typography variant="body2" className="checkout_text">
                        HK$ {budgetValue[1]} +
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="slides_box">
                      <Slider
                        value={budgetValue}
                        max={30000}
                        step={100}
                        onChange={handleBudgetChange}
                        disableSwap
                      />
                    </Grid>
                  </Grid>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Grid>
          <Grid item xs={12} md={8} className="right_show_div">
            <Grid container className="top_menu">
              <Grid item xs className="left_box">
                {/* <Link to="#" className="sort_btn">
                <FontAwesomeIcon icon={faSliders} />
                <Typography variant="body1">SORT BY</Typography>
              </Link> */}
              </Grid>
              {/* <Grid item xs className="right_box">
              {checkNotNull(lists) ? <Typography variant="body1">
                Showing {lists.length} results out of {page}
              </Typography>
                : null}
            </Grid> */}
            </Grid>



            {checkIsNull(info) ? null : info.filter_categories.filter((item, index) => {
              return item.show_in_menu == 1
            }).map((item, index) => {
              return (
                <Grid key={item.code} container className="list_item" spacing={2}>
                  <Grid item xs={3} className="img_box">
                    <CardMedia
                      image={item.clinic ? process.env.REACT_APP_API_IMAGE + item.clinic.clinic_logo_path : null}
                      component="img" />
                  </Grid>
                  <Grid item xs={6} className="context_box">
                    <Typography variant="body2" className="sub_title">
                      {item.clinic ? item.clinic.clinic_name : null}
                    </Typography>
                    <Typography variant="h6" className="title">
                      {item.name}
                    </Typography>
                    <Typography variant="body2" className="gender">
                      Gender: {item.gender}
                    </Typography>
                    <Box variant="body2" >
                      <div className="item_desc" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </Box>
                  </Grid>
                  <Grid item xs={3} className="btns_box">
                    {
                      item.discount_price == null ?
                        <>
                          <Typography variant="body2" className="price"> </Typography>
                          <Typography variant="h5" className="sale_price">
                            HK$ {item.original_price}
                          </Typography>
                        </>
                        :
                        <>
                          <Typography variant="body2" className="price">
                            HK$ {item.original_price}
                          </Typography>
                          <Typography variant="h5" className="sale_price">
                            HK$ {item.discount_price}
                          </Typography>
                        </>
                    }

                    <Button variant="outlined">COMPARE</Button>
                    <Button variant="outlined" onClick={() => toProductPage(item.id)}>DETAIL</Button>
                  </Grid>
                </Grid>
              )
            })


            }




          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md></Grid>
          <Grid item xs={12} md={8} sx={{ display: "flex" }}>
            {checkNotNull(lists) ? <Pagination
              count={checkPage(total, 10)}
              sx={{ margin: "0 auto", pt: "40px" }} onChange={(e, v) => handleClickPage(e, v)} /> : null}
          </Grid>
        </Grid>
        <ContactUs />
      </Container>
    </div >
  );
};
