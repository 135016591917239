import axios from "axios";

// 1 : en-us 
// 2 : zh-hk
// 3 : zh-cn
const CancelToken = axios.CancelToken;
let cancel = null;

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    timeout: process.env.REACT_APP_API_TIMEOUT,
    headers: {
        'Accept': process.env.REACT_APP_API_ACCEPT,
    }
})

AxiosInstance.interceptors.request.use((config) => {
    // if (typeof cancel == 'function') {
    //     cancel('Previous request canceled, new request is send');
    // }
    // config.cancelToken = new CancelToken((c) => {
    //     cancel = c;
    // })
    return config;
}, (error) => {
    return Promise.reject(error);
})

AxiosInstance.interceptors.response.use((response) => {
    
    if (response.status >= 200 && response.status < 400) {
        // cancel = null;
        return response.data;
    } else {
        return Promise.reject(response);
    }
}, (err) => {
    // if (axios.isCancel(err)) {
    //     // console.log('Previous request canceled, new request is send', err.message);
    //     return new Promise(() => { });
    // }
    // cancel = null;
    return Promise.reject(err);

});

export default AxiosInstance;