import "../../styles/views/Pages/cart.scss";
import React, { useEffect, useState, useRef } from "react";

import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import AxiosInstance from "../../controller/axios/AxiosInstance";
import axios from "axios";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  Paper,
  Box,
  Divider,
  Alert,
  Button,
  IconButton,
  InputBase,
  ClickAwayListener,

} from "@mui/material";


import { styled, alpha } from "@mui/material/styles";

import { Form, Ratio } from "react-bootstrap";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import cart_emp from "../../images/cart/cart_emp.webp";
import product1 from "../../images/cart/raffles_e230.webp";
import fwc from "../../images/cart/hka_fwc.webp";
import { useTranslation } from "react-i18next";

import { ContactUs } from "../../components/contact_us";

import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";

import { get_health_product_info, clear_product } from "../../redux/reducers/healthSlice";
import { get_health_product, apply_code, offline_payment } from "../../controller/api/api";
import { checkNotNull } from "../../utils";

import { add_order_items, set_order_prices, update_order_items, clear_carts } from "../../redux/reducers/orderSlice";

import { toggle_isLogin, toggle_isRegister } from "../../redux/reducers/userSlice";


import { useForm, Controller, useFormState } from "react-hook-form";

import { SuccessToast, ErrorToast } from "../../components/ToastSweetAlert2/Toast";

import ContactBtn from "../../components/ContactBtn/ContactBtn";

import Datepicker from "../../components/datepicker/Datepicker";


// Search bar component
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#efefef", 1),
  width: "100%",
  display: "flex",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `1rem`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },
}));

export const Cart = () => {
  const paypal = useRef();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emptyCart, setEmptyCart] = useState(true);
  const [bookingItemInfo, setBookingItemInfo] = useState();

  const { language } = useSelector((state) => state.language);
  const { product } = useSelector((state) => state.health.healths);
  const { items } = useSelector((state) => state.order.order);
  const { booking_id } = useSelector((state) => state.booking.Booking);
  const { user } = useSelector((state) => state.user);
  const { info } = useSelector((state) => state.home.Home);



  const [openDate, setOpenDate] = useState(false);
  const [clinicList, setClinicList] = useState();
  const [code, setCode] = useState('');


  const [paymentType, setPaymentType] = useState();
  const [paymentId, setPaymentId] = useState();



  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    getValues,
    unregister,
    getFieldState,
    setValue,
    setError
  } = useForm();

  const onBookingSubmit = async (data) => {
    //// console.log('file type', typeof data.file);
    //// console.log('onBookingSubmit', data,paymentId);

    if (data.clinic_location_id == null || data.clinic_location_id == undefined) {
      delete data.clinic_location_id;
    }

    let new_data = null;
    if (checkNotNull(items)) {
      let fileData = new FormData();
      let data_temp = {
        check_up_id: items.id,
        customer_id: user && user.userData != null ? user.userData.id : null,
        discount_id: items.discount_apply && items.discount_apply.discount_id ? items.discount_apply.discount_id : '',
        discount_percentage: items.discount_apply && items.discount_apply.discount_percentage ? items.discount_apply.discount_percentage : null,
        original_price: items.original_price,
        final_price: items.discount_apply && items.discount_apply.newPrice != null ? items.discount_apply.newPrice : items.discount_price != null ? items.discount_price : items.original_price,
        payment_type_id: paymentId,
        payment_id: null,
        file: data.file,
        code: data.code == undefined ? null : data.code,
      }

      new_data = { ...data, ...data_temp };
      //// console.log('onBookingSubmit->new_data', new_data);
      for (let i = 0; i < Object.keys(new_data).length; i++) {
        //// console.log('fileData.append', Object.keys(new_data)[i], Object.values(new_data)[i])
        fileData.append(Object.keys(new_data)[i], Object.values(new_data)[i]);
      }
      //// console.log(fileData);
      // fileData.append({...new_data});



      await offline_payment(fileData, language, user && user.access_token)
        .then((res) => {
          // console.log("offline_payment->res", res);
          SuccessToast(res.message);
          navigate("/success_payment", { state: res.booking })
          clearCarts()
        })
        .catch((err) => {
          // console.log("offline_payment->err", err);
          ErrorToast(Object.values(err.response.data.message))
        });
    }

  };

  const toggleDateOpen = () => {
    setOpenDate(!openDate);
  };
  const handleDateChange = (dateVal, onChange) => {

    let temp = new Date(dateVal);
    let new_date = temp.toLocaleDateString('en-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });


    onChange(new_date);
    toggleDateOpen();
  };




  const handlePaymentType = (payment) => {
    // console.log(payment);
    setPaymentType(payment.paypal_bit);
    setPaymentId(payment.id);


    switch (payment.paypal_bit) {
      case 1: {
        unregister('file')
        // // console.log('->PAYPAL');
        break;
      }
      case 0: {
        register('file');
        //// console.log('->OFFICE');
        break;
      }
    }
  }

  const renderPaypalBtn = () => {

    //// console.log(paypal.current.childNodes);
    if (paypal.current.childNodes) {
      paypal.current.innerHTML = "";
    }

    return window.paypal
      .Buttons({
        env: "live",
        client: {
          // sandbox: "AczxKhJGC3fdeqUdn-OhNzkFupfzg-EGA3cAGMGZuw0nnh_Fs1dra2SdrzuB0Ocf6FLxUqdYIyTDVpN9",
          production: "AQ1e9PuKEus5HZxlMEYALZSGpByocpFJ72dWYtx5V0PPpzoLvAiz0k0V8wMQYegye1jwszzToQgUi25V",
        },
        locale: "en_US",
        style: {
          size: "large",
          color: "gold",
          shape: "pill",
          label: "checkout",
        },
        commit: true,
        createOrder: function (resolve, reject) {
          let data = getValues();
          let new_data = { ...data };
          new_data.check_up_id = items.id;
          new_data.customer_id = user && user.userData != null ? user.userData.id : null;
          new_data.return_url =
            process.env.REACT_APP_BASE_FRONTEND_URL + "/success_payment";
          // new_data.clinic_location_id = (clinicList && clinicList.length >= 1 && new_data.clinic_location_id == undefined) ? null : new_data.clinic_location_id;

          console.log(new_data);
          return new Promise((resolve, reject) => {
            // console.log('createOrder->new_data', new_data);
            axios
              .post(
                process.env.REACT_APP_API_DOMAIN + "/paypal/checkout-paypal",
                {
                  // address: new_data.address,
                  check_up_id: new_data.check_up_id,
                  clinic_location_id: new_data.clinic_location_id,
                  customer_id: new_data.customer_id,
                  date: new_data.date,
                  email: new_data.email,
                  first_name: new_data.first_name,
                  last_name: new_data.last_name,
                  remark: new_data.remark,
                  return_url: new_data.return_url,
                  tel: new_data.tel,
                  time: new_data.time,
                  title: new_data.title,
                  final_price: items.discount_apply && items.discount_apply.newPrice ? items.discount_apply.newPrice : items.discount_price != null ? items.discount_price : items.original_price,
                  original_price: items.original_price,
                  code: new_data.code,
                  language_id: language
                }
              )
              .then((res) => {
                // // console.log(res)
                let token;

                for (let link of res.data.links) {
                  if (link.rel === "approval_url") {
                    token = link.href.match(/EC-\w+/)[0];
                  }
                }

                resolve(token);
                // resolve(res.data.id);
              })
              .catch((error) => {
                let err_temp = error.response.data.message;
                Object.keys(err_temp).forEach(item => setError(item, { type: "required" }))
                reject(error.response.data);
                //console.log(error.response.data)
                ErrorToast("Data not submit, Please input!");
              });
          });
        },
        onApprove: function (data) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                process.env.REACT_APP_API_DOMAIN + "/paypal/execute-paypal",
                {
                  payer_id: data.payerID,
                  payment_id: data.paymentID,
                }
              )
              .then((res) => {
                if (res.data.status == "success" && res.data.transaction_id != null) {
                  SuccessToast(res.data.status + ", id: " + res.data.transaction_id);
                  // console.log(res)
                  navigate("/success_payment", { state: res.data.booking })
                  resolve(res);
                  clearCarts()

                } else {
                  ErrorToast("Paypal not able");
                }

              })
              .catch((error) => {
                ErrorToast("Paypal->Error");
                reject(error);
              });
          });
        },
      });


  }

  const checkPaymentType = () => {
    // console.log('checkPaymentType->', paymentType);
    if (paymentType == 1) {
      // // console.log(paypal.current);
      let paypalBtn = renderPaypalBtn();
      if (checkNotNull(paypalBtn)) {
        paypalBtn.render(paypal.current);
      }
    }
  }


  const handleDiscountCode = async (codeStr) => {
    // console.log('handleDiscountCode->code', codeStr)
    setValue('code', codeStr);
    let new_items = null;
    if (codeStr !== null) {
      if (user != null) {
        await apply_code(items.id, code, user.access_token)
          .then((res) => {
            // console.log('handleDiscountCode->apply_code->token->res')
            new_items = { ...items, discount_apply: res };

          })
          .catch((err) => {
            // console.log("handleDiscountCode->apply_code->token->err", err);
            new_items = { ...items, discount_apply: null };
            ErrorToast(err.response.data.message)
            setValue('code', '');
          });
        dispatch(update_order_items(new_items))
      } else {

        await apply_code(items.id, code)
          .then((res) => {
            // console.log("handleDiscountCode->apply_code->res", res);
            new_items = { ...items, discount_apply: res };

          })
          .catch((err) => {
            // console.log("handleDiscountCode->apply_code->err", err);
            new_items = { ...items, discount_apply: null };
            ErrorToast(err.response.data.message)
            setValue('code', '')
          });
        dispatch(update_order_items(new_items))

      }

    } else {
      await apply_code(items.id, null)
        .then((res) => {
          // console.log("handleDiscountCode->apply_code->res", res);
          new_items = { ...items, discount_apply: res };

        })
        .catch((err) => {
          // console.log("handleDiscountCode->apply_code->err", err);
          new_items = { ...items, discount_apply: null };
          ErrorToast(err.response.data.message)
          setValue('code', '')
        });
      dispatch(update_order_items(new_items))
    }
    setCode('')




  }

  useEffect(() => {
    if (user != null) {
    } else {
      let new_items = { ...items, discount_apply: null };
      dispatch(update_order_items(new_items));
      setCode(null);
    }
    if (items && items.id) {

      handleDiscountCode();
    }
  }, [user]);



  useEffect(() => {
    checkPaymentType();
  }, [paymentType]);

  const getProductInfo = async (id, lang) => {
    await get_health_product(id, lang)
      .then((res) => {
        // console.log("getProductInfo->res", res);
        dispatch(add_order_items(res.product));
      })
      .catch((err) => {
        // console.log("getProductInfo->err", err);
        dispatch(add_order_items(null));
      });
  };

  const clickBookingItem = () => {
    if (checkNotNull(product)) {
      setEmptyCart(false);
      if (items.id == booking_id) {
        setBookingItemInfo(product);
        let new_branchs = [];
        items.branches.map((item, index) => {
          let obj = {};
          obj.value = item.id;
          obj.label = item.location_clinic_name;
          new_branchs.push(obj);
        });

        setClinicList(new_branchs);

      } else {
        if (checkNotNull(language) && checkNotNull(booking_id)) {
          getProductInfo(booking_id, language);
        } else {
        }
      }
    } else {
      setEmptyCart(true);
    }
  };

  const handleLoginBtn = () => {
    dispatch(toggle_isLogin(true));
  }
  const handleJoinBtn = () => {
    dispatch(toggle_isRegister(true));

  }


  const clearCarts = () => {
    setEmptyCart(true);

    dispatch(clear_carts());
    dispatch(clear_product());
  }


  useEffect(() => {
    clickBookingItem();
  }, []);


  const updateProductByLanguage = async (id, language) => {
    await get_health_product(id, language)
      .then(res => {
        //console.log(res)
        dispatch(add_order_items(res.product));
      })
      .catch(err => {
        console.log(err);
      })
  }


  useEffect(() => {
    if (language != null) {
      if (items !== null) {
        //console.log('carts->lang');
        updateProductByLanguage(items.id, language)
      }
    }
  }, [language])


  return (
    <div className="cart_section">
      {emptyCart == true && (product == null || items == null) ? (
        <Container maxWidth="lg">
          <Box className="empty_cart_div" sx={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ marginBottom: "20px" }}
            >
              {t("booking_empty_carts.1")}
            </Typography>
            <Grid item xs={12}>
              <img src={cart_emp} />
            </Grid>

            <Link className="back_btn" to="/">
              <FingerprintIcon className="touch_icon" />
              <Typography variant="body" component="div">
                {t("booking_return_home.1")}
              </Typography>
            </Link>
          </Box>
        </Container>
      ) : (
        items == null ? null :
          <>
            <Box className="shopping_cart_div">
              <form onSubmit={handleSubmit(onBookingSubmit)}>
                <Container maxWidth="lg">
                  <Typography variant="h4" component="div" sx={{ mb: "24px" }}>
                    {t("booking_booking_text.1")}
                  </Typography>

                  <Grid container className="cart_context_div">
                    <Grid item xs={12} sm={12} md={7} className="left_div">
                      <Paper elevation={0} className="products_div">
                        <Grid container alignItems="center" sx={{ padding: '8px 0 ' }} >
                          <Grid item xs={10}>
                            <Box>
                              <Typography
                                variant="h6"
                                className="title"
                              >
                                {t("booking_booking_text.1")}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item className="right_div" xs={2} sx={{ alignItems: 'center', display: { xs: 'flex', sm: 'none' }, textAlign: 'center' }}>
                            <IconButton aria-label="delete" className="del_btn" onClick={() => clearCarts()}>
                              <ClearOutlinedIcon />
                            </IconButton>
                          </Grid>
                        </Grid>

                        <Divider light />
                        <Box className="product_detail" alignItems="center" sx={{ padding: { xs: '0', md: '30px' } }}>
                          <Grid container>
                            <Grid item className="left_div" xs={12} sm={10} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                              <Grid item className="img_div" xs={9} md={12} sx={{ width: { xs: '100%', md: '100px' }, padding: '0 16px 16px' }}>
                                <Ratio aspectRatio="16x9">
                                  <CardMedia
                                    src={
                                      items && items.clinic && items.clinic.clinic_logo_path !== null
                                        ? process.env.REACT_APP_API_IMAGE +
                                        items.clinic.clinic_logo_path
                                        : null
                                    }
                                    component="img"
                                    loading="lazy"
                                    sx={{ width: { xs: '100%', sm: '100%' }, objectFit: 'contain' }}
                                  />
                                </Ratio>
                              </Grid>
                              <Grid item className="product_context" xs={12} sx={{ textAlign: 'center' }}>
                                <Typography
                                  variant="body"
                                  component="div"
                                  className="title"
                                >
                                  {items.name}
                                </Typography>
                                <Typography
                                  variant="body"
                                  component="div"
                                  className="price"
                                  sx={{ fontSize: { xs: '20px', md: '14px' } }}
                                >
                                  {t("booking_hkd.1")}${" "}
                                  {items.discount_price !== null
                                    ? items.discount_price
                                    : items.original_price}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item className="right_div" justifyContent="right" xs={2} sx={{ alignItems: 'center', display: { xs: 'none', sm: 'flex' }, textAlign: 'center', padding: '0 8px 8px' }}>
                              <IconButton aria-label="delete" className="del_btn" onClick={() => clearCarts()}>
                                <ClearOutlinedIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>


                      </Paper>
                      <Paper elevation={0} className="products_div" sx={{ mt: '40px' }}>
                        <Typography variant="body1" className="warning_text" sx={{ mt: 1, mb: 2 }}>
                          {t("booking_warn_text.1")}
                        </Typography>
                        {/* <Alert
                          severity="error"
                          sx={{ color: "#f0506e", margin: "16px 0" }}
                        >
                          Please ensure you have inserted your client details.
                        </Alert> */}
                        <Grid
                          container
                          spacing={2}
                          className="booking_form"

                        >

                          <Grid item xs={12} md={4} className="title_box">
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_title.1")}<span className="warn_star">*</span>:
                              </Typography>
                              <Controller
                                name="title"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => (
                                  <Select
                                    className="title_select"
                                    placeholder={t("booking_title.1")}
                                    options={[
                                      { value: "mr", label: "MR" },
                                      { value: "ms", label: "MS" },
                                      { value: "mrs", label: "MRS" },
                                    ]}
                                    onChange={(e) => onChange(e.value)}
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        boxShadow: "unset",
                                        border: 0,
                                        backgroundColor: "#efefef",
                                        fontSize: "13px",
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        margin: 0,
                                        fontSize: "13px",
                                        backgroundColor: "#efefef",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "#efefef",
                                        color: "#666",
                                      }),
                                      ":active": (styles) => ({
                                        ...styles[":active"],
                                        backgroundColor: "red",
                                      }),
                                    }}
                                  />
                                )}
                              />
                              {errors.title &&
                                errors.title.type === "required" && (
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "12px", color: "red" }}
                                  >
                                    {t("header_required.1")}
                                  </Typography>
                                )}{" "}
                            </Box>
                          </Grid>
                          <Grid xs={12} md={8} item>
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_email.1")}<span className="warn_star">*</span>:
                              </Typography>
                              <Search
                                className="search_div"
                                sx={{
                                  width: { xs: "100%" },
                                  height: "100%",
                                }}
                              >
                                <StyledInputBase
                                  className="booking_input"
                                  placeholder={t("booking_email.1")}
                                  sx={{ width: "100%", fontSize: "13px" }}
                                  {...register("email", { required: true })}
                                />
                              </Search>
                              {errors.email && errors.email.type === "required" && (
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "12px", color: "red" }}
                                >
                                  {t("header_required.1")}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            {" "}
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_first_name.1")}<span className="warn_star">*</span>:
                              </Typography>
                              <Search
                                className="search_div"
                                sx={{
                                  width: { xs: "100%" },
                                  height: "100%",
                                }}
                              >
                                <StyledInputBase
                                  className="booking_input"
                                  placeholder={t("booking_first_name.1")}
                                  sx={{ width: "100%", fontSize: "13px" }}
                                  {...register("first_name", { required: true })}
                                />
                              </Search>
                              {errors.first_name &&
                                errors.first_name.type === "required" && (
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "12px", color: "red" }}
                                  >
                                    {t("header_required.1")}
                                  </Typography>
                                )}{" "}
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            {" "}
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_last_name.1")}<span className="warn_star">*</span>:
                              </Typography>
                              <Search
                                className="search_div"
                                sx={{
                                  width: { xs: "100%" },
                                  height: "100%",
                                }}
                              >
                                <StyledInputBase
                                  className="booking_input"
                                  placeholder={t("booking_last_name.1")}
                                  sx={{ width: "100%", fontSize: "13px" }}
                                  {...register("last_name", { required: true })}
                                />
                              </Search>
                              {errors.last_name &&
                                errors.last_name.type === "required" && (
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "12px", color: "red" }}
                                  >
                                    {t("header_required.1")}
                                  </Typography>
                                )}{" "}
                            </Box>
                          </Grid>

                          <Grid xs={12} md={6} item>
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_mobile.1")}<span className="warn_star">*</span>:
                              </Typography>
                              <Search
                                className="search_div"
                                sx={{
                                  width: { xs: "100%" },
                                  height: "100%",
                                }}
                              >
                                <StyledInputBase
                                  className="booking_input"
                                  placeholder={t("booking_mobile.1")}
                                  sx={{ width: "100%", fontSize: "13px" }}
                                  {...register("tel", { required: true })}
                                />
                              </Search>
                              {errors.tel && errors.tel.type === "required" && (
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "12px", color: "red" }}
                                >
                                  {t("header_required.1")}
                                </Typography>
                              )}
                            </Box>
                          </Grid>

                          {/* <Grid xs={8} item>
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_address.1")}:
                              </Typography>
                              <Search
                                className="search_div"
                                sx={{
                                  width: { xs: "100%" },
                                  height: "100%",
                                }}
                              >
                                <StyledInputBase
                                  className="booking_input"
                                  placeholder={t("booking_address.1")}
                                  sx={{ width: "100%", fontSize: "13px" }}
                                  {...register("address", { required: true })}
                                />
                              </Search>
                              {errors.address &&
                                errors.address.type === "required" && (
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "12px", color: "red" }}
                                  >
                                    {t("header_required.1")}
                                  </Typography>
                                )}
                            </Box>
                          </Grid> */}
                          <Grid item xs={12} md={3} className="time_box">
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_time.1")}<span className="warn_star">*</span>:
                              </Typography>
                              <Controller
                                name="time"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => (
                                  <Select
                                    className="time_select"
                                    placeholder={t("booking_time.1")}
                                    options={[
                                      { value: "am", label: "AM" },
                                      { value: "pm", label: "PM" },
                                    ]}
                                    onChange={(e) => onChange(e.value)}
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        boxShadow: "unset",
                                        border: 0,
                                        backgroundColor: "#efefef",
                                        fontSize: "13px",
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        margin: 0,
                                        fontSize: "13px",
                                        backgroundColor: "#efefef",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "#efefef",
                                        color: "#666",
                                      }),
                                      ":active": (styles) => ({
                                        ...styles[":active"],
                                        backgroundColor: "red",
                                      }),
                                    }}
                                  />
                                )}
                              />
                              {errors.time &&
                                errors.time.type === "required" && (
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "12px", color: "red" }}
                                  >
                                    {t("header_required.1")}
                                  </Typography>
                                )}{" "}
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={3} className="date_box">
                            <ClickAwayListener
                              onClickAway={() => setOpenDate(false)}
                            >
                              <Box>
                                <Typography variant="body1" className="field_name">
                                  {t("booking_date.1")}<span className="warn_star">*</span>:
                                </Typography>
                                {/* <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                > */}
                                <Controller
                                  name="date"
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue={''}
                                  render={({ field: { value, onChange } }) => (
                                    <Box>
                                      <Search
                                        className="search_div"
                                        sx={{
                                          width: { xs: "100%" },
                                          height: "100%",
                                        }}
                                      >
                                        <StyledInputBase
                                          className="booking_input"
                                          placeholder={t("booking_date.1")}
                                          sx={{
                                            width: "100%",
                                            fontSize: "13px",
                                          }}
                                          onClick={toggleDateOpen}
                                          value={value}
                                          readOnly
                                        />
                                      </Search>
                                      <Box
                                        sx={{
                                          display: openDate ? "block" : "none",

                                        }}
                                      >
                                        {/* <CalendarPicker
                                            minDate={new Date()}
                                            onChange={(val) =>
                                              handleDateChange(val, onChange)
                                            }
                                          /> */}
                                        <Datepicker minDate={new Date()} changeDate={(val) =>
                                          handleDateChange(val, onChange)
                                        } dateWidth={'unset'} />
                                      </Box>
                                    </Box>
                                  )}
                                />
                                {/* </LocalizationProvider> */}
                                {errors.date &&
                                  errors.date.type === "required" && (
                                    <Typography
                                      variant="body1"
                                      sx={{ fontSize: "12px", color: "red" }}
                                    >
                                      {t("header_required.1")}
                                    </Typography>
                                  )}{" "}
                              </Box>
                            </ClickAwayListener>
                          </Grid>
                          <Grid item xs={12} >
                            <Typography variant="body2" sx={{ color: 'teal' }}>{t("booking_date_note.1")}</Typography>
                          </Grid>
                          {clinicList && clinicList.length >= 1 ?
                            <Grid item xs={12} className="centre_box">
                              <Box>
                                <Typography variant="body1" className="field_name">
                                  {t("booking_centre.1")}<span className="warn_star">*</span>:
                                </Typography>
                                <Controller
                                  name="clinic_location_id"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { value, onChange } }) => (
                                    <Select
                                      className="centre_select"
                                      placeholder={t("booking_centre.1")}
                                      options={clinicList ? clinicList : ''}
                                      onChange={(e) => onChange(e.value)}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          boxShadow: "unset",
                                          border: 0,
                                          backgroundColor: "#efefef",
                                          fontSize: "13px",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          margin: 0,
                                          fontSize: "13px",
                                          backgroundColor: "#efefef",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: "#efefef",
                                          color: "#666",
                                        }),
                                        ":active": (styles) => ({
                                          ...styles[":active"],
                                          backgroundColor: "red",
                                        }),
                                      }}
                                    />
                                  )}
                                />
                                {errors.clinic_location_id &&
                                  errors.clinic_location_id.type === "required" && (
                                    <Typography
                                      variant="body1"
                                      sx={{ fontSize: "12px", color: "red" }}
                                    >
                                      {t("header_required.1")}
                                    </Typography>
                                  )}{" "}
                              </Box>
                            </Grid> : null}
                          <Grid xs={12} item>
                            <Box>
                              <Typography variant="body1" className="field_name">
                                {t("booking_remark.1")}:
                              </Typography>
                              <Search
                                className="search_div"
                                sx={{
                                  width: { xs: "100%" },
                                  height: "100%",
                                }}
                              >
                                <StyledInputBase
                                  className="booking_input"
                                  placeholder={t("booking_remark.1")}
                                  sx={{ width: "100%", fontSize: "13px" }}
                                  {...register("remark", { required: false })}
                                  multiline
                                  rows={5}
                                />
                              </Search>
                            </Box>
                          </Grid>




                        </Grid>
                      </Paper>
                      <Grid container sx={{ padding: { xs: '10px 0', md: '70px 0' } }}>

                        <>
                          <Grid item xs={12} md>
                            <Box className="discount_div" sx={{ padding: { xs: '10px 0', md: '0' } }}>
                              <Typography variant="body" component="div">
                                {t("booking_discount_code_title.1")}
                              </Typography>
                              <Typography
                                variant="body"
                                component="div"
                                className="discount_text"
                              >
                                {t("booking_discount_code_title_2.1")}
                              </Typography>


                              <Grid container className="input_div" spacing={2}>
                                {items.discount_apply && items.discount_apply.discount_code != null ?
                                  <>
                                    <Grid item xs={9} md={6}>
                                      <Typography variant="h6" sx={{ color: 'teal' }}>{items.discount_apply.discount_code}</Typography>
                                    </Grid>
                                    <Grid item xs={3} md={6}>
                                      <IconButton onClick={() => handleDiscountCode('')}>
                                        <HighlightOffRoundedIcon />
                                      </IconButton>
                                    </Grid>
                                  </>
                                  :
                                  <>
                                    <Grid item xs={9} md={6}>
                                      <Form.Control type="text" placeholder={t("booking_discount_code_input.1")}
                                        onChange={(e) => setCode(e.target.value)} value={code} />

                                    </Grid>

                                    <Grid item xs={3} md={6}>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          backgroundColor: "teal",
                                          paddingLeft: "15px",
                                          paddingRight: "15px",

                                          "&:hover": {
                                            backgroundColor: "green",
                                          },
                                        }}
                                        onClick={() => handleDiscountCode(code)}
                                      >
                                        {t("booking_discount_code_apply.1")}
                                      </Button>
                                    </Grid>
                                  </>

                                }
                              </Grid>
                            </Box>
                          </Grid>
                          {user !== null ? null :

                            <Grid item xs={12} md={5}>
                              <Box className="discount_div" sx={{ padding: { xs: '10px 0', md: '0' } }}>
                                <Typography variant="body" component="div">
                                  {t("booking_member_title.1")}
                                </Typography>
                                <Typography
                                  variant="body"
                                  component="div"
                                  className="discount_text"
                                >
                                  {t("booking_member_title2.1")}
                                </Typography>

                                <Grid container className="input_div" spacing={2} >
                                  <Grid item xs={6}>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        width: '100%',
                                        backgroundColor: "teal",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                        "&:hover": {
                                          backgroundColor: "green",
                                        },
                                      }}
                                      onClick={() => handleLoginBtn()}
                                    >
                                      {t("booking_member_login_btn.1")}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        width: '100%',
                                        backgroundColor: "teal",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                        "&:hover": {
                                          backgroundColor: "green",
                                        },
                                      }}
                                      onClick={() => handleJoinBtn()}
                                    >
                                      {t("booking_member_register_btn.1")}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          }
                        </>
                      </Grid>

                      {/* <Paper elevation={0} className="point_div">
                  <Grid container className="context_div">
                    <Grid item xs={12} md={7} className="left_div">
                      <Typography
                        variant="body"
                        component="div"
                        className="title"
                      >
                        Earn FWC points reward
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className="context"
                      >
                        FWC members can enjoy HKD 2 spent = 1 FWC points on
                        Zanolife website
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className="context"
                      >
                        *Please note that the name on the membership submitted
                        must match the name of the lead guest. Terms and
                        conditions apply
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} className="right_div">
                      <CardMedia
                        component="img"
                        image={fwc}
                        sx={{ width: { xs: "auto", sm: "auto", md: "100%" } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="input_div">
                    <Grid container className="name_div">
                      <Grid item xs={5} sm={3} className="inputs_t">
                        <Form.Control type="text" placeholder="First Name" />
                      </Grid>
                      <Grid item xs={5} sm={3} className="inputs_t">
                        <Form.Control type="text" placeholder="Last Name" />
                      </Grid>
                    </Grid>
                    <Grid container className="submit_div">
                      <Grid item xs>
                        <Form.Control
                          type="text"
                          placeholder="Membership no."
                        />
                      </Grid>
                      <Grid item xs={3} className="btn_div">
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "teal",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            width: "100%",
                            "&:hover": {
                              backgroundColor: "green",
                            },
                          }}
                        // onClick={() => clickToPage({ pathname: '/blog' })}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} className="right_div">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={11}
                        sx={{
                          margin: {
                            xs: "40px auto",
                            sm: "40px auto",
                            md: "0 auto",
                          },
                        }}
                      >
                        <Paper elevation={0} className="order_div">
                          <Typography variant="body" component="div">
                            {t("booking_order_summary.1")}
                          </Typography>
                          <Grid container className="gp_div" justifyContent="space-between">
                            <Grid item xs={8}>
                              <h6>{items.name}</h6>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                              <h6 className="products_price">
                                {t("booking_hkd_2.1")}{" "}
                                {items.discount_price !== null
                                  ? items.discount_price
                                  : items.original_price}
                              </h6>
                            </Grid>
                          </Grid>

                          {/* {user != null ?
                          <Grid container className="gp_div" justifyContent="space-between">
                            <Grid item xs={8}>
                              <h6>Membership discount 5%</h6>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                              <h6 className="products_price">( HKD 127 )</h6>
                            </Grid>
                          </Grid>
                          : null} */}
                          {items.discount_apply && items.discount_apply.discount_code != null ?
                            < Grid container className="gp_div" justifyContent="space-between">
                              <Grid item xs={8}>
                                <h6>{items.discount_apply.discount_title} ({items.discount_apply.discount_code == null ? items.discount_apply.discount_percentage + '%' : 'Code:' + items.discount_apply.discount_code + " (" + items.discount_apply.discount_percentage + '%)'})</h6>
                              </Grid>
                              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <h6 className="products_price">
                                  {t("booking_hkd_2.1")}{" "}
                                  {items.discount_apply.discount_amount}
                                </h6>
                              </Grid>
                            </Grid>
                            : null}
                          <Grid container className="gp_div" justifyContent="space-between">
                            <Grid item xs={8}>
                              <h6>{t("booking_order_total.1")}</h6>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                              <h6 className="products_price">
                                {t("booking_hkd_2.1")}{" "}
                                {items.discount_apply ? items.discount_apply.newPrice :
                                  items.discount_price !== null
                                    ? items.discount_price
                                    : items.original_price
                                }
                              </h6>
                            </Grid>
                          </Grid>

                        </Paper>
                        <Alert
                          severity="error"
                          sx={{ color: "#f0506e", margin: "16px 0" }}
                        >
                          {t("booking_order_sure_text.1")}
                        </Alert>

                        <Typography variant="body" component="div" sx={{ mb: 1 }}>
                          {t("booking_payment_method.1")}
                        </Typography>
                        {info && info.payment_types && info.payment_types.map((item, index) => {
                          return (<Button
                            key={item + index}
                            variant="contained"
                            sx={{
                              width: '100%',
                              backgroundColor: "teal",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              "&:hover": {
                                backgroundColor: "green",
                              },
                              mb: 1
                            }}
                            onClick={() => handlePaymentType(item)}
                            {...register("payment_type_id", { required: false })}
                          >
                            {item.payment_name}
                          </Button>)
                        })}
                        {errors.payment_type_id &&
                          errors.payment_type_id.type === "required" && (
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "12px", color: "red" }}
                            >
                              {t("header_required.1")}
                            </Typography>
                          )}{" "}

                        {(info && info.payment_types) && (paymentType != null && paymentType == 0) ?
                          <Paper elevation={0} className="order_div" sx={{ margin: '8px 0 ' }}>

                            {info && info.payment_types.filter((item) => item.id == paymentId).map((item) => {
                              return (<Box key={item.id}>
                                <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                                  {t("booking_member_method.1")}: {item.payment_name}
                                </Typography>
                                <Typography variant="body" component="div" sx={{ mb: 1 }}>
                                  {item.description}
                                </Typography>
                              </Box>
                              )
                            })}

                            {paymentType == 0 &&
                              <Grid xs={12} item>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#f0506e", mb: 1 }}
                                  >
                                    {t("booking_image_upload_warn.1")}
                                  </Typography>
                                  <Search
                                    className="search_div"
                                    sx={{
                                      width: { xs: "100%" },
                                      height: "100%",
                                    }}
                                  >
                                    <Controller
                                      control={control}
                                      name="file"
                                      rules={{ required: true }}
                                      defaultValue={""}
                                      render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <input
                                          className="booking_input"
                                          placeholder="file"
                                          style={{ fontSize: "13px", padding: '8px' }}
                                          type="file"
                                          accept=".png, .jpeg, .jpg"
                                          onChange={(e) => {
                                            // console.log(e.target.files);
                                            onChange(e.target.files[0])
                                          }}
                                        />
                                      )}
                                    />

                                  </Search>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#f0506e", mb: 1, fontSize: '12px' }}
                                  >
                                    {t("booking_image_size_warn.1")}<span className="warn_star">*</span>:
                                  </Typography>
                                  {errors.file &&
                                    errors.file.type === "required" && (
                                      <Typography
                                        variant="body1"
                                        sx={{ fontSize: "12px", color: "red" }}
                                      >
                                        {t("header_required.1")}
                                      </Typography>
                                    )}{" "}
                                </Box>
                              </Grid>

                            }
                          </Paper>
                          : null}



                        {
                          paymentType !== null ? paymentType == 0 ? <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              backgroundColor: "teal",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              mb: 2,
                              mt: 1,
                              "&:hover": {
                                backgroundColor: "green",
                              },
                            }}
                            type="submit"
                          >
                            {t("booking_pay_btn.1")}
                          </Button> : <div>
                            {paypal ? (
                              <div className="paypalButton" ref={paypal}></div>
                            ) : (
                              ""
                            )}
                          </div> : null
                          // <Typography variant="body" component="div" sx={{ mb: 1 }}>
                          //   Choose the method
                          // </Typography>
                        }



                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </form>
            </Box>
          </>
      )
      }

      <ContactUs />
      <ContactBtn />
    </div >
  );
};
