import "../../styles/views/Pages/blog.scss";
import React, { useEffect, useState, useCallback, useMemo } from "react";

import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Box,
} from "@mui/material";
import { Pagination as Pag } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";

import bg from "../../images/blog/bg.webp";
import img1 from "../../images/blog/sd_989a.webp";

import {
  get_news_list,
  get_news_category_list,
} from "../../controller/api/api";

import { useDispatch, useSelector } from "react-redux";
import { get_lists, get_gategory_lists } from "../../redux/reducers/newsSlice";

import { checkPage } from "../../utils";

import { useTranslation } from "react-i18next";

export const Blog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const COUNT_PER_PAGES = 10;

  const [startIndex, setStartIndex] = useState(0);
  const [newsPage, setNewsPage] = useState(1);
  const { news_list, news_category_list, select_categroy_id } = useSelector(
    (state) => state.news
  );
  const { language } = useSelector(state => state.language);

  const [newList, setNewList] = useState([]);
  const [count, setCount] = useState(0);


  const { t } = useTranslation();



  const getNewList = async (language, newsPage, cate = null) => {
    // console.log('getNewList')


    await get_news_list(language, newsPage, cate)
      .then((res) => {
        // console.log("getNewList->dispatch", res);
        let newArr = res.news.filter(item => item.seo_link == null || item.seo_link == '')
        console.log('get_news_list->seo_link == empty', newArr)
        setNewList(res.news);
        setCount(res.total);
        dispatch(get_lists(res));
      })
      .catch((err) => console.log(err));

  };

  // const getNewList = useCallback(async () => {
  //     // console.log('getNewList')
  //     await get_news_list()
  //         .then(res => {
  //             // console.log('getNewList->dispatch')
  //             dispatch(get_lists(res));
  //         })
  //         .catch(err => // console.log(err))
  // }, [dispatch])

  const getNewCategoryList = async () => {
    //// console.log('getNewList')

    await get_news_category_list(language)
      .then((res) => {
        // console.log("getNewCategoryList->dispatch", res);
        dispatch(get_gategory_lists(res));
      })
      .catch((err) => console.log(err));

  };





  const handlePageUpdate = (event, num) => {
    setNewsPage(num);
    setStartIndex(num * COUNT_PER_PAGES);
    getNewList(language, num, select_categroy_id)
  };

  const handleGategoryChange = (id) => {

    handlePageUpdate(null, 1)
  };

  const checkHeaderCategroy = () => {
    if (select_categroy_id !== null && select_categroy_id !== undefined) {
      if (select_categroy_id == 0) {

        getNewList(language, 1);
      } else {
        handleGategoryChange(select_categroy_id);
      }
    }
  };

  const linkToPost = (event, item) => {
    event.preventDefault();
    navigate(`/blog/post/${item.seo_link}`);
  };


  useEffect(() => {
    if (language != null) {
      getNewList(language, 1, select_categroy_id == null ? 1 : select_categroy_id);
      setNewsPage(1);
      getNewCategoryList();

      if (news_list !== null) {
        checkHeaderCategroy();
      }
    }
    // // console.log(news_list);
    // // console.log(count);
  }, [select_categroy_id, language]);


  return (
    <div className="blog_section">
      <Grid item xs={12}>
        <img
          src={bg}
          style={{ height: "450px", width: "100%", objectFit: "cover" }}
        />
      </Grid>

      <Container maxWidth="lg">
        <Grid container sx={{ p: "40px 0" }}>
          <Grid item xs={12} md={8} className="left_arts_div">
            <Typography variant="h4" component={"div"} className="title">
              {t("BLOG_Latest.1")}
            </Typography>
            <Swiper
              className="lastest_arts_list"
              modules={[FreeMode, Pagination, Navigation]}
              slidesPerGroup={2}
              slidesPerView={2}
              freeMode={false}
              pagination={{ clickable: true }}
              navigation={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                600: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                900: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  freeMode: true,
                },
                1200: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  freeMode: false,
                },
              }}
            >
              {newList == null
                ? null
                : newList
                  .filter((item, index) => {
                    return index < 8;
                  })
                  .map((item, index) => {
                    return (
                      <SwiperSlide
                        className="arts_list"
                        key={item.id}
                      >
                        <Link
                          to="#"
                          className="list_item"
                          onClick={(event) => linkToPost(event, item)}
                        >
                          <Paper
                            elevation={0}
                            sx={{
                              margin: "0 auto",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                process.env.REACT_APP_API_IMAGE +
                                item.image_path
                              }
                              alt={item.seo_link}
                              sx={{
                                height: { md: "100px", xs: "170px" },
                                width: "100%",
                              }}
                            />
                            <CardContent className="context">
                              <h6 className="date">
                                {item.date}
                              </h6>
                              <h6 className="desc">{item.title}</h6>
                            </CardContent>
                          </Paper>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
            <Box
              className="category_div"
              sx={{ display: { md: "none", xs: "block" }, mb: "40px" }}
            >
              <Typography variant="h4" component={"div"} className="title">
                {t("BLOG_Cate.1")}
              </Typography>
              <Swiper
                className="category_list"
                modules={[FreeMode, Pagination, Navigation]}
                slidesPerGroup={2}
                slidesPerView={2}
                freeMode={false}
                pagination={{ clickable: true }}
                navigation={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  600: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  900: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    freeMode: true,
                  },
                  1200: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    freeMode: false,
                  },
                }}
              >
                {news_category_list.lists == null
                  ? null
                  : news_category_list.lists.map((item, index) => {
                    return (
                      <SwiperSlide
                        className="arts_list"
                        key={item.id}
                      >
                        <Link
                          to="#"
                          className="list_item"
                          onClick={() => handleGategoryChange(item.id)}
                        >
                          <Paper
                            elevation={0}
                            sx={{
                              margin: "0 auto",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                process.env.REACT_APP_API_IMAGE +
                                item.banner_image_path
                              }
                              alt={item.seo_link}
                              sx={{ height: { md: "100px", xs: "170px" } }}
                            />
                            <h6 className="name">{item.seo_link}</h6>
                          </Paper>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Box>
            <div className="other_arts_div">
              <Typography variant="h4" component={"div"} className="title">
                {t("BLOG_Others.1")}
              </Typography>
              {newList == null
                ? null
                : newList
                  // .filter((item, index) => {
                  //   return (
                  //     index >= startIndex - COUNT_PER_PAGES &&
                  //     index < newsPage * COUNT_PER_PAGES
                  //   );
                  // })
                  .map((item, index) => {
                    return (
                      <Link
                        to="#"
                        className="list_item"
                        key={item.id}
                        onClick={(event) => linkToPost(event, item)}
                      >
                        <div className="arts_list">
                          <CardMedia
                            component="img"
                            image={
                              process.env.REACT_APP_API_IMAGE +
                              item.image_path
                            }
                            alt={item.seo_link}
                            sx={{ width: "30%", height: "auto" }}
                          />
                          <div className="context">
                            <h6 className="date">
                              {item.date}
                            </h6>
                            <h6 className="desc">{item.title}</h6>
                            <div
                              className="arts_context"
                              dangerouslySetInnerHTML={{
                                __html: item.content,
                              }}
                            ></div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
            </div>
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: { md: "block", sm: "none", xs: "none" },
              pl: { lg: "60px", md: "30px" },
            }}
            className="recent_arts_div"
          >
            <Typography variant="h4" component={"div"} className="title">
              {t("BLOG_Recent.1")}
            </Typography>
            {newList == null
              ? null
              : newList
                .filter((item, index) => {
                  return index < 3;
                })
                .map((item, index) => {
                  return (
                    <Link
                      to="#"
                      className="list_item"
                      key={item.id}
                      onClick={(event) => linkToPost(event, item)}
                    >
                      <Paper
                        className="arts_list"
                        elevation={0}
                        sx={{ display: "flex" }}
                      >
                        <CardMedia
                          component="img"
                          image={
                            process.env.REACT_APP_API_IMAGE + item.image_path
                          }
                          alt={item.seo_link}
                          sx={{ width: "30%", height: "auto" }}
                        />
                        <CardContent className="context">
                          <h6 className="date">
                            {item.date}
                          </h6>
                          <h6 className="desc">{item.title}</h6>
                        </CardContent>
                      </Paper>
                    </Link>
                  );
                })}
          </Grid>
          <Pag
            page={newsPage}
            count={checkPage(count, COUNT_PER_PAGES)}
            onChange={handlePageUpdate}
            sx={{ margin: "0 auto", pt: "40px" }}
          />
        </Grid>
      </Container>
    </div>
  );
};
