import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import './i18n'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/app.scss';

import store from './redux/store';
import { Provider } from 'react-redux';



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Provider store={store}>
//     <BrowserRouter >
//       <App />
//     </BrowserRouter>
//   </Provider>
// );
const root = document.getElementById('root');

if (root.hasChildNodes()) {
  // console.log('root.hasChildNodes()')
  ReactDOM.hydrateRoot(root).render(<Provider store={store}>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </Provider>)
} else {
  // console.log('rootEl.render')

  ReactDOM.createRoot(root).render(
    <Provider store={store}>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Provider>);
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
