import "../../styles/views/Pages/centrestoreservice.scss";
import React, { useEffect, useState, useRef } from "react";

import { useLocation, useParams, useNavigate, Navigate } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Box,
  Breadcrumbs,
  Button,
  CardMedia,
  Table,
  TableBody,
  Zoom,
  Backdrop,
  ClickAwayListener,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';


import { ListGroup, Accordion, Form, Ratio } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation, FreeMode, Virtual } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import { ContactUs } from "../../components/contact_us";
import { checkNotNull } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import {
  get_health_product_info,
  get_related_products,
} from "../../redux/reducers/healthSlice";

import { get_health_product } from "../../controller/api/api";

import { get_booking_id } from "../../redux/reducers/bookingSlice";

import { useTranslation } from "react-i18next";
import { add_order_items } from "../../redux/reducers/orderSlice";


import { set_category_id } from "../../redux/reducers/homeSlice";

import ContactBtn from "../../components/ContactBtn/ContactBtn";

import { Helmet } from "react-helmet";
import { SEO_META } from "../../components/seo/SEO";


export const HealthService = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);
  const { product, related_products } = useSelector(
    (state) => state.health.healths
  );
  const { booking_id } = useSelector((state) => state.booking.Booking);


  const [showZoom, setShowZoom] = useState(false);
  const [imgZoom, setImgZoom] = useState(null);



  const get_product_details = async (id, lang_id) => {
    await get_health_product(id, lang_id)
      .then((res) => {
        // console.log("get_product_details", res);
        dispatch(get_health_product_info(res.product));
        dispatch(get_related_products(res.related_products));
        dispatch(add_order_items(res.product));
      })
      .catch((err) => {
        // console.log("get_product_details", err);
        dispatch(get_health_product_info(null));
        dispatch(get_related_products(null));
        dispatch(add_order_items(null));
      });
  };

  const handleBooking = (id) => {
    if (checkNotNull(id)) {
      dispatch(get_booking_id(id));

      navigate("/cart");
    }
  };

  useEffect(() => {
    // console.log("HealthService()", params);
    if (checkNotNull(language)) {
      get_product_details(params.uid, language);
    }
    window.scrollTo(0, 0);
  }, [language, params.uid]);

  return (
    <div className="centre_store_service_section">
      {product !== null ? <SEO_META title={product.meta_title} keyword={product.meta_keyword} description={product.meta_description} /> : null}
      {product !== null ? (


        <Container maxWidth="lg">
          <Breadcrumbs
            className="breadcrumbs"
            sx={{ pt: { xs: "30px", md: "90px ", lg: "20px" } }}
          >
            <Link to="/">
              <Typography underline="hover" color="inherit">
                {t("healths_service_burmb_home.1")}
              </Typography>
            </Link>
            <Link to="/health" onClick={() => dispatch(set_category_id(null))}>
              <Typography underline="hover" color="inherit">
                {t("healths_service_burmb_hs.1")}
              </Typography>
            </Link>
            <Typography color="text.primary">{product.name}</Typography>
          </Breadcrumbs>

          <Grid
            container
            className="store_details"
            sx={{ flexFlow: { xs: "column-reverse", md: "row" } }}
          >
            <Grid item xs={12} md={6} className="left_div" sx={{ display: { xs: 'flex', md: 'block' }, flexDirection: { xs: 'column' } }}>
              <Typography variant="title" component="h1" sx={{ order: { xs: '1', md: 'unset' }, pt: { xs: '8px', md: '0' } }}>
                {product.name}
              </Typography>
              <Grid container alignItems="center" spacing={1} sx={{ order: { xs: '2', md: 'unset' } }}>
                <Grid item xs={3} sx={{ width: "60px" }}>

                  {product.clinic && product.clinic.clinic_logo_path && (
                    <Ratio aspectRatio="16x9">
                      <img
                        src={
                          product.clinic.clinic_logo_path &&
                            product.clinic.clinic_logo_path !== null
                            ? process.env.REACT_APP_API_IMAGE +
                            product.clinic.clinic_logo_path
                            : null
                        }
                        loading="lazy"
                        style={{ objectFit: 'contain' }}
                      />
                    </Ratio>
                  )}

                </Grid>
                <Grid item xs>
                  <Typography variant="body1" className="subTitle">
                    {product.clinic && product.clinic.clinic_name}
                  </Typography>
                </Grid>
              </Grid>

              <Box variant="body2" className="details" sx={{ order: { xs: '3', md: 'unset' } }}>
                <div
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>
              </Box>
              <Box
                className="context_box"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  order: { xs: '4', md: 'unset' }
                }}
              >
                <Typography
                  variant="h4"
                  className="title"
                  sx={{ pl: { xs: "0", md: "30px" } }}
                >
                  ${" "}
                  {product.discount_price == null
                    ? product.original_price
                    : product.discount_price}
                </Typography>
                <Typography
                  variant="h6"
                  className="price"
                  sx={{ pl: { xs: "0", md: "30px" } }}
                >
                  {t("healths_service_p1_price.1")}
                </Typography>
                <Box className="btn_div">
                  <Button
                    className="book_btn"
                    variant="contained"
                    onClick={() => handleBooking(product.id)}
                  >
                    {t("healths_service_p1_book.1")}
                  </Button>
                </Box>
              </Box>

              <Box sx={{ display: { md: "block", xs: "block" }, order: { xs: '0', md: 'unset' } }}>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  watchSlidesProgress={true}
                  modules={[Thumbs]}
                  allowTouchMove={false}
                  className="left_img_list"
                  sx={{ padding: { md: '40px 0', xs: '0' } }}
                  onClick={(s, e) => setThumbsSwiper(s.clickedIndex)}

                  breakpoints={{
                    0: {
                      slidesPerView: 4
                    },
                    900: {
                      slidesPerView: 6
                    }
                  }}
                >
                  {product.clinic && product.clinic.location_images.length >= 1
                    ? product.clinic.location_images.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index} className="list_item" >
                          <Box sx={{ padding: { sm: "0", md: '0 8px' } }}>


                            <Link to="#" className="img_link" >
                              <img
                                src={
                                  item.image_path !== null
                                    ? process.env.REACT_APP_API_IMAGE +
                                    item.image_path
                                    : null
                                }
                                loading="lazy"
                              />
                            </Link>
                          </Box>
                        </SwiperSlide>
                      );
                    })
                    : null}
                  {/* {product.hc_image.length >= 1
                    ? product.hc_image.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index} className="list_item">
                          <Link to="#" className="img_link">
                            <img
                              src={
                                item.image_path !== null
                                  ? process.env.REACT_APP_API_IMAGE +
                                  item.image_path
                                  : null
                              }
                              loading="lazy"
                            />
                          </Link>
                        </SwiperSlide>
                      );
                    })
                    : null} */}
                  {product.branches.length >= 1
                    ? product.branches.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index} className="list_item">
                          <Link to="#" className="img_link">
                            <div
                              className="iframe_size"
                              dangerouslySetInnerHTML={{
                                __html: item.google_map,
                              }}
                            ></div>

                            <Box className="btn_face"></Box>
                          </Link>
                        </SwiperSlide>
                      );
                    })
                    : null}
                </Swiper>
              </Box>

            </Grid>
            <Grid item xs={12} md={6} className="right_div">
              <Grid item xs sx={{ ml: { xs: "0", md: "40px" } }}>
                <Swiper
                  className="store_img_lists"
                  modules={[Navigation, Thumbs]}
                  thumbs={{ swiper: thumbsSwiper }}
                  watchSlidesProgress={true}
                  navigation={true}
                  style={{
                    "--swiper-navigation-color": "#fff",
                  }}
                >
                  {product.clinic && product.clinic.location_images.length >= 1
                    ? product.clinic.location_images.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index}>
                          <CardMedia
                            src={
                              item.image_path !== null
                                ? process.env.REACT_APP_API_IMAGE +
                                item.image_path
                                : null
                            }
                            component="img"
                            sx={{}}
                          />
                        </SwiperSlide>
                      );
                    })
                    : null}
                  {/* {product.hc_image.length >= 1
                    ? product.hc_image.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index}>
                          <CardMedia
                            image={
                              item.image_path !== null
                                ? process.env.REACT_APP_API_IMAGE +
                                item.image_path
                                : null
                            }
                            component="img"
                            sx={{}}
                          />
                        </SwiperSlide>
                      );
                    })
                    : null} */}

                  {product.branches.length >= 1
                    ? product.branches.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index}>
                          <div
                            className="google_branches_div"
                            dangerouslySetInnerHTML={{
                              __html: item.google_map,
                            }}
                          ></div>
                        </SwiperSlide>
                      );
                    })
                    : null}
                </Swiper>
              </Grid>
            </Grid>
          </Grid>

          {product.description == '' || product.description == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p1.1")}
              </Typography>
              <Box variant="body1" className="context" >

                <div

                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>
              </Box>
            </Box>}
          {product.terms == '' || product.terms == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p5.1")}
              </Typography>
              <Box variant="body1" className="context">
                <div
                  dangerouslySetInnerHTML={{ __html: product.terms }}
                ></div>
              </Box>
            </Box>}
          {product.delivery == '' || product.delivery == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p4.1")}
              </Typography>
              <Box variant="body1" className="context">
                <div
                  dangerouslySetInnerHTML={{ __html: product.delivery }}
                ></div>
              </Box>
            </Box>}
          {product.preparation == '' || product.preparation == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p3.1")}
              </Typography>
              <Box variant="body1" className="context">
                <div
                  dangerouslySetInnerHTML={{ __html: product.preparation }}
                ></div>
              </Box>
            </Box>}
          {product.detail == '' || product.detail == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p7.1")}
              </Typography>
              <Box variant="body1" className="context">
                <div
                  dangerouslySetInnerHTML={{ __html: product.detail }}
                ></div>
              </Box>
            </Box>}
          {product.highlight == '' || product.highlight == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p8.1")}
              </Typography>
              <Box variant="body1" className="context">
                <div
                  dangerouslySetInnerHTML={{ __html: product.highlight }}
                ></div>
              </Box>
            </Box>}
          {product.notes == '' || product.notes == null ? null :
            <Box className="service_desc">
              <Typography variant="h5" className="title">
                {t("healths_service_p2_p9.1")}
              </Typography>
              <Box variant="body1" className="context">
                <div
                  dangerouslySetInnerHTML={{ __html: product.notes }}
                ></div>
              </Box>
            </Box>}
          {product.testing_items.length < 1 && product.hc_image.length < 1 ? null :
            <Grid container className="service_plan">
              <Grid item xs={12}>
                <Typography variant="h5" className="title">
                  {t("healths_service_p2_p2.1")}
                </Typography>
              </Grid>
              {(imgZoom) &&

                <Backdrop
                  open={showZoom}
                  sx={{ zIndex: 1000 }}
                >
                  <ClickAwayListener onClickAway={(e) => {
                    e.stopPropagation();
                    setShowZoom(false)
                    // console.log('ClickAwayListener')
                  }}>
                    <Zoom in={showZoom} style={{ transitionDelay: showZoom ? '500ms' : '0ms', width: { xs: '100%', md: '75%' } }}>

                      <Box className="zoomImg" sx={{ position: 'absolute', height: '100%', overflow: 'auto' }}>
                        <Box sx={{ backgroundColor: '#dfdfdf', height: '40px' }}>
                          <IconButton
                            sx={{ position: 'absolute', right: '10px', top: '0' }}
                            onClick={e => {
                              e.stopPropagation();
                              setShowZoom(false);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <IconButton
                            sx={{ position: 'absolute', left: '10px', top: '0' }}
                            onClick={e => {
                              e.stopPropagation();
                              window.open(process.env.REACT_APP_API_IMAGE + imgZoom.image_path, '_blank')
                            }}
                          >
                            <SearchIcon />
                            <Typography variant="body2">original size</Typography>
                          </IconButton>
                        </Box>

                        <CardMedia
                          sx={{ height: 'auto' }}
                          src={
                            imgZoom.image_path !== null
                              ? process.env.REACT_APP_API_IMAGE +
                              imgZoom.image_path
                              : null}

                          component="img"
                        />
                      </Box>
                    </Zoom>
                  </ClickAwayListener>
                </Backdrop>

              }
              {product.hc_image && product.hc_image.length >= 1 ?
                <Grid container spacing={2} sx={{ padding: '16px 0' }}>
                  {product.hc_image.map((item) => {

                    return (

                      <Grid key={item.id} item xs={4} onClick={(e) => {
                        e.stopPropagation();
                        // console.log('click');
                        setImgZoom(item);
                        setShowZoom(true)
                      }}>
                        <CardMedia
                          src={
                            item.image_path !== null
                              ? process.env.REACT_APP_API_IMAGE +
                              item.image_path
                              : null}

                          component="img"
                          sx={{ objectFit: 'contain' }}
                        />
                      </Grid>

                    )
                  })}
                </Grid>
                : null}



              <Table className="plan_table">
                <TableBody>
                  {product.testing_items && product.testing_items.map((item, index) => {
                    return (
                      <tr key={item + index} className="table_rows">
                        <th>{item.name}</th>
                        <td>
                          {item.lists.length >= 1
                            ? item.lists.map((item, index) => {
                              return (
                                <Box key={item + index}>{item.item_name}</Box>
                              );
                            })
                            : null}
                        </td>
                      </tr>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>}
          {related_products == '' || related_products == null || related_products.length < 1 ? null :
            <Grid container className="service_suggest_list">
              <Typography variant="h5">   {t("healths_service_p2_p6.1")}</Typography>
              <Grid item xs={12}>
                <Swiper
                  slidesPerView={1}
                  className="plan_lists"
                  modules={[Navigation]}
                  navigation={true}
                  breakpoints={{
                    500: {
                      slidesPerView: 2,
                      //navigation: true,
                    },
                    750: {
                      slidesPerView: 3,
                      //navigation: true,
                    },
                    1000: {
                      slidesPerView: 4,
                      //navigation: true,
                    },
                  }}
                >
                  {related_products && related_products.length >= 1
                    ? related_products.map((item, index) => {
                      if (item.id != null) {
                        return (
                          <SwiperSlide key={item.id} className="list_item">
                            <Link
                              to={item.seo_link !== null && item.seo_link !== '' ? `/health/${item.seo_link}` : `/health/${item.id}`}
                              onClick={() => window.scrollTo(0, 0)}
                              className="link_a"
                            >
                              <Box className="img_link">
                                <Box>
                                  <CardMedia
                                    component="img"
                                    sx={{ objectFit: 'contain' }}
                                    src={
                                      item.clinic.clinic_logo_path && item.clinic.clinic_logo_path !== null
                                        ? process.env.REACT_APP_API_IMAGE +
                                        item.clinic.clinic_logo_path
                                        : null
                                    }
                                  />
                                  <Typography variant="body1" className="title">
                                    {item.name}
                                  </Typography>
                                </Box>
                                <Typography variant="h6" className="price">
                                  HK${" "}
                                  {item.discount_price == null
                                    ? item.original_price
                                    : item.discount_price}
                                </Typography>
                              </Box>
                            </Link>
                          </SwiperSlide>
                        );
                      }
                    })
                    : null}
                </Swiper>
              </Grid>
            </Grid>}
        </Container>

      ) : null
      }

      <ContactUs />
      <ContactBtn />
    </div >
  );
};
