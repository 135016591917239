import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        order: { items: null, prices: null }
    },
    reducers: {
        add_order_items: (state, action) => {
            state.order.items = action.payload;
        },
        set_order_prices: (state, action) => {
            state.order.prices = action.payload;
        },
        update_order_items: (state, action) => {
            state.order.items = action.payload;
        },
        clear_carts: (state, action) => {
            state.order.items = null;
            state.order.prices = null;
        },

    }
})

export const { add_order_items, set_order_prices, update_order_items, clear_carts } = orderSlice.actions;

export default orderSlice.reducer;


