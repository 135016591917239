import { createSlice } from "@reduxjs/toolkit";

export const bookingSlice = createSlice({
    name: 'booking',
    initialState: {
        Booking: { booking_id: null }
    },
    reducers: {
        get_booking_id: (state, action) => {
            state.Booking.booking_id = action.payload
        },
        
    }
})

export const { get_booking_id } = bookingSlice.actions;

export default bookingSlice.reducer;
