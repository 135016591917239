import Swal from "sweetalert2"
const ToastInt = Swal.mixin({
    toast: true,
    //position: 'center',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    
})

export const SuccessToast = (msg) => {
    ToastInt.fire({
        icon: 'success',
        title: msg
    });
}

export const ErrorToast = (msg) => {
    ToastInt.fire({
        icon: 'error',
        title: msg,
    });
}

