import AxiosInstance from "../axios/AxiosInstance";

// import axios from 'axios'

// let source = null;

// User Auths
export const user_login_auth = (user) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post("/auth/login", user)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const user_register_auth = (data) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post("/auth/register", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const forget_pwd = (data) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post("/send-verification-code", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const reset_pwd = (data) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post("/reset-password", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const userLogout = (token) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post("/auth/logout", {}, { headers: { Authorization: token } })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


// News
export const get_news_list = (lang_id, page, news_category_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get("/news/list", { params: { language_id: lang_id, perPage: 10, page: page, news_category_id: news_category_id, sortBy: 'date', sortDesc: true } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const get_news_category_list = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get("/news_category/list", { params: { language_id: lang_id } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const get_news_post = (id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/news?id=${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// FAQ
export const get_faq_category_list = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get("/faq_category/list", {
      params: { language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// Question Pages
export const get_question_pages = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get("/pages/list?sortBy=sort_order", {
      params: { language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const send_contact_form = (data) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/contact`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};






// Centre
export const get_centre_list = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get("/centre/list", { params: { language_id: lang_id } })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const get_centre_list_by_locationId = (id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/list?location_id[]=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const get_centre_store = (id, lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/?id=${id}`, { params: { language_id: lang_id } })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


// Region
export const get_centre_filters = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get("/info/newclinicfilter", { params: { language_id: lang_id } })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


// Healths
export const get_healths = (lang_id, perPage, page) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/products/list`, {
      params: { perPage: perPage, page: page, language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const get_health_product = (id, lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/products?id=${id}`, {
      params: { language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const get_health_product_filter = (str, lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/products/list` + str, {
      params: { language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const get_health_product_by_filters = (filters, lang_id) => {
  // if (source !== null) {
  //   source.cancel()
  // }

  // source = axios.CancelToken.source();
  // console.log(filters)
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/products/list`, {
      //cancelToken: source.token,
      params: { ...filters, sortBy: 'discount_price', sortDesc: false, language_id: lang_id }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // if (axios.isCancel(err)) {
        //   // console.log('Previous request canceled, new request is send', err.message);
        // } else {
        // handle error
        reject(err)
        // }

      });

  });

};

// Search

export const get_result_from_search = (filters, lang_id) => {
  // if (source !== null) {
  //   source.cancel()
  // }

  // source = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/centre/products/list`, {
      //cancelToken: source.token,
      params: { ...filters, sortBy: 'net_price', sortDesc: false, language_id: lang_id }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // if (axios.isCancel(err)) {
        //   // console.log('Previous request canceled, new request is send', err.message);
        // } else {
        // handle error
        reject(err)
        // }

      });

  });

};



// Home
export const get_home_info = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/info`, {
      params: { language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// Filter
export const get_filter_list = (lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`info/newfilter`, {
      params: { language_id: lang_id },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

//  Checkout
export const checkout_paypal = (data) => {
  // console.log("checkout_paypal", data);
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`paypal/checkout-paypal`, {
      address: data.address,
      check_up_id: data.check_up_id,
      clinic_location_id: data.clinic_location_id,
      customer_id: data.customer_id,
      date: data.date,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      remark: data.remark,
      return_url: data.return_url,
      tel: data.tel,
      time: data.time,
      title: data.title,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const apply_code = (c_up_id, codeStr, token) => {
  if (token != null) {
    // console.log('apply_code->token')
    return new Promise((resolve, reject) => {
      AxiosInstance.post(`checkout/detailscheck`, {
        check_up_id: c_up_id, code: codeStr
      }, { headers: { Authorization: 'Bearer ' + token } })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  } else {
    return new Promise((resolve, reject) => {
      AxiosInstance.post(`checkout/detailscheck`, {
        check_up_id: c_up_id, code: codeStr
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

};


export const offline_payment = (data, lang_id, token) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`offline-payment`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', Authorization: token ? 'Bearer ' + token : null
      },
      params: { language_id: lang_id }
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


// User

export const get_cust_info = (token, lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`user/info`, {
      params: {
        language_id: lang_id
      }
      , headers: { Authorization: 'Bearer ' + token }
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const get_cust_booking = (token, lang_id) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`user/account/bookings`, {
      params: {
        language_id: lang_id
      },
      headers: { Authorization: 'Bearer ' + token }
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const update_user_info = (token, data) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`user/update`, data, { headers: { Authorization: 'Bearer ' + token } })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};




