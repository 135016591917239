import { createSlice } from "@reduxjs/toolkit";

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    news_list: { lists: null, count: null },
    news_category_list: { lists: null, count: null },
    select_categroy_id: null,
    news: { blog: null }
  },
  reducers: {
    get_lists: (state, action) => {
      state.news_list.lists = action.payload.news.reverse();
      state.news_list.count = action.payload.total;
    },
    get_gategory_lists: (state, action) => {
      state.news_category_list.lists = action.payload.newscategories;
      state.news_category_list.count = action.payload.total;
    },
    select_categroy_header: (state, action) => {
      state.select_categroy_id = action.payload;
    },
    select_blog: (state, action) => {
      state.news.blog = action.payload;
    },
  },
});

export const { get_lists, get_gategory_lists, select_categroy_header, select_blog } =
  newsSlice.actions;

export default newsSlice.reducer;
