import "../../styles/views/Pages/blogPost.scss";
import React, { useEffect, useState, useCallback, useMemo } from "react";

import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Box,
  Button,
} from "@mui/material";

import bg from "../../images/blog/bg.webp";

import { get_news_post } from "../../controller/api/api";

import { useSelector, useDispatch } from "react-redux";
import { select_blog } from "../../redux/reducers/newsSlice";

import { useTranslation } from "react-i18next";

import { SEO_META } from "../../components/seo/SEO";


export const BlogPost = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { language } = useSelector((state) => state.language);
  const { blog } = useSelector((state) => state.news.news);


  const [news, setNews] = useState(true);

  const getPost = async () => {
    if (id == undefined || id == null) {
      setNews(false);
    } else {

      await get_news_post(id)
        .then((res) => {
          // // console.log('BlogPost->getPost, id:' + id, res)
          let new_arr = res.news;
          let content = null;
          if (res.news.contents != null) {
            content = res.news.contents.filter(
              (item, index) => item.language_id == language
            );
            new_arr.contents = content[0];
          }
          // // console.log('content', content);

          setNews(new_arr);
          // // console.log('new_arr', new_arr)
          dispatch(select_blog(new_arr))
          //// console.log(new_arr);
        })
        .catch((err) => {
          setNews(false);
          // console.log(err)
        });


    }
  };

  useEffect(() => {
    if (language != null) {
      console.log(id)
      getPost();
    }
  }, [language]);

  return (
    <div className="blog_post_section">

      {blog != null && news ? (
        <>
          <SEO_META title={blog.meta_title} keyword={blog.meta_keyword} description={blog.meta_description} />
          <Grid item xs={12}>
            <CardMedia
              image={process.env.REACT_APP_API_IMAGE + blog.image_path}
              alt={blog.seo_link}
              style={{
                height: "450px",
                width: "100%",
                objectFit: "cover",
                backgroundAttachment: "fixed",
              }}
            />
          </Grid>
          <Container maxWidth="lg">
            <Typography variant="h4" sx={{ margin: '8px 0' }}>{blog.contents.title}</Typography>
            <Grid container >
              <Grid xs item>
                <Typography variant="h6" sx={{ margin: '8px 0' }}>{t("BLOG_POST_DATE.1")}: {blog.date}</Typography>
              </Grid>
              <Grid xs item textAlign="right">
                <Typography variant="h6" sx={{ margin: '8px 0' }}>{t("BLOG_POST_AUTHOR.1")}: {blog.contents.author}</Typography>
              </Grid>
            </Grid>

            <div className="html_div"
              dangerouslySetInnerHTML={{ __html: blog.contents.content }}
            ></div>
          </Container>
        </>
      ) : blog == null && !news ? (
        <>
          <Container maxWidth="lg">
            <Typography variant="h4">Not Fund!</Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "teal",
                paddingLeft: "30px",
                paddingRight: "30px",

                borderRadius: 0,
                "&:hover": {
                  backgroundColor: "green",
                },
              }}
              onClick={() => navigate({ pathname: "/blog" })}
            >
              News Page
            </Button>
          </Container>
        </>
      ) : null}
    </div>
  );
};
