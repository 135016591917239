import "../../styles/views/Pages/centrestore.scss";
import React, { useEffect, useState, useRef } from "react";

import { useLocation, useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Breadcrumbs,
  Button,
  ImageList,
  ImageListItem,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Rating,
  Popper,
} from "@mui/material";

import { ListGroup, Accordion, Form, Ratio } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation, FreeMode, Virtual } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import { useDispatch, useSelector } from "react-redux";
import { get_centre_store } from "../../controller/api/api";
import { get_centre_store_info } from "../../redux/reducers/centreSlice";

import { ContactUs } from "../../components/contact_us";

import ContactBtn from "../../components/ContactBtn/ContactBtn";

import { useTranslation } from "react-i18next";

import { SEO_META } from "../../components/seo/SEO";


export const CentreStore = () => {

  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [mapThumbsSwiper, setMapThumbsSwiper] = useState(null);

  const { store } = useSelector((state) => state.centre.centre_list);
  const { language } = useSelector((state) => state.language);



  const checkCentreStoreId = async (id, language) => {
    await get_centre_store(id, language)
      .then((res) => {
        // // console.log(res)

        dispatch(get_centre_store_info(res.clinic));

      })
      .catch((err) => {
        //    console.log(err);
      });
  };

  useEffect(() => {
    // console.log("CentreStore()->params.id", params.id);
    if (language != null) {
      //console.log(params);
      checkCentreStoreId(params.name, language);
      window.scrollTo(0, 0);
    }
  }, [params.name, language]);

  return (
    <div className="centre_store_section">
      {store !== null && <SEO_META title={store.meta_title} keyword={store.meta_keyword} description={store.meta_description} />}
      <Container maxWidth="lg">
        {store == null ? null : (
          <>
            <Breadcrumbs
              className="breadcrumbs"
              sx={{ pt: { xs: "30px", md: "90px ", lg: "20px" } }}
            >
              <Link to="/">
                <Typography underline="hover" color="inherit">
                  {t("centre_store_brumb_home.1")}
                </Typography>
              </Link>
              <Link to="/centre">
                <Typography underline="hover" color="inherit">
                  {t("centre_store_brumb_centres.1")}
                </Typography>
              </Link>
              <Typography color="text.primary">{store.clinic_name}</Typography>
            </Breadcrumbs>

            <Grid
              container
              className="store_details"
              sx={{ flexFlow: { xs: "column-reverse", md: "row" } }}
            >
              <Grid item xs={12} md={6} className="left_div">
                <Typography variant="title" component="h1">
                  {store.clinic_name}
                </Typography>
                <Typography variant="body1" className="subTitle">
                  {store.seo_link}
                </Typography>

                <Box className="rating_info">
                  <Box className="show_rating_info">
                    <Rating
                      name="read-only"
                      value={
                        ((store.equipment +
                          store.location +
                          store.efficiency +
                          store.environment) /
                          2 /
                          20) *
                        5
                      }
                      precision={0.5}
                      readOnly
                      className="rating_box"
                    />
                    <Grid container className="sub_rating">
                      <Grid item xs={12}>
                        <Typography variant="body1" className="title">
                          {t("centre_store_stars_rating.1")}
                        </Typography>
                        <Grid item xs={12} md={6} className="rating_context">
                          <Grid container className="rating_item">
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {t("centre_store_stars_environ.1")}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Rating
                                name="read-only"
                                precision={0.5}
                                value={store.equipment / 2}
                                readOnly
                                className="rating_box"
                              />
                            </Grid>
                          </Grid>
                          <Grid container className="rating_item">
                            <Grid item xs={6}>
                              <Typography variant="body1">{t("centre_store_stars_locat.1")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Rating
                                name="read-only"
                                value={store.location / 2}
                                precision={0.5}
                                readOnly
                                className="rating_box"
                              />
                            </Grid>
                          </Grid>
                          <Grid container className="rating_item">
                            <Grid item xs={6}>
                              <Typography variant="body1">{t("centre_store_stars_effic.1")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Rating
                                name="read-only"
                                value={store.efficiency / 2}
                                precision={0.5}
                                readOnly
                                className="rating_box"
                              />
                            </Grid>
                          </Grid>
                          <Grid container className="rating_item">
                            <Grid item xs={6}>
                              <Typography variant="body1">{t("centre_store_stars_equip.1")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Rating
                                name="read-only"
                                value={store.environment / 2}
                                precision={0.5}
                                readOnly
                                className="rating_box"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={11} className="rating_desc">
                          <Typography variant="body1" className="title">
                            {t("centre_store_stars_how_w_r.1")}
                          </Typography>
                          <Box className="desc_list">
                            <Box className="list_item">
                              <Typography
                                variant="body2"
                                className="desc_title"
                              >
                                {t("centre_store_stars_environ.1")}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="desc_context"
                              >
                                {t("centre_store_stars_environ_desc.1")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="desc_list">
                            <Box className="list_item">
                              <Typography
                                variant="body2"
                                className="desc_title"
                              >
                                {t("centre_store_stars_locat.1")}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="desc_context"
                              >
                                {t("centre_store_stars_locat_desc.1")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="desc_list">
                            <Box className="list_item">
                              <Typography
                                variant="body2"
                                className="desc_title"
                              >
                                {t("centre_store_stars_effic.1")}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="desc_context"
                              >
                                {t("centre_store_stars_effic_desc.1")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="desc_list">
                            <Box className="list_item">
                              <Typography
                                variant="body2"
                                className="desc_title"
                              >
                                {t("centre_store_stars_equip.1")}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="desc_context"
                              >
                                {t("centre_store_stars_equip_desc.1")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box variant="body2" className="details">
                  <div
                    dangerouslySetInnerHTML={{ __html: store.about_text }}
                  ></div>
                </Box>
                <Grid container className="context_box">
                  <Grid item xs className="left_box">
                    <Typography variant="h4" className="title">
                      {store.clinic_location.length}
                    </Typography>
                    <Typography variant="h6" className="context">
                      {t("centre_store_centres.1")}
                    </Typography>
                  </Grid>
                  <Divider
                    className="center_line"
                    orientation="vertical"
                    flexItem
                    sx={{ height: "initial" }}
                  />

                  <Grid item xs className="right_box">
                    <Typography variant="h4" className="title">
                      {store.clinic_location.length}
                    </Typography>
                    <Typography variant="h6" className="context">
                      {t("centre_store_location.1")}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ display: { lg: "block", xs: "block" } }}>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    watchSlidesProgress={true}
                    modules={[Thumbs]}
                    slidesPerView={6}
                    allowTouchMove={false}
                    className="left_img_list"
                    onClick={(s, e) => setThumbsSwiper(s.clickedIndex)}
                  >
                    {store.location_images.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index} className="list_item">
                          <Link to="#" className="img_link">
                            <img
                              src={
                                item.clinic_logo_path !== null
                                  ? process.env.REACT_APP_API_IMAGE +
                                  item.image_path
                                  : null
                              }
                              loading="lazy"
                            />
                          </Link>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className="right_div">
                <Grid item xs sx={{ ml: { xs: "0", md: "40px" } }}>
                  <Swiper
                    className="store_img_lists"
                    modules={[Navigation, Thumbs]}
                    thumbs={{ swiper: thumbsSwiper }}
                    watchSlidesProgress={true}
                    navigation={true}
                    style={{
                      "--swiper-navigation-color": "#fff",
                    }}
                  >
                    {store.location_images.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index}>
                          <CardMedia
                            image={
                              item.clinic_logo_path !== null
                                ? process.env.REACT_APP_API_IMAGE +
                                item.image_path
                                : null
                            }
                            sx={{ height: "100%" }}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>

            <Box className="store_info" sx={{ padding: { xs: '70px 15px', lg: ' 70px 80px 210px' } }}>
              <img
                src={
                  store.clinic_logo_path !== null
                    ? process.env.REACT_APP_API_IMAGE + store.clinic_logo_path
                    : null
                }
                style={{ width: "300px", height: "auto" }}
              />
              <Box variant="body2">
                <div
                  dangerouslySetInnerHTML={{ __html: store.about_text }}
                ></div>
              </Box>
            </Box>

            <Box className="store_map_box">
              <Swiper
                onSwiper={setMapThumbsSwiper}
                watchSlidesProgress={true}
                modules={[Thumbs]}
                slidesPerView={2}
                spaceBetween={16}
                allowTouchMove={true}

                className="map_type_list"
                onClick={(s, e) => setMapThumbsSwiper(s.clickedIndex)}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
              >
                {store.clinic_location.map((item, index) => {
                  return (
                    <SwiperSlide key={item + index} className="list_item">
                      <Link to="#" onClick={(e) => e.preventDefault()}>
                        {item.location_clinic_name}
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <Swiper
                className="map_iframe_list"
                modules={[Navigation, Thumbs]}
                thumbs={{ swiper: mapThumbsSwiper }}
                watchSlidesProgress={true}
                navigation={false}
                style={{
                  "--swiper-navigation-color": "#fff",
                }}
              >
                {store.clinic_location.map((item, index) => {
                  return (
                    <SwiperSlide key={item + index}>
                      <Grid
                        container
                        className="store_map"
                        sx={{ alignItems: "center" }}
                      >
                        <Grid item xs={12} md={6} className="map_box">
                          <Grid item xs={12} md={11}>
                            <Ratio aspectRatio="16x9">
                              <div
                                className="iframe_size"
                                dangerouslySetInnerHTML={{
                                  __html: store.clinic_location[0].google_map,
                                }}
                              ></div>
                            </Ratio>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="map_context">
                          <Typography variant="h5" className="title">
                            {item.location_clinic_name}
                          </Typography>
                          <Box className="rating_info">
                            <Box className="show_rating_info">
                              <Rating
                                name="read-only"
                                value={
                                  ((item.equipment +
                                    item.location +
                                    item.efficiency +
                                    item.environment) /
                                    2 /
                                    20) *
                                  5
                                }
                                precision={0.5}
                                readOnly
                                className="rating_box"
                              />
                              <Grid
                                container
                                className="sub_rating"
                                sx={{ width: "80%" }}
                              >
                                <Grid item xs={12}>
                                  <Typography variant="body1" className="title">
                                    {t("centre_store_stars_rating.1")}
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className="rating_context"
                                  >
                                    <Grid container className="rating_item">
                                      <Grid item xs={6}>
                                        <Typography variant="body1">
                                          {t("centre_store_stars_environ.1")}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Rating
                                          name="read-only"
                                          precision={0.5}
                                          value={item.equipment / 2}
                                          readOnly
                                          className="rating_box"
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container className="rating_item">
                                      <Grid item xs={6}>
                                        <Typography variant="body1">
                                          {t("centre_store_stars_locat.1")}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Rating
                                          name="read-only"
                                          value={item.location / 2}
                                          precision={0.5}
                                          readOnly
                                          className="rating_box"
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container className="rating_item">
                                      <Grid item xs={6}>
                                        <Typography variant="body1">
                                          {t("centre_store_stars_effic.1")}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Rating
                                          name="read-only"
                                          value={item.efficiency / 2}
                                          precision={0.5}
                                          readOnly
                                          className="rating_box"
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container className="rating_item">
                                      <Grid item xs={6}>
                                        <Typography variant="body1">
                                          {t("centre_store_stars_equip.1")}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Rating
                                          name="read-only"
                                          value={item.environment / 2}
                                          precision={0.5}
                                          readOnly
                                          className="rating_box"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>

                          <Typography variant="body2" className="address_text">
                            {t("centre_store_address.1")}:
                            {item.address}
                          </Typography>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Box>

            {store.health_check_up.length >= 1 ? (
              <Grid container className="store_plan_list">
                <Grid item xs>
                  <Typography variant="h5">{t("centre_store_health_checks.1")}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Swiper
                    slidesPerView={1}
                    className="plan_lists"
                    modules={[Navigation]}
                    navigation={true}
                    breakpoints={{
                      500: {
                        slidesPerView: 2,

                      },
                      750: {
                        slidesPerView: 3,

                      },
                      1000: {
                        slidesPerView: 4,

                      },
                    }}
                  >
                    {store.health_check_up.map((item, index) => {
                      return (
                        <SwiperSlide key={item + index} className="list_item">
                          <Link
                            to={item.seo_link !== null && item.seo_link !== '' ? `/health/${item.seo_link}` : `/health/${item.id}`}
                            className="link_a">
                            <Box className="img_link">
                              <Box>
                                <img
                                  src={
                                    store.clinic_logo_path &&
                                      store.clinic_logo_path !== null
                                      ? process.env.REACT_APP_API_IMAGE +
                                      store.clinic_logo_path
                                      : null
                                  }
                                />

                                <Typography variant="body1" className="title">
                                  {item.code}
                                </Typography>
                                <Typography variant="body1" className="title">
                                  {item.name}
                                </Typography>
                              </Box>
                              <Typography variant="h6" className="price">
                                {t("centre_store_health_checks_hkd.1")}${" "}
                                {item.discount_price !== null
                                  ? item.discount_price
                                  : item.original_price}
                              </Typography>
                            </Box>
                          </Link>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </Container>

      <ContactUs />
      <ContactBtn />
    </div>
  );
};
