import "../../styles/views/Pages/health.scss";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router";
import { Link, createSearchParams } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  CardMedia,
  Slider,
  Pagination,
  Alert,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  ClickAwayListener,
  IconButton,
  TableRow,
  TableCell
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { Ratio, Accordion } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { ContactUs } from "../../components/contact_us";

import { checkNotNull, checkIsNull } from "../../utils";

import { useSearchParams } from "react-router-dom";

import { motion } from "framer-motion";

import {
  get_health_product_filter,
  get_filter_list,
  get_health_product_by_filters,
  get_health_product
} from "../../controller/api/api";


import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { get_health_lists, get_product_lists_total } from "../../redux/reducers/healthSlice";
import { getFilterData } from "../../redux/reducers/filterSlice";
import { set_category_item, set_category_id } from "../../redux/reducers/homeSlice";
import { addCompareProduct, delCompareProduct, updateCompareProduct, clearCompareItems } from "../../redux/reducers/compareSlice";

import { checkPage } from "../../utils";

import ContactBtn from "../../components/ContactBtn/ContactBtn";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#efefef", 1),
  width: "100%",
  display: "flex",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `1rem`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const Health = () => {
  const { filters, regions } = useSelector((state) => state.filter.filter);
  const { info, cateId } = useSelector((state) => state.home.Home);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();


  const [page, setPage] = useState(1);

  const { language } = useSelector((state) => state.language);
  const { lists, total, priceLists } = useSelector((state) => state.health.healths, shallowEqual);
  const { compare } = useSelector((state) => state.compare);

  const [budgetValue, setBudgetValue] = useState([0, 30000]);
  const [priceValue, setPriceValue] = useState();

  const [maxPrice, setMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState();
  const [selectLocationId, setSelectLocationId] = useState(null);
  const [selectFilterId, setSelectFilterId] = useState([]);
  const [selectFilterCategory, setSelectFilterCategory] = useState();
  const [selectGender, setSelectGender] = useState();
  const [searchText, setSearchText] = useState('');
  const [pageTitle, setPageTitle] = useState(null)
  const [initBudget, setInitBudget] = useState()


  const [products, setProducts] = useState([]);
  const [searchAPI, setSearchAPI] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [showCompare, setShowCompare] = useState(false);
  const [showCompareTable, setShowCompareTable] = useState(false);


  const [isReset, setIsReset] = useState(false);


  const handleFilterClick = (e, id) => {
    // // console.log(id, selectFilterId);
    let temp = [...selectFilterId];
    if (temp.includes(id) == false) {
      temp = [id, ...temp];
    } else {
      temp = temp.filter((item) => {
        return item !== id;
      })
    }
    setSelectFilterId(temp);
    handleClickPage(null, 1);
    // // console.log('handleFilterClick->temp', temp);

  };
  const handleGenderClick = (e, gender) => {
    //// console.log(gender);
    setSelectGender(gender);
    handleClickPage(e, 1);
    if (selectGender == gender) {
      setSelectGender(null);
    } else {
      //getProductLocationFilter(id);
    }
  };
  const handleFilterCategoryClick = (e, filterCategory) => {
    // // console.log('handleFilterCategoryClick', filterCategory);
    setSelectFilterCategory(filterCategory);
    dispatch(set_category_id(filterCategory));
    handleClickPage(e, 1);
    if (selectFilterCategory == filterCategory) {
      setSelectFilterCategory(null);

      dispatch(set_category_id(null));

    } else {
      //getProductLocationFilter(id);
    }
  };

  const handleLocationClick = (e, id) => {
    // // console.log(id);

    if (selectLocationId == id) {
      setSelectLocationId(null);
      // handleFilter();

    } else {
      setSelectLocationId(id);
      handleClickPage(null, 1);
    }

  };


  const handleBudgetChange = (event, newValue, activeThumb) => {
    setBudgetValue(newValue);
  };
  const handleBudgetCommitted = (event, newValue) => {
    // // console.log('handleBudgetCommitted', newValue);
    //getProductMaxPriceFilter(newValue[1], 10, 1);
    let timerBudget = setTimeout(() => {
      if (searchAPI == true) {
        setPriceValue(newValue);
        handleClickPage(null, 1);
      }
    }, 500)
    return () => clearTimeout(timerBudget)

  }


  const handleCompareClick = async (e, item) => {
    // console.log('handleCompareClick->item', item);
    e.preventDefault();
    await get_health_product(item.id, language)
      .then((res) => {
        // console.log("handleCompareClick->get_product_details->res", res);
        dispatch(addCompareProduct(res.product));
      })
      .catch((err) => {

      });

  }
  const openCompareBox = (e, bool, clear) => {
    e.preventDefault();
    setShowCompareTable(bool);
    if (clear == true) {
      dispatch(clearCompareItems());
    }
  }

  useEffect(() => {
    if (compare && compare.length >= 1) {
      setShowCompare(true);
    } else {
      setShowCompare(false);
    }
  }, [compare])

  const handleDelCompareItem = (item) => {
    dispatch(delCompareProduct(item));
  }




  const handleSortBy = (e) => {
    e.preventDefault();
    console.log('handleSortBy')
  }


  const handleFilter = async (search, filterCategoryId, filterId, locationId, pForm, pTo, sGender, sPage) => {
    // filter_id, location_id, price_from, price_to, gender
    // console.log(searchText, selectFilterCategory, selectLocationId, budgetValue[0], budgetValue[1], selectGender, page);
    setLoading(true);
    //console.log("handleFilter", parseInt(filterCategoryId), selectFilterCategory)
    await get_health_product_by_filters(
      {
        q: search == null ? searchText : search,
        filter_id: filterId == null ? selectFilterId : filterId,
        location_id: locationId == null ? selectLocationId : locationId,
        price_from: pForm == null ? budgetValue[0] : pForm,
        price_to: pTo == null ? budgetValue[1] : pTo,
        gender: sGender == null ? selectGender : sGender,
        page: sPage == null ? page : sPage,
        filter_category_id: filterCategoryId == null ? selectFilterCategory : filterCategoryId,
        perPage: 10
      }, language
    ).then((res) => {
      //// console.log(res)
      setProducts(res.products);
      dispatch(get_product_lists_total(res.total));
    }).catch((err) => {
      // console.log(err)
    })


    if (searchAPI == false) {
      setSearchAPI(true)
    }
    setLoading(false);
  }


  const getFilter = async (str) => {
    if (checkNotNull(language)) {
      await get_filter_list(language)
        .then((res) => {
          //// console.log(res);
          dispatch(getFilterData({ filters: res.filter, regions: res.regions, maxPrice: res.max_price, minPrice: res.min_price }));
          setBudgetValue([0, res.max_price]);
          setInitBudget([0, res.max_price]);
          setMaxPrice(res.max_price);
          setMinPrice(res.min_price)

        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }

  const handleClickPage = (e, v) => {
    // // console.log(v)
    setPage(v)
  }

  const toProductPage = (e, item) => {
    e.preventDefault();
    // console.log(item)

    let newId = null;
    if (item.seo_link !== null && item.seo_link !== '') {
      newId = item.seo_link
    } else {
      newId = item.id
    }
    //console.log(newId);
    navigate(`/health/${newId}`,);


  }



  useEffect(() => {
    //// console.log('call API')


    const timer = setTimeout(() => {
      if (searchAPI == true) {
        handleFilter()
      }
    }, 300)
    return () => clearTimeout(timer)


  }, [selectFilterId, selectLocationId, priceValue, selectGender, page, searchText, selectFilterCategory, isReset])
  useEffect(() => {
    setPage(1)
  }, [selectFilterId, selectLocationId, priceValue, selectGender, searchText, selectFilterCategory, isReset])



  useEffect(() => {
    window.scrollTo(0, 0);

    // if (location.pathname == "/health") {
    //   setSelectLocationId(null);
    //   setSelectFilterId([]);
    //   setSelectFilterCategory();
    //   setSelectGender();
    //   setSearchText('');
    //   //getFilter()
    //   if (initBudget !== null && initBudget !== undefined) {
    //     // console.log(initBudget);
    //     let budget = initBudget;
    //     setBudgetValue(budget);
    //   }
    //   dispatch(set_category_id(null));
    // }

    // console.log(location);
  }, [location])


  const checkCateTitleName = (cateNum) => {
    //console.log(cateNum)
    if (checkNotNull(info)) {
      if (cateNum != null) {
        if (info.filter_categories != null) {
          let tempCate = info.filter_categories.filter((item) => item.id == cateNum);
          // console.log(tempCate);
          if (tempCate.length >= 1) {
            //// console.log('tempCate[0].category_name', tempCate[0].category_name)
            // setPageTitle(tempCate[0].category_name);
            dispatch(set_category_item(tempCate[0]));
            dispatch(set_category_id(tempCate[0].id));
          } else {
            dispatch(set_category_id(null));
          }
        }
      }

    }
  }


  useEffect(() => {
    //console.log(location, selectFilterCategory);
    // getFilter();

    if (language != null) {
      if (location.search == "") {
        handleFilter();
        // console.log('location.search == ""')
        if (location.state && location.state.reset != null) {
          setSelectLocationId(null);
          setSelectFilterId([]);
          setSelectFilterCategory();
          setSelectGender();
          setSearchText('');
          //getFilter()
          if (initBudget !== null && initBudget !== undefined) {
            // console.log(initBudget);
            let budget = initBudget;
            setBudgetValue(budget);
          }
          dispatch(set_category_id(null));
        }
      } else {
        // handleFilter()
        // console.log(searchParams)
        // console.log(location)
        // console.log('location.search == "" else')
        let filterCateNum = parseInt(searchParams.get('filter_category_id'));
        let search = searchParams.get('q');
        let filterId = parseInt(searchParams.get('filter_id'));
        let gender = searchParams.get('gender');
        // console.log('filterCateNum', filterCateNum, searchParams.get('filter_category_id'))


        //   console.log('selectFilterCategory == null')
        if (filterCateNum && filterCateNum != NaN && typeof filterCateNum == 'number') {

          setSelectFilterCategory(filterCateNum);
          // // console.log(typeof filterCateNum);
          checkCateTitleName(filterCateNum);
        }
        // else {

        //   filterCateNum = null;
        //   checkCateTitleName(null);
        //   setSelectFilterCategory(null);
        // }


        if (filterId && typeof filterId == 'number') {
          setSelectFilterId([filterId]);
        } else {
          filterId = null;
        }
        if (search) {
          setSearchText(search);
        }
        if (searchAPI != true) {
          //  console.log("searchAPI != true")
          handleFilter(search, selectFilterCategory ? selectFilterCategory : filterCateNum, filterId == null ? null : [filterId], null, null, null, gender, null);
        }
        // getHealthsWithFilter(location.search);

      }

    }

  }, [language, location])

  useEffect(() => {
    getFilter();
    if (language != null) {
      if (compare && compare.length >= 1) {

        compare.forEach((element, index) => {
          let tempId = element.id;
          dispatch(delCompareProduct({ id: tempId }))
          updateCompareItemByLanguage(tempId, language);
        });


      }
    }
  }, [language])


  const updateCompareItemByLanguage = async (id, lang) => {

    await get_health_product(id, lang)
      .then(res => {
        //console.log(res.product)
        dispatch(addCompareProduct(res.product))
      }).catch(err => {
        console.log(err)
      })

  }


  const handleSearchTextChagne = (e) => {
    // console.log(e.target.value);
    setSearchText(e.target.value);
  }


  return (
    <div className="health_section">

      <motion.div
        initial={false}
        animate={{ bottom: showCompare == true ? 0 : '-40px' }}
        style={{
          position: 'fixed',
          zIndex: 300,
          right: '5%'
        }}
      >
        <Link to="#" onClick={(e) => openCompareBox(e, true)} className="compare_grid">
          {t("healths_compare_text.1")} ( {compare && compare.length} )
        </Link>
      </motion.div>
      <motion.div
        animate={{ height: showCompareTable == true ? '100%' : '0' }}
        style={{
          position: 'fixed',
          zIndex: 200,
        }}
      >
        <Modal
          open={showCompareTable}
          onClose={(e) => openCompareBox(e, false, false)}
        >

          <Box className="compareBox" sx={{ height: { md: '80%', xs: '100%' }, width: { md: '80%', xs: '100%' }, overflowX: { xs: 'auto', md: 'unset' } }}>
            <Grid container className="compare_top" >
              <Grid item xs>
                <Typography variant="h4" className="title_compare" sx={{ paddingLeft: { md: 0, xs: '40px' } }}>{t("healths_compare_text.1")}</Typography>
              </Grid>

              <IconButton sx={{ color: 'white' }} onClick={(e) => openCompareBox(e, false, true)}>
                <CloseIcon />
              </IconButton>

            </Grid>


            {compare && compare.length >= 1 ?
              <Grid container className="compare_table" spacing={0} sx={{ display: { xs: 'block', md: 'block' } }}>
                <Grid container className="table_item" >
                  <Grid item xs={2} className="col_name" alignItems='center' sx={{ fontSize: { md: '20px', xs: '14px' }, justifyContent: { md: 'center', xs: 'unset' } }}> {t("healths_compare_name.1")}:</Grid>
                  {compare && compare.map((item, index) => {
                    return (
                      compare && compare.length >= 1 ?
                        <Grid item xs={5} key={item + index} className="item_name item_field" sx={{ fontSize: { md: '20px', xs: '16px' } }}>
                          {item.name}
                        </Grid> : <Grid item xs={10}></Grid>
                    )
                  })}
                  {compare.length == 1 ? <Grid item xs={5}></Grid> : null}
                </Grid>
                <Grid container className="table_item" >
                  <Grid item xs={2} className="col_name" alignItems='center' sx={{ fontSize: { md: '20px', xs: '14px' }, justifyContent: { md: 'center', xs: 'unset' } }}>{t("healths_compare_image.1")}:</Grid>
                  {compare && compare.map((item, index) => {
                    return (
                      compare && compare.length >= 1 ?
                        <Grid item xs={5} key={item.id} className="item_field">
                          {item.clinic && <CardMedia
                            image={
                              item.clinic.clinic_logo_path !== null
                                ? process.env.REACT_APP_API_IMAGE +
                                item.clinic.clinic_logo_path
                                : null
                            }
                            component="img"
                            sx={{ objectFit: 'contain', height: 'auto', width: { xs: '80%', md: '200px' } }}
                            className="item_image"
                          />}
                        </Grid> : <Grid item xs={10}></Grid>

                    )

                  })}
                  {compare.length == 1 ? <Grid item xs={5}></Grid> : null}
                </Grid>

                <Grid container className="table_item" >
                  <Grid item xs={2} className="col_name" alignItems='center' sx={{ fontSize: { md: '20px', xs: '14px' }, justifyContent: { md: 'center', xs: 'unset' } }}>{t("healths_compare_price.1")}:</Grid>
                  {compare && compare.map((item, index) => {
                    return (
                      compare && compare.length >= 1 ?
                        <Grid item xs={5} key={item + index} className="item_field" >
                          <Typography variant="h4" className="item_price" sx={{ fontSize: { xs: '24px', md: '30px' } }}>$ {item.discount_price}</Typography>
                        </Grid> : <Grid item xs={10}></Grid>
                    )
                  })}
                  {compare.length == 1 ? <Grid item xs={5}></Grid> : null}
                </Grid>

                <Grid container className="table_item" >
                  <Grid item xs={2} className="col_name" alignItems='center' sx={{ fontSize: { md: '20px', xs: '14px' }, justifyContent: { md: 'center', xs: 'unset' } }}>{t("healths_compare_action.1")}:</Grid>
                  {compare && compare.map((item, index) => {
                    return (
                      compare && compare.length >= 1 ?
                        <Grid item xs={5} key={item + index} className="item_field btn_group">
                          <Grid container className="btns_grid" spacing={1}>
                            <Grid item xs={12} md={4} className="grid_item" sx={{ width: { xs: 'auto', md: '120px' } }}>
                              <Button variant="contained" className="grid_btn" onClick={() => toProductPage(item)}>{t("healths_compare_details.1")}</Button>
                            </Grid>
                            <Grid item xs={12} md={4} className="grid_item" sx={{ width: { xs: 'auto', md: '120px' } }}>
                              <Button variant="contained" className="grid_btn" onClick={() => handleDelCompareItem(item)}>{t("healths_compare_delete.1")}</Button>
                            </Grid>
                          </Grid>
                        </Grid> : <Grid item xs={10}></Grid>
                    )
                  })} {compare.length == 1 ? <Grid item xs={5}></Grid> : null}
                </Grid>

                <Grid container className="table_item" >
                  <Grid item xs={2} className="col_name" sx={{ fontSize: { md: '20px', xs: '14px' }, justifyContent: { md: 'center', xs: 'unset' } }}>{t("healths_compare_branch.1")}:</Grid>
                  {compare && compare.map((item, index) => {
                    return (
                      compare && compare.length >= 1 ?
                        <Grid item xs={5} key={item + index} className="item_field">
                          <Grid container >
                            {item.branches.map((item, index) => {
                              return (
                                <Grid container className="branches_list" >
                                  <Grid item xs={12} key={item + index} className="location_list">
                                    {item.location && <Typography variant="h6" className="item_location_name">{item.location.location} </Typography>}
                                  </Grid>
                                  {/* <Grid item xs={12}>
                                    <Typography variant="body" className="item_clinic_name">{item.location_clinic_name}</Typography>
                                  </Grid> */}
                                </Grid>

                              )
                            })}
                          </Grid>
                        </Grid> : <Grid item xs={10}></Grid>
                    )
                  })}
                  {compare.length == 1 ? <Grid item xs={5}></Grid> : null}
                </Grid>

                <Grid container className="table_item" >
                  <Grid item xs={2} className="col_name" sx={{ fontSize: { md: '20px', xs: '14px' }, justifyContent: { md: 'center', xs: 'unset' } }}>{t("healths_compare_plan.1")}:</Grid>
                  {compare && compare.map((item, index) => {
                    return (
                      item.testing_items && item.testing_items.length >= 1 ?
                        <Grid item xs={5} key={item + index} className="item_field">
                          <Table className={`item_plan_table${index >= 1 ? " right" : ""}`} >
                            <TableBody sx={{ display: 'inline-table', width: '100%', border: { xs: '0', md: 'solid #8bc9c9' }, padding: { xs: '4px', md: '10px' } }}  >
                              {item.testing_items.map((item, index) => {
                                return (
                                  <TableRow key={item + index} className="table_rows" sx={{ border: { xs: '0', md: 'solid #8bc9c9' }, backgroundColor: { md: index % 2 == 0 ? '#dcf3f3' : 'white', xs: 'white' } }} >
                                    <TableCell component="th" sx={{ display: { xs: 'none', md: 'table-cell' }, border: { xs: '0', md: 'solid #8bc9c9' } }}>{item.name}</TableCell>
                                    <TableCell sx={{ borderBottom: { xs: '0', md: 'initial' }, padding: { md: '10px', xs: '4px' } }}>
                                      {item.lists.length >= 1
                                        ? item.lists.map((item, index) => {
                                          return (
                                            <Box key={item.id}>{item.item_name}</Box>
                                          );
                                        })
                                        : null}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid> : <Grid item xs={5}></Grid>
                    )
                  })}
                  {compare.length == 1 ? <Grid item xs={5}></Grid> : null}
                </Grid>
              </Grid>

              : null}




          </Box>

        </Modal>


      </motion.div >


      <Box className="banner_div">
        <Box className="context_div">
          <Typography variant="h4" component="h4">
            {t("healths_meet_out.1")}
          </Typography>

          {cateId != null ? <Typography variant="h3" component="h3">

            {info && info.filter_categories != null ? info.filter_categories.filter((item) => item.id == cateId).map((item) => item.category_name) : null} {t("healths_title2.1")}
          </Typography> : <Typography variant="h3" component="h3">
            {t("healths_title.1")} {t("healths_title2.1")}
          </Typography>
          }
          <Typography variant="h6" className="text">
            {t("healths_search_context.1")}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" className="centre_context">
        <Grid container className="show_medical_div">
          <Grid item xs={12} md={4} className="left_filter_div" sx={{ pr: { xs: '0', md: '20px' } }}>
            <Search className="search_div">
              <StyledInputBase
                className="input_bar"
                placeholder={t("healths_cate_search.1")}
                inputProps={{
                  "aria-label": "search",
                }}
                sx={{ width: "100%" }}
                onChange={(e) => handleSearchTextChagne(e)}
                value={searchText}

              />


            </Search>
            {checkNotNull(regions) ?
              <Accordion defaultActiveKey={["0", ...regions.map((item, index) => "" + index + 1), regions.length + 1, regions.length + 2, regions.length + 3]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{t("healths_cate_need.1")}</Accordion.Header>
                  <Accordion.Body>
                    <Grid container className="checkbox_lists">
                      {checkNotNull(filters) ?
                        filters.map((item, index) => {
                          if (item.product_count >= 1) {

                            return <Grid key={item + index} item xs={6} className="c_list_item">
                              <FormControlLabel
                                className="label_ck" sx={{ margin: { xs: '0', md: 'initial' } }}
                                control={<Checkbox className="checkbox_btn" />}
                                onChange={(e) => handleFilterClick(e, item.id)}
                                checked={selectFilterId.includes(item.id)}
                                label={
                                  <Typography variant="body2" className="checkout_text">
                                    {item.filter_name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          }
                        })
                        : null}
                    </Grid>
                  </Accordion.Body>
                </Accordion.Item>
                {checkNotNull(regions) ?
                  regions.map((item, index) => {
                    if (item.count >= 1) {
                      return (
                        <Accordion.Item
                          key={item + index}
                          eventKey={"" + index + 1}
                        >
                          <Accordion.Header>{item.region}</Accordion.Header>
                          <Accordion.Body>
                            <Grid container className="checkbox_lists">
                              {item.location.map((item, index) => {
                                if (item.product_count >= 1) {


                                  return (
                                    <Grid key={item + index} item xs={6} className="c_list_item">
                                      <FormControlLabel
                                        className="label_ck" sx={{ margin: { xs: '0', md: 'initial' } }}
                                        onChange={(e) =>
                                          handleLocationClick(e, item.id)
                                        }
                                        checked={item.id == selectLocationId}
                                        control={
                                          <Checkbox className="checkbox_btn" />
                                        }
                                        label={
                                          <Typography
                                            variant="body2"
                                            className="checkout_text"
                                          >
                                            {item.location}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  );
                                }
                              })}
                            </Grid>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                  })
                  : null}
                <Accordion.Item eventKey={regions.length + 1}>
                  <Accordion.Header>{t("healths_cate_budget.1")}</Accordion.Header>
                  <Accordion.Body>
                    <Grid container className="slides_list">
                      <Grid item xs={6}>
                        <Typography variant="body2" className="checkout_text">
                          {t("healths_cate_budget_hkd.1")}$ {budgetValue[0]}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="body2" className="checkout_text">
                          {t("healths_cate_budget_hkd.1")}$ {budgetValue[1]} +
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="slides_box">
                        <Slider
                          value={budgetValue}
                          max={maxPrice}
                          step={100}
                          onChange={handleBudgetChange}
                          disableSwap
                          onChangeCommitted={handleBudgetCommitted}
                        />
                      </Grid>
                    </Grid>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={regions.length + 2}>
                  <Accordion.Header>{t("healths_cate_gender.1")}</Accordion.Header>
                  <Accordion.Body>
                    <Grid container className="checkbox_lists">

                      <Grid item xs={6} className="c_list_item">
                        <FormControlLabel
                          className="label_ck" sx={{ margin: { xs: '0', md: 'initial' } }}
                          onChange={(e) => handleGenderClick(e, 'F')}
                          checked={'F' == selectGender}
                          control={<Checkbox className="checkbox_btn" />}
                          label={
                            <Typography variant="body2" className="checkout_text">
                              {" "}
                              {t("healths_cate_gender_female.1")}
                            </Typography>
                          }
                        />
                      </Grid>


                      <Grid item xs={6} className="c_list_item">
                        <FormControlLabel
                          className="label_ck" sx={{ margin: { xs: '0', md: 'initial' } }}
                          onChange={(e) => handleGenderClick(e, 'M')}
                          checked={'M' == selectGender}
                          control={<Checkbox className="checkbox_btn" />}
                          label={
                            <Typography variant="body2" className="checkout_text">
                              {" "}
                              {t("healths_cate_gender_male.1")}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Accordion.Body>
                </Accordion.Item>
                {checkNotNull(info) ?

                  <Accordion.Item eventKey={regions.length + 3}>
                    <Accordion.Header>{t("healths_service_categories_title.1")}</Accordion.Header>
                    <Accordion.Body>
                      <Grid container className="checkbox_lists">
                        {info.filter_categories.map((item, index) => {
                          return (<Grid item xs={6} key={item.id} className="c_list_item">
                            <FormControlLabel
                              className="label_ck" sx={{ margin: { xs: '0', md: 'initial' } }}
                              onChange={(e) => handleFilterCategoryClick(e, item.id)}
                              checked={item.id == selectFilterCategory}
                              control={<Checkbox className="checkbox_btn" />}
                              label={
                                <Typography variant="body2" className="checkout_text">
                                  {item.category_name}
                                </Typography>
                              }
                            />
                          </Grid>)
                        })}
                      </Grid>
                    </Accordion.Body>
                  </Accordion.Item>

                  : null}

              </Accordion>
              : null}

          </Grid>
          <Grid item xs={12} md={8} className="right_show_div" sx={{ border: { xs: '1px solid #e5e5e5', md: '0' }, borderLeft: { xs: '1px solid #e5e5e5', md: '1px solid #e5e5e5' } }}>
            {/* <Grid container className="top_menu">
              <Grid item xs className="left_box">
                <Link to="#" className="sort_btn" onClick={handleSortBy}>
                  <FontAwesomeIcon icon={faSliders} />
                  <Typography variant="body1">SORT BY</Typography>
                </Link>
              </Grid>
              <Grid item xs className="right_box">
                {checkNotNull(lists) ? <Typography variant="body1">
                  Showing {lists.length} results out of {page}
                </Typography>
                  : null}
              </Grid> 
            </Grid> */}

            {isLoading == true ? <Box sx={{ textAlign: 'center' }}><CircularProgress color="success" /></Box> :

              checkNotNull(products) && products.length >= 1 ? products.map((item, index) => {
                return (
                  <Grid key={item.id} container className="list_item" spacing={2}>
                    <Grid item xs={12} md={3} className="img_box">
                      <CardMedia
                        image={item.clinic ? process.env.REACT_APP_API_IMAGE + item.clinic.clinic_logo_path : null}
                        component="img" />
                    </Grid>
                    <Grid item xs={12} md={6} className="context_box">
                      <Typography variant="body2" className="sub_title">
                        {item.clinic ? item.clinic.clinic_name : null}
                      </Typography>
                      <Typography variant="h6" className="title">
                        {item.name}
                      </Typography>
                      <Typography variant="body2" className="gender">
                        {t("healths_cate_gender.1")}: {item.gender}
                      </Typography>
                      <Box variant="body2" >
                        <div className="item_desc" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} className="btns_box">
                      {
                        item.discount_price == null ?
                          <>
                            <Typography variant="body2" className="price"> </Typography>
                            <Typography variant="h5" className="sale_price">
                              {t("healths_cate_budget_hkd.1")}$ {item.original_price}
                            </Typography>
                          </>
                          :
                          <>
                            <Typography variant="body2" className="price">
                              {t("healths_cate_budget_hkd.1")}$ {item.original_price}
                            </Typography>
                            <Typography variant="h5" className="sale_price">
                              {t("healths_cate_budget_hkd.1")}$ {item.discount_price}
                            </Typography>
                          </>
                      }

                      <Button variant="outlined" ><Link to="#" className="a_link" onClick={(e) => handleCompareClick(e, item)}>{t("healths_compare_text.1")}</Link></Button>
                      <Button variant="outlined" ><Link to={`/health/${item.seo_link}`} onClick={(e) => toProductPage(e, item)} className="a_link">{t("healths_details_text.1")}</Link></Button>
                    </Grid>
                  </Grid>
                )
              }) :
                total != null ?
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {t("healths_total_0.1")}
                    </Alert>
                  </Grid> : null


            }






          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md></Grid>
          <Grid item xs={12} md={8} sx={{ display: "flex" }}>
            {checkNotNull(products) ? <Pagination
              page={page}
              defaultPage={1}
              count={checkPage(total, 10)}
              sx={{ margin: "0 auto", pt: "40px" }} onChange={(e, v) => handleClickPage(e, v)} /> : null}
          </Grid>
        </Grid>
        <ContactUs />
        <ContactBtn />
      </Container>
    </div >
  );
};
