import { createSlice } from "@reduxjs/toolkit";

export const langSlice = createSlice({
    name: 'language',
    initialState: {
        language: null
    },
    reducers: {
        change_language: (state, action) => {
            state.language = action.payload
        }
    }
})

export const { change_language } = langSlice.actions;

export default langSlice.reducer;
