import React, { } from 'react'
import { Helmet } from 'react-helmet'

import { useSelector } from 'react-redux'

export const SEO_META = (meta) => {

    const { info } = useSelector(state => state.home.Home);

    //console.log(meta);

    if (Object.keys(meta).length <= 0 && info !== null) {

        const seo = <Helmet>
            <title>{info.info.meta_title[0].value}</title>
            {info.info.meta_keywords && info.info.meta_keywords.map((item,index) => {
                //console.log(item.value);
                return <meta key={index} name="keywords" content={item.value} />
            })}
            {info.info.meta_description && info.info.meta_description.map((item,index) => {
                //console.log(item.value);
                return <meta key={index} name="description" content={item.value} />
            })}
        </Helmet>;
        return seo;
    }
    return <Helmet>
        <title>{meta.title}</title>
        <meta name="keywords" content={meta.keyword} />
        <meta name="description" content={meta.description} />
    </Helmet>



}
